import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import ReportContent from "./ReportContent"
import { PDFViewer } from "@react-pdf/renderer"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

export const ReportFile = props => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ border: "1px solid red", minHeight: "100vh" }}
      >
        <Container fluid>
          <Breadcrumbs
            title={props.t("Générer un rapport sur l'utilisateur")}
            // breadcrumbItem={props.t("Reports")}
          />
        </Container>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8} style={{ height: "700%" }}>
            <Paper className={classes.paperx}>
              <PDFViewer
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "relative",
                  zIndex: 0,
                  overflow: "hidden",
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  display: "block",
                }}
                showToolbar={true}
                showOpenFile={false}
                showPrint={true}
                showDownload={true}
                showPageSetup={true}
                showNavigator={true}
                showThumbnails={true}
                showOutline={true}
                showViewPort={true}
                showStatusbar={true}
                showFind={true}
                showPresentation={true}
              >
                <ReportContent />
              </PDFViewer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={classes.paper}>xs=6</Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}

ReportFile.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ReportFile))
