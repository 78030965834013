import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { addNewSprint as onAddNewSprint } from "store/sprints/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import logo from "assets/images/logo.jpg"
import { getMenusActions } from "store/menus/actions"
import { getOrganisationsActions } from "store/organisations/actions"

//i18n
import { withTranslation } from "react-i18next"

export const SprintCreate = props => {
  // meta title
  document.title = props.t("NewSprint")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const toggleToast = () => {
    setToast(!toast)
  }

  const { menus } = useSelector(state => ({
    menus: state.menus.menus,
  }))

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  useEffect(() => {
    if (menus && !menus?.length) {
      dispatch(getMenusActions())
    }
  }, [menus?.length])

  useEffect(() => {
    if (organisations && !organisations?.length) {
      dispatch(getOrganisationsActions())
    }
  }, [organisations?.length])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nbre_participants: "",
      scope_fr: "",
      scope_en: "",
      scope_nl: "",
      scope_sp: "",
      scope_de: "",
      scope_it: "",
      menu: "",
      organisation: "",
      s1_start_date: "",
      s1_end_date: "",
    },
    validationSchema: Yup.object({
      nbre_participants: Yup.string().required("Please Enter Your Participant Number"),
      scope_fr: Yup.string().required("Please Enter Your Scope Name"),
      scope_en: Yup.string().required("Please Enter Your Scope Name"),
      scope_nl: Yup.string().required("Please Enter Your Scope Name"),
      scope_sp: Yup.string().required("Please Enter Your Scope Name"),
      scope_de: Yup.string().required("Please Enter Your Scope Name"),
      scope_it: Yup.string().required("Please Enter Your Scope Name"),
      menu: Yup.string().required("Please Choice Your Menu"),
      organisation: Yup.string().required("Please Choice Your Organisation"),
      s1_start_date: Yup.string().required("Please Enter Your Start Date"),
      s1_end_date: Yup.string().required("Please Enter Your Ended Date"),
    }),
    onSubmit: values => {
      // save new menu
    //   // console.log({values})
      dispatch(onAddNewSprint(values))
      // toggleToast()
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Sprint")}
            breadcrumbItem={props.t("NewSprint")}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("SprintMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("NewSprint")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_fr_input"
                          name="scope_fr"
                          type="text"
                          value={validation.values.scope_fr || ""}
                          invalid={
                            validation.touched.scope_fr &&
                            validation.errors.scope_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_fr &&
                        validation.errors.scope_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_en_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_en_input"
                          name="scope_en"
                          type="text"
                          value={validation.values.scope_en || ""}
                          invalid={
                            validation.touched.scope_en &&
                            validation.errors.scope_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_en &&
                        validation.errors.scope_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_nl_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (Neerlandais)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (Neerlandais)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_nl_input"
                          name="scope_nl"
                          type="text"
                          value={validation.values.scope_nl || ""}
                          invalid={
                            validation.touched.scope_nl &&
                            validation.errors.scope_nl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_nl &&
                        validation.errors.scope_nl ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_nl}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_sp_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (Espagnol)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (Espagnol)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_sp_input"
                          name="scope_sp"
                          type="text"
                          value={validation.values.scope_sp || ""}
                          invalid={
                            validation.touched.scope_sp &&
                            validation.errors.scope_sp
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_sp &&
                        validation.errors.scope_sp ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_sp}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_de_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (Deutsch)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (Deutsch)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_de_input"
                          name="scope_de"
                          type="text"
                          value={validation.values.scope_de || ""}
                          invalid={
                            validation.touched.scope_de &&
                            validation.errors.scope_de
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_de &&
                        validation.errors.scope_de ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_de}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="scope_it_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Scope (Italian)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Scope (Italian)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="scope_it_input"
                          name="scope_it"
                          type="text"
                          value={validation.values.scope_it || ""}
                          invalid={
                            validation.touched.scope_it &&
                            validation.errors.scope_it
                              ? true
                              : false
                          }
                        />
                        {validation.touched.scope_it &&
                        validation.errors.scope_it ? (
                          <FormFeedback type="invalid">
                            {validation.errors.scope_it}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="nbre_participants_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("nbre_participants")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("nbre_participants")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="nbre_participants_input"
                          name="nbre_participants"
                          type="number"
                          value={validation.values.nbre_participants || ""}
                          invalid={
                            validation.touched.nbre_participants &&
                            validation.errors.nbre_participants
                              ? true
                              : false
                          }
                        />
                        {validation.touched.nbre_participants &&
                        validation.errors.nbre_participants ? (
                          <FormFeedback type="invalid">
                            {validation.errors.nbre_participants}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="menu_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("menu")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="menu_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.menu || ""}
                          name="menu"
                          placeholder={props.t("menu")}
                          className="form-control"
                        >
                          <option value="">{props.t("menu")}</option>
                          {menus &&
                            menus.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {props.t(language === 'fr' ? item?.fr : item?.en)}
                              </option>
                            ))}
                        </select>
                        {validation.touched.menu &&
                        validation.errors.menu ? (
                          <FormFeedback type="invalid">
                            {validation.errors.menu}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="organisation_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("organisation")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="organisation_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.organisation || ""}
                          name="organisation"
                          placeholder={props.t("organisation")}
                          className="form-control"
                        >
                          <option value="">{props.t("organisation")}</option>
                          {organisations &&
                            organisations.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {props.t(item?.short_code_org)}
                              </option>
                            ))}
                        </select>
                        {validation.touched.menu &&
                        validation.errors.menu ? (
                          <FormFeedback type="invalid">
                            {validation.errors.menu}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="s1_start_date_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("s1_start_date")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="s1_start_date_input"
                          name="s1_start_date"
                          placeholder={props.t("s1_start_date")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="date"
                          value={validation.values.s1_start_date || ""}
                          invalid={
                            validation.touched.s1_start_date &&
                            validation.errors.s1_start_date
                              ? true
                              : false
                          }
                        />
                        {validation.touched.s1_start_date &&
                        validation.errors.s1_start_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.s1_start_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="s1_end_date_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("s1_end_date")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="s1_end_date_input"
                          name="s1_end_date"
                          placeholder={props.t("s1_end_date")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="date"
                          value={validation.values.s1_end_date || ""}
                          invalid={
                            validation.touched.s1_end_date &&
                            validation.errors.s1_end_date
                              ? true
                              : false
                          }
                        />
                        {validation.touched.s1_end_date &&
                        validation.errors.s1_end_date ? (
                          <FormFeedback type="invalid">
                            {validation.errors.s1_end_date}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {props.t("SubmitSprint")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SprintCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SprintCreate))
