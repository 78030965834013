import {
  ADD_NEW_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
  GET_QUESTION_BY_THEME,
  GET_QUESTION_BY_THEME_SUCCESS,
  GET_QUESTION_BY_THEME_FAIL,
} from "./actionTypes"

export const getQuestionsActions = () => ({
  type: GET_QUESTIONS,
})

export const getQuestionSuccess = questions => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions,
})

export const getQuestionFail = error => ({
  type: GET_QUESTIONS_FAIL,
  payload: error,
})
export const getQuestionByThemeAction = themeID => ({
  type: GET_QUESTION_BY_THEME,
  themeID,
})

export const getQuestionByThemeSuccess = (id, question) => ({
  type: GET_QUESTION_BY_THEME_SUCCESS,
  payload: { id, question },
})

export const getQuestionByThemeFail = error => ({
  type: GET_QUESTION_BY_THEME_FAIL,
  payload: error,
})

export const addNewQuestion = question => ({
  type: ADD_NEW_QUESTION,
  payload: question,
})

export const addQuestionSuccess = question => ({
  type: ADD_QUESTION_SUCCESS,
  payload: question,
})

export const addQuestionFail = error => ({
  type: ADD_QUESTION_FAIL,
  payload: error,
})

export const updateQuestion = question => ({
  type: UPDATE_QUESTION,
  payload: question,
})

export const updateQuestionSuccess = question => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload: question,
})

export const updateQuestionFail = error => ({
  type: UPDATE_QUESTION_FAIL,
  payload: error,
})
