import {
  GET_QUESTIONNAIRE_DETAIL_BY_ID,
  GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL,
  GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS,
  GET_QUESTIONNAIRE_DETAIL,
  GET_QUESTIONNAIRE_DETAIL_SUCCESS,
  GET_QUESTIONNAIRE_DETAIL_FAIL,
  ADD_NEW_QUESTIONNAIRE_ADMIN,
  ADD_QUESTIONNAIRE_ADMIN_SUCCESS,
  ADD_QUESTIONNAIRE_ADMIN_FAIL,
  GET_QUESTIONNAIRES,
  GET_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRES_FAIL,
} from "./actionTypes"

export const addNewQuestionnaireAdmin = questionnaireAdmin => ({
  type: ADD_NEW_QUESTIONNAIRE_ADMIN,
  payload: questionnaireAdmin,
})

export const addQuestionnaireAdminSuccess = questionnaireAdmin => ({
  type: ADD_QUESTIONNAIRE_ADMIN_SUCCESS,
  payload: questionnaireAdmin,
})

export const addQuestionnaireAdminFail = error => ({
  type: ADD_QUESTIONNAIRE_ADMIN_FAIL,
  payload: error,
})

export const getQuestionnaireDetail = () => ({
  type: GET_QUESTIONNAIRE_DETAIL,
})

export const getQuestionnaireDetailSuccess = questionnaireDetail => ({
  type: GET_QUESTIONNAIRE_DETAIL_SUCCESS,
  payload: questionnaireDetail,
})

export const getQuestionnaireDetailFail = error => ({
  type: GET_QUESTIONNAIRE_DETAIL_FAIL,
  payload: error,
})

export const getQuestionnaires = () => ({
  type: GET_QUESTIONNAIRES,
})

export const getQuestionnairesSuccess = questionnaires => ({
  type: GET_QUESTIONNAIRES_SUCCESS,
  payload: questionnaires,
})

export const getQuestionnairesFail = error => ({
  type: GET_QUESTIONNAIRES_FAIL,
  payload: error,
})

export const getQuestionnaireDetailById = id => ({
  type: GET_QUESTIONNAIRE_DETAIL_BY_ID,
  id,
})

export const getQuestionnaireDetailByIdSuccess = questionnaireDetailByID => ({
  type: GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS,
  payload: questionnaireDetailByID,
})

export const getQuestionnaireDetailByIdFail = error => ({
  type: GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL,
  payload: error,
})
