import {
  ADD_NEW_THEME,
  ADD_THEME_SUCCESS,
  ADD_THEME_FAIL,
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAIL,
  DELETE_THEME, DELETE_THEME_SUCCESS, DELETE_THEME_FAIL, UPDATE_THEME, UPDATE_THEME_SUCCESS, UPDATE_THEME_FAIL
} from "./actionTypes"
import { DELETE_USER_USER, DELETE_USER_USER_FAIL, DELETE_USER_USER_SUCCESS } from "../users/actionTypes"
import { UPDATE_MENU, UPDATE_MENU_FAIL, UPDATE_MENU_SUCCESS } from "../menus/actionTypes"

export const getThemesActions = () => ({
  type: GET_THEMES,
})

export const getThemeSuccess = themes => ({
  type: GET_THEMES_SUCCESS,
  payload: themes,
})

export const getThemeFail = error => ({
  type: GET_THEMES_FAIL,
  payload: error,
})

export const addNewTheme = theme => ({
  type: ADD_NEW_THEME,
  payload: theme,
})

export const addThemeSuccess = theme => ({
  type: ADD_THEME_SUCCESS,
  payload: theme,
})

export const addThemeFail = error => ({
  type: ADD_THEME_FAIL,
  payload: error,
})
export const delete_Theme = id => ({
  type: DELETE_THEME,
  payload: id,
})

export const updateTheme = theme => ({
  type: UPDATE_THEME,
  payload: theme,
})

export const updateThemeSuccess = theme => ({
  type: UPDATE_THEME_SUCCESS,
  payload: theme,
})

export const updateThemeFail = error => ({
  type: UPDATE_THEME_FAIL,
  payload: error,
})

export const delete_ThemeSuccess = id => ({
  type: DELETE_THEME_SUCCESS,
  payload: id,
})

export const delete_ThemeFail = error => ({
  type: DELETE_THEME_FAIL,
  payload: error,
})
