import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { withRouter } from "react-router-dom"
import { Modal, Form, Row, Label, Input, FormFeedback } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { updateOrganisation as onUpdateOrganisation } from "store/organisations/actions"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

const OrgaUpdate = props => {
  const { modal_standard, tog_standard, setmodal_standard, id_orga } = props

  const dispatch = useDispatch()

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { colorTypes } = useSelector(state => ({
    colorTypes: state.colorTypes.colorTypes,
  }))

  const { secteurs } = useSelector(state => ({
    secteurs: state.secteurs.secteurs,
  }))

  // Utilisez la méthode find pour récupérer l'objet avec l'id correspondant
  const selectedObject = organisations?.find(obj => obj?._id === id_orga)

  // // console.log({ selectedObject })

  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState("")
  const [isoCode, setIsoCode] = useState("")
  const [imageUp, setImageUp] = useState(false)

  useEffect(() => {
    // Charger la liste des pays depuis l'API REST
    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => {
        // Trier le tableau par ordre alphabétique
        const sortedData = data.sort((a, b) => {
          const countryA = a.name.common.toUpperCase()
          const countryB = b.name.common.toUpperCase()
          if (countryA < countryB) {
            return -1
          }
          if (countryA > countryB) {
            return 1
          }
          return 0
        })

        // Mettre à jour le state avec le tableau trié
        setCountries(sortedData)
      })
      .catch(error => {
        console.error("Erreur lors du chargement des pays :", error)
      })
  }, [])

  const handleCountryChange = event => {
    const selectedCountry = event.target.value
    setSelectedCountry(selectedCountry)

    // Recherche du code ISO du pays sélectionné
    const country = countries.find(c => c.name.common === selectedCountry)
    if (country) {
      setIsoCode(country.cca2)
    } else {
      setIsoCode("")
    }
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  // Ajoutez un nouvel état pour stocker les informations sur le fichier
  const [uploadedFile, setUploadedFile] = useState(null)

  //---------------------- Upload an image --------------------//
  const handleUpload = e => {
    setImageUp(true)

    // Mettez à jour l'état du fichier
    setUploadedFile(e.target.files[0])
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      short_code_org: selectedObject ? selectedObject?.short_code_org : "",
      code: selectedObject ? selectedObject?.code : "",
      address_1: selectedObject ? selectedObject?.address_1 : "",
      address_2: selectedObject ? selectedObject?.address_2 : "",
      address_3: selectedObject ? selectedObject?.address_3 : "",
      code_postal: selectedObject ? selectedObject?.code_postal : "",
      ville: selectedObject ? selectedObject?.ville : "",
      country: selectedObject ? selectedObject?.country : "",
      code_pays: selectedObject ? selectedObject?.code_pays : "",
      tva: selectedObject ? selectedObject?.tva : "",
      url_site_web: selectedObject ? selectedObject?.url_site_web : "",
      tel_admin: selectedObject ? selectedObject?.tel_admin : "",
      email_compta: selectedObject ? selectedObject?.email_compta : "",
      type_organisation: selectedObject
        ? selectedObject?.type_organisation
        : "",
      total_employe: selectedObject ? selectedObject?.total_employe : "",
      langue: selectedObject ? selectedObject?.langue : "",
      type_organigramme: selectedObject
        ? selectedObject?.type_organigramme
        : "",
      date_DEBUT: selectedObject?.date_DEBUT
        ? new Date(selectedObject?.date_DEBUT)?.toISOString().split("T")[0]
        : "",
      date_FIN: selectedObject?.date_FIN
        ? new Date(selectedObject?.date_FIN)?.toISOString().split("T")[0]
        : "",
      color_type: selectedObject ? selectedObject?.color_type?._id : "",
      slug: "ORGANISATIONS",
      secteur: selectedObject ? selectedObject?.secteur?._id : "",
      anonyme: selectedObject ? selectedObject?.anonyme : "",
      nbre_niveau_hierarchique: selectedObject
        ? selectedObject?.nbre_niveau_hierarchique
        : "",
    },
    validationSchema: Yup.object({
      short_code_org: Yup.string().required("Please Enter Your Short Code"),
      // code: Yup.string().required("Please Enter Your Code"),
      address_1: Yup.string().required("Please Enter Your address 1"),
      address_2: Yup.string().optional(),
      address_3: Yup.string().optional(),
      code_postal: Yup.string().required("Please Enter Your Postal Code"),
      ville: Yup.string().required("Please Enter Your Town"),
      tva: Yup.string().required("Please Enter Your tva"),
      tel_admin: Yup.string().required("Please Enter Your Phone Number"),
      date_DEBUT: Yup.string().required("Please Enter Your date_DEBUT"),
      nbre_niveau_hierarchique: Yup.number().required(
        "Please Enter Your Hierarchique"
      ),
    }),
    onSubmit: async values => {
      try {
        const data = {
          ...values,
          id: id_orga,
          country: selectedCountry ? selectedCountry : selectedObject?.country,
          code_pays: isoCode ?isoCode : selectedObject?.code_pays,
          // anonyme: isChecked,
          file: uploadedFile,
        }

        dispatch(onUpdateOrganisation(data))

        await setmodal_standard(false)

        setUploadedFile(null)
        validation.resetForm()
      } catch (error) {
        console.error("Error submitting form:", error)
        // Handle the error as needed
      }
    },
  })

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Mise À Jour
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <Label
                htmlFor="short_code_org_input"
                className="col-md-2 col-form-label"
              >
                {props.t("short_code_org")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("short_code_org")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="short_code_org_input"
                  name="short_code_org"
                  type="text"
                  value={validation.values.short_code_org || ""}
                  invalid={
                    validation.touched.short_code_org &&
                    validation.errors.short_code_org
                      ? true
                      : false
                  }
                />
                {validation.touched.short_code_org &&
                validation.errors.short_code_org ? (
                  <FormFeedback type="invalid">
                    {validation.errors.short_code_org}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="code_input" className="col-md-2 col-form-label">
                {props.t("code")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("code")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="code_input"
                  name="code"
                  type="text"
                  disabled
                  value={validation.values.code || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="address_1_input"
                className="col-md-2 col-form-label"
              >
                {props.t("address_1")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("address_1")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="address_1_input"
                  name="address_1"
                  type="text"
                  value={validation.values.address_1 || ""}
                  invalid={
                    validation.touched.address_1 && validation.errors.address_1
                      ? true
                      : false
                  }
                />
                {validation.touched.address_1 && validation.errors.address_1 ? (
                  <FormFeedback type="invalid">
                    {validation.errors.address_1}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="address_2_input"
                className="col-md-2 col-form-label"
              >
                {props.t("address_2")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("address_2")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="address_2_input"
                  name="address_2"
                  type="text"
                  value={validation.values.address_2 || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="address_3_input"
                className="col-md-2 col-form-label"
              >
                {props.t("address_3")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("address_3")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="address_3_input"
                  name="address_3"
                  type="text"
                  value={validation.values.address_3 || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="code_postal_input"
                className="col-md-2 col-form-label"
              >
                {props.t("code_postal")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("code_postal")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="code_postal_input"
                  name="code_postal"
                  type="text"
                  value={validation.values.code_postal || ""}
                  invalid={
                    validation.touched.code_postal &&
                    validation.errors.code_postal
                      ? true
                      : false
                  }
                />
                {validation.touched.code_postal &&
                validation.errors.code_postal ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code_postal}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="ville_input" className="col-md-2 col-form-label">
                {props.t("ville")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("ville")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="ville_input"
                  name="ville"
                  type="text"
                  value={validation.values.ville || ""}
                  invalid={
                    validation.touched.ville && validation.errors.ville
                      ? true
                      : false
                  }
                />
                {validation.touched.ville && validation.errors.ville ? (
                  <FormFeedback type="invalid">
                    {validation.errors.ville}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="country_input"
                className="col-md-2 col-form-label"
              >
                {props.t("country")}
              </Label>
              <div className="col-md-8">
                <select
                  className="form-control"
                  placeholder={props.t("country")}
                  // onChange={validation.handleChange}
                  onChange={handleCountryChange}
                  onBlur={validation.handleBlur}
                  id="country_input"
                  name="country"
                  type="text"
                  value={selectedCountry || validation.values.country}
                >
                  <option value="" label="Sélectionnez un pays" />
                  {countries.map(country => (
                    <option
                      key={country.name.common}
                      // value={country.name.common}
                      // label={country.name.common}
                    >
                      {country.name.common}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="code_pays_input"
                className="col-md-2 col-form-label"
              >
                {props.t("code_pays")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("code_pays")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="code_pays_input"
                  name="code_pays"
                  type="text"
                  value={isoCode || validation.values.code_pays}
                  readOnly
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="tva_input" className="col-md-2 col-form-label">
                {props.t("tva")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("tva")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="tva_input"
                  name="tva"
                  type="text"
                  value={validation.values.tva || ""}
                  invalid={
                    validation.touched.tva && validation.errors.tva
                      ? true
                      : false
                  }
                />
                {validation.touched.tva && validation.errors.tva ? (
                  <FormFeedback type="invalid">
                    {validation.errors.tva}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="url_site_web_input"
                className="col-md-2 col-form-label"
              >
                {props.t("url_site_web")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("url_site_web")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="url_site_web_input"
                  name="url_site_web"
                  type="text"
                  value={validation.values.url_site_web || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="tel_admin_input"
                className="col-md-2 col-form-label"
              >
                {props.t("tel_admin")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("tel_admin")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="tel_admin_input"
                  name="tel_admin"
                  type="tel"
                  value={validation.values.tel_admin || ""}
                  invalid={
                    validation.touched.tel_admin && validation.errors.tel_admin
                      ? true
                      : false
                  }
                />
                {validation.touched.tel_admin && validation.errors.tel_admin ? (
                  <FormFeedback type="invalid">
                    {validation.errors.tel_admin}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="email_compta_input"
                className="col-md-2 col-form-label"
              >
                {props.t("email_compta")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  placeholder={props.t("email_compta")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="email_compta_input"
                  name="email_compta"
                  type="email"
                  value={validation.values.email_compta || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="type_organisation_input"
                className="col-md-2 col-form-label"
              >
                {props.t("type_organisation")}
              </Label>
              <div className="col-md-8">
                <select
                  id="type_organisation_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.type_organisation || ""}
                  name="type_organisation"
                  placeholder={props.t("type_organisation")}
                  className="form-control"
                >
                  <option value="">{props.t("type_organisation")}</option>
                  <option value="CLIENT">{props.t("Client")}</option>
                  <option value="COACHING">{props.t("Coach")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="total_employe_input"
                className="col-md-2 col-form-label"
              >
                {props.t("total_employe")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  id="total_employe_input"
                  placeholder={props.t("total_employe")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="total_employe"
                  type="number"
                  value={validation.values.total_employe || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="langue_input" className="col-md-2 col-form-label">
                {props.t("langue")}
              </Label>
              <div className="col-md-8">
                <select
                  id="langue_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.langue || ""}
                  name="langue"
                  placeholder={props.t("langue")}
                  className="form-control"
                >
                  <option value="">{props.t("langue")}</option>
                  <option value="FRANCAIS">{props.t("Français")}</option>
                  <option value="ENGLISH">{props.t("English")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="type_organigramme_input"
                className="col-md-2 col-form-label"
              >
                {props.t("type_organigramme")}
              </Label>
              <div className="col-md-8">
                <select
                  id="type_organigramme_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.type_organigramme || ""}
                  name="type_organigramme"
                  placeholder={props.t("type_organigramme")}
                  className="form-control"
                >
                  <option value="">{props.t("type_organigramme")}</option>
                  <option value="PYRAMIDAL">{props.t("PYRAMIDAL")}</option>
                  <option value="CERCLE">{props.t("CERCLE")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="date_DEBUT_input"
                className="col-md-2 col-form-label"
              >
                {props.t("date_DEBUT")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  id="date_DEBUT_input"
                  name="date_DEBUT"
                  placeholder={props.t("date_DEBUT")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="date"
                  value={validation.values.date_DEBUT || ""}
                  invalid={
                    validation.touched.date_DEBUT &&
                    validation.errors.date_DEBUT
                      ? true
                      : false
                  }
                />
                {validation.touched.date_DEBUT &&
                validation.errors.date_DEBUT ? (
                  <FormFeedback type="invalid">
                    {validation.errors.date_DEBUT}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="date_FIN_input"
                className="col-md-2 col-form-label"
              >
                {props.t("date_FIN")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  id="date_FIN_input"
                  name="date_FIN"
                  placeholder={props.t("date_FIN")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="date"
                  value={validation.values.date_FIN || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="secteur_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Secteur")}
              </Label>
              <div className="col-md-8">
                <select
                  id="secteur_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.secteur || ""}
                  name="secteur"
                  placeholder={props.t("Secteur")}
                  className="form-control"
                >
                  <option value="">{props.t("Secteur")}</option>
                  {secteurs &&
                    secteurs.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {props.t(
                          language === "fr"
                            ? item?.name_secteur_fr
                            : item?.name_secteur_en
                        )}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="color_type_input"
                className="col-md-2 col-form-label"
              >
                {props.t("ColorTypes")}
              </Label>
              <div className="col-md-8">
                <select
                  id="color_type_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.color_type || ""}
                  name="color_type"
                  placeholder={props.t("ColorTypes")}
                  className="form-control"
                >
                  <option value="">{props.t("ColorTypes")}</option>
                  {colorTypes?.data &&
                    colorTypes?.data.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {props.t(
                          language === "fr"
                            ? item?.color_type_fr
                            : item?.color_type_en
                        )}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="nbre_niveau_hierarchique_input"
                className="col-md-2 col-form-label"
              >
                {props.t("nbre_niveau_hierarchique")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  id="nbre_niveau_hierarchique_input"
                  placeholder={props.t("nbre_niveau_hierarchique")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="nbre_niveau_hierarchique"
                  type="number"
                  value={validation.values.nbre_niveau_hierarchique || ""}
                  invalid={
                    validation.touched.nbre_niveau_hierarchique &&
                    validation.errors.nbre_niveau_hierarchique
                      ? true
                      : false
                  }
                />
                {validation.touched.nbre_niveau_hierarchique &&
                validation.errors.nbre_niveau_hierarchique ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nbre_niveau_hierarchique}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="anonyme_input"
                className="col-md-2 col-form-label"
              >
                {props.t("anonyme")}
              </Label>
              <div className="col-md-8">
                <select
                  id="anonyme"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  placeholder={props.t("anonyme")}
                  name="anonyme"
                  value={validation.values.anonyme || ""}
                  className="form-control"
                >
                  <option value="">{props.t("anonyme")}</option>
                  <option value="True">{props.t("Oui")}</option>
                  <option value="False">{props.t("Non")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="logo_input" className="col-md-2 col-form-label">
                {props.t("Logo")}
              </Label>
              <div className="col-md-8">
                <Input
                  className="form-control"
                  id="logo_input"
                  placeholder={props.t("logo")}
                  accept=".png, .svg, .jpg, .jpeg"
                  onChange={handleUpload}
                  name="file"
                  type="file"
                />
              </div>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-primary ">
                Mettre à Jour
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

OrgaUpdate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(OrgaUpdate))
