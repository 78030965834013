import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteComponent from "components/Common/DeleteComponent"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getQuestionsActions } from "store/questions/actions"

//i18n
import { withTranslation } from "react-i18next"
import Pagination from "components/Common/ItemsPagination"
import QuestionCreate from "../QuestionCreate/question-create"

export const QuestionList = props => {
  // meta title
  document.title = props.t("Question")

  const dispatch = useDispatch()

  const { questions } = useSelector(state => ({
    questions: state.questions.questions,
  }))

  const { loading } = useSelector(state => ({
    loading: state.questions.loading,
  }))

  useEffect(() => {
    if (questions && !questions?.length) {
      dispatch(getQuestionsActions())
      // showToast()
    }
  }, [questions?.length])

  const language = localStorage.getItem("I18N_LANGUAGE")

  const [modal_standard, setmodal_standard] = useState(false)
  const [question_selected, setQuestion_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [updateQuestion, setUpdateQuestion] = useState(undefined)

  const [page, setPage] = useState(1)
  const ITEMS_PER_PAGE = 8
  const [dataList, setDataList] = useState(
    questions?.slice(0, ITEMS_PER_PAGE) || []
  )
  const handlePaginate = (page, offset) => {
    if (questions?.length > 0) {
      setPage(page + 1)
      setDataList(questions?.slice(offset, offset + ITEMS_PER_PAGE))
    }
  }

  useEffect(() => {
    if (questions?.length > 0) {
      setDataList(questions.slice(0, ITEMS_PER_PAGE))
    }
  }, [questions])

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const handleDelete = (id, name) => {
    console.log({ id, name })
    // dispatch(deleteQuestion(id))
  }

  //   ------------------------- Loader ---------------------------------//
  if (loading && questions.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      {updateQuestion ? (
        <FullModalEdit
          open={updateQuestion !== undefined}
          onClose={() => setUpdateQuestion(undefined)}
          question={updateQuestion}
          props={props}
        />
      ) : null}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Question")}
            breadcrumbItem={props.t("QuestionList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("QuestionList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{props.t("Question")}</th>
                          <th>{props.t("ThemeName")}</th>
                          <th>{props.t("ColorCode")}</th>
                          {/* <th className="d-flex justify-content-center">
                            {props.t("actions")}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList &&
                          dataList.map((question, index) => (
                            <tr key={question?._id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {language === "fr"
                                  ? question?.question_name_fr
                                  : question?.question_name_en}
                              </td>
                              <td>
                                {language === "fr"
                                  ? question?.theme?.theme_name_fr
                                  : question?.theme?.theme_name_en}
                              </td>
                              <td>{question?.color_code}</td>
                              {/* <td>
                                <div className="d-flex justify-content-around">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                      setQuestion_selected(question?._id)
                                      setUpdateQuestion(question)
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>
                                    {""}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setname_selected("")
                                      setQuestion_selected(question?._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                    {""}
                                  </button>
                                </div>

                                <DeleteComponent
                                  tog_center={tog_center}
                                  name={name_selected}
                                  id={question_selected}
                                  setmodal_center={setmodal_center}
                                  modal_center={modal_center}
                                  handleDelete={handleDelete}
                                />
                              </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Pagination
                      items={questions}
                      itemsPerPage={ITEMS_PER_PAGE}
                      handlePaginate={(page, offset) =>
                        handlePaginate(page, offset)
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const FullModalEdit = props => {
  const { open, onClose, question } = props
  return (
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>{/*{menu.fr}*/}</ModalHeader>
      <ModalBody>
        <QuestionCreate initialState={question} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

QuestionList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(QuestionList))
