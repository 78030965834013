import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteComponent from "components/Common/DeleteComponent"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import OrgaUpdate from "./orga-update.js"

import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { deleteOrganisation } from "store/organisations/actions"

//i18n
import { withTranslation } from "react-i18next"
import OrgaCreate from "../OrgaCreate/orga-create"
import Pagination from "components/Common/ItemsPagination.js"

export const OrgaList = props => {
  // meta title
  document.title = props.t("Orga")

  const dispatch = useDispatch()

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { loading } = useSelector(state => ({
    loading: state.organisations.loading,
  }))

  const [modal_standard, setmodal_standard] = useState(false)
  const [orga_selected, setorga_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [page, setPage] = useState(1)
  const ITEMS_PER_PAGE = 8
  const [dataList, setDataList] = useState(
    organisations.slice(0, ITEMS_PER_PAGE)
  )

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  // // console.log({organisations})

  const handleDelete = (id, name) => {
    // console.log({ name })
    dispatch(deleteOrganisation(id))
  }

  const handlePaginate = (page, offset) => {
    console.log(`User requested page number ${page}, which is offset ${offset}`)
    if (!loading && organisations?.length > 0) {
      setPage(page + 1)
      setDataList(organisations.slice(offset, offset + ITEMS_PER_PAGE))
    }
  }

  useEffect(() => {
    if (organisations?.length > 0) {
      setDataList(organisations.slice(0, ITEMS_PER_PAGE))
    }
  }, [organisations])

  //   ------------------------- Loader ---------------------------------//
  if (loading && organisations.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }
  const [updateOrg, setUpdateOrg] = useState(undefined)

  return (
    <React.Fragment>
      {updateOrg ? (
        <FullModalEdit
          open={updateOrg !== undefined}
          onClose={() => setUpdateOrg(undefined)}
          org={updateOrg}
          props={props}
        />
      ) : null}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Orga")}
            breadcrumbItem={props.t("OrgaList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("OrgaList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th scope="row">{props.t("code")}</th>
                          <th>{props.t("short_code_org")}</th>
                          <th>{props.t("country")}</th>
                          <th>{props.t("type_organisation")}</th>
                          <th>{props.t("type_organigramme")}</th>
                          <th>{props.t("color_type")}</th>
                          <th className="d-flex justify-content-center">
                            {props.t("actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{props.t(item.code)}</th>
                            <td>{props.t(item?.short_code_org)}</td>
                            <td>{props.t(item?.country)}</td>
                            <td>{props.t(item?.type_organisation)}</td>
                            <td>{props.t(item?.type_organigramme)}</td>
                            <td>
                              {props.t(
                                language === "fr"
                                  ? item?.color_type?.color_type_fr
                                  : item?.color_type?.color_type_en
                              )}
                            </td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <button
                                  type="button"
                                  onClick={() => {
                                    tog_standard()
                                    setorga_selected(item?._id)
                                    setUpdateOrg(item)
                                  }}
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                >
                                  <i className="bx bx-edit"></i>
                                  {""}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  onClick={() => {
                                    tog_center()
                                    setname_selected(item?.short_code_org)
                                    setorga_selected(item?._id)
                                  }}
                                >
                                  <i className="bx bx-trash"></i>
                                  {""}
                                </button>
                              </div>

                              {/*<OrgaUpdate*/}
                              {/*  modal_standard={modal_standard}*/}
                              {/*  id_orga={orga_selected}*/}
                              {/*  setorga_selected={setorga_selected}*/}
                              {/*  setmodal_standard={setmodal_standard}*/}
                              {/*  tog_standard={tog_standard}*/}
                              {/*/>*/}

                              <DeleteComponent
                                tog_center={tog_center}
                                name={name_selected}
                                id={orga_selected}
                                setmodal_center={setmodal_center}
                                modal_center={modal_center}
                                handleDelete={handleDelete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      items={organisations}
                      itemsPerPage={ITEMS_PER_PAGE}
                      handlePaginate={(page, offset) =>
                        handlePaginate(page, offset)
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const FullModalEdit = props => {
  const { open, onClose, org } = props
  return (
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>{/*{menu.fr}*/}</ModalHeader>
      <ModalBody>
        <OrgaCreate initialState={org} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

OrgaList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(OrgaList))
