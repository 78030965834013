import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useDispatch, useSelector } from "react-redux"
import { delete_Theme, getThemesActions } from "store/themes/actions"

//i18n
import { withTranslation } from "react-i18next"
import DeleteComponent from "../../../components/Common/DeleteComponent"
import ThemeCreate from "../ThemeCreate/theme-create"


export const ThemeList = props => {
  // meta title
  document.title = props.t("Themes")

  const dispatch = useDispatch()

  const { themes } = useSelector(state => ({
    themes: state.themes.themes
  }))

  const { loading } = useSelector(state => ({
    loading: state.themes.loading
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  function showToast() {
    const title = props.t("Themes")
    const message = props.t("ThemeListMessage")
    const positionClass = "toast-top-right"
    const showEasing = "swing"
    const hideEasing = "linear"
    const showMethod = "fadeIn"
    const hideMethod = "fadeOut"
    const showDuration = 300
    const hideDuration = 1000
    const timeOut = 5000
    const extendedTimeOut = 1000
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }
    toastr.success(message, title)
  }

  useEffect(() => {
    if (themes && !themes?.length) {
      dispatch(getThemesActions())
      showToast()
    }
  }, [themes?.length])
  const [themeSelected, setThemeSelected] = useState("")
  const [nameSelected, setNameSelected] = useState("")
  const [modal_center, setmodal_center] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const handleDelete = (id, name) => {
    dispatch(delete_Theme(id))
    // dispatch(get_UsersActions())
  }

//   ------------------------- Loader ---------------------------------//
  if (loading && themes?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%"
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }
  const [themeUpdate, setThemeUpdate] = useState(undefined)
  return (
    <React.Fragment>
      {
        themeUpdate ? <FullModalEdit
          open={themeUpdate !== undefined}
          theme={themeUpdate}
          onClose={() => setThemeUpdate(undefined)}
        /> : null
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Themes")}
            breadcrumbItem={props.t("ThemeList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">{props.t("ThemeList")}</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                      <tr>
                        <th scope="row">#</th>
                        <th>{props.t("ThemeName")}</th>
                        <th>{props.t("Description")}</th>
                        <th>{props.t("Question")}</th>
                        <th>{props.t("Categorie")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 1")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 2")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 3")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 4")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 5")}</th>
                        <th style={{ width: "70px" }}>{props.t("Zone 6")}</th>
                        <th className="d-flex justify-content-center">{props.t("actions")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        themes?.map((theme, index) => (
                          <tr key={theme?._id}>
                            <th scope="row">{index}</th>
                            <td>{language === "fr" ? theme?.theme_name_fr : theme?.theme_name_en}</td>
                            <td>{language === "fr" ? theme?.description_fr : theme?.description_en}</td>
                            <td>{language === "fr" ? theme?.main_question_fr : theme?.main_question_en}</td>
                            <td>{language === "fr" ? theme?.categorie?.cat_name_fr : theme?.categorie?.cat_name_en}</td>
                            <td>{theme?.percent_zone_01}</td>
                            <td>{theme?.percent_zone_02}</td>
                            <td>{theme?.percent_zone_03}</td>
                            <td>{theme?.percent_zone_04}</td>
                            <td>{theme?.percent_zone_05}</td>
                            <td>{theme?.percent_zone_06}</td>
                            <td>
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  onClick={() => {
                                    // tog_standard()
                                    setNameSelected(theme?.[`theme_name_${language}`])
                                    setThemeSelected(theme?._id)
                                    setThemeUpdate(theme)
                                  }}
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                >
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  onClick={() => {
                                    tog_center()
                                    setNameSelected(theme?.[`theme_name_${language}`])
                                    setThemeSelected(theme?._id)
                                  }}
                                >
                                  <i className="bx bx-trash"></i>
                                </button>
                              </div>

                              <DeleteComponent
                                tog_center={tog_center}
                                name={nameSelected}
                                id={themeSelected}
                                setmodal_center={setmodal_center}
                                modal_center={modal_center}
                                handleDelete={handleDelete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const FullModalEdit = props => {
  const {open,onClose,theme} = props
  return (
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>
        {/*{menu.fr}*/}
      </ModalHeader>
      <ModalBody>
        <ThemeCreate initialState={theme} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

ThemeList.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(ThemeList))
