import {
  ADD_NEW_MENU,
  ADD_MENU_SUCCESS,
  ADD_MENU_FAIL,
  GET_MENUS,
  GET_MENUS_SUCCESS,
  GET_MENUS_FAIL,
  UPDATE_MENU,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAIL,
  DELETE_MENU,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAIL,
} from "./actionTypes"

export const getMenusActions = () => ({
  type: GET_MENUS,
})

export const getMenuSuccess = menus => ({
  type: GET_MENUS_SUCCESS,
  payload: menus,
})

export const getMenuFail = error => ({
  type: GET_MENUS_FAIL,
  payload: error,
})

export const addNewMenu = menu => ({
  type: ADD_NEW_MENU,
  payload: menu,
})

export const addMenuSuccess = menu => ({
  type: ADD_MENU_SUCCESS,
  payload: menu,
})

export const addMenuFail = error => ({
  type: ADD_MENU_FAIL,
  payload: error,
})

export const updateMenu = menu => ({
  type: UPDATE_MENU,
  payload: menu,
})

export const updateMenuSuccess = menu => ({
  type: UPDATE_MENU_SUCCESS,
  payload: menu,
})

export const updateMenuFail = error => ({
  type: UPDATE_MENU_FAIL,
  payload: error,
})

export const deleteMenu = id => ({
  type: DELETE_MENU,
  payload: id,
})

export const deleteMenuSuccess = id => ({
  type: DELETE_MENU_SUCCESS,
  payload: id,
})


export const deleteMenuFail = error => ({
  type: DELETE_MENU_FAIL,
  payload: error,
})
