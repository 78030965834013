import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  addNewQuestion as onAddNewQuestion,
  updateQuestion,
} from "store/questions/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import logo from "assets/images/logo.jpg"
import { getThemesActions } from "store/themes/actions"

//i18n
import { withTranslation } from "react-i18next"

export const QuestionCreate = props => {
  // meta title
  document.title = props.t("NewQuestion")

  console.log(props)

  const initialState = props.initialState

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)
  // const [transformedArrayFR, setTransformedArrayFR] = useState([])
  // const [transformedArrayEN, setTransformedArrayEN] = useState([])

  const toggleToast = () => {
    setToast(!toast)
  }

  const { themes } = useSelector(state => ({
    themes: state.themes.themes,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  useEffect(() => {
    if (themes && !themes?.length) {
      dispatch(getThemesActions())
    }
  }, [themes?.length])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      question_name_fr: initialState?.question_name_fr || "",
      question_name_en: initialState?.question_name_en || "",
      color_code: initialState?.color_code || "",
      theme: initialState?.theme?._id || "",
    },
    validationSchema: Yup.object({
      question_name_fr: Yup.string().required(
        "Please Enter Your French Question Name"
      ),
      question_name_en: Yup.string().required(
        "Please Enter Your English Question Name"
      ),
      color_code: Yup.string().required("Please Enter Your ColorType"),
      theme: Yup.string().required("Please Enter Your Theme"),
    }),
    onSubmit: values => {
      // save new menu
      if (initialState) {
        dispatch(updateQuestion(values))
      } else {
        dispatch(onAddNewQuestion(values))
      }
      // toggleToast()
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Question")}
            breadcrumbItem={
              initialState ? props.t("UpdateQuestion") : props.t("NewQuestion")
            }
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("QuestionMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("NewQuestion")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="question_name_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Question (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Question (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="question_name_fr_input"
                          name="question_name_fr"
                          type="text"
                          value={validation.values.question_name_fr || ""}
                          invalid={
                            validation.touched.question_name_fr &&
                            validation.errors.question_name_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.question_name_fr &&
                        validation.errors.question_name_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.question_name_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="question_name_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Question (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Question (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="question_name_en_input"
                          name="question_name_en"
                          type="text"
                          value={validation.values.question_name_en || ""}
                          invalid={
                            validation.touched.question_name_en &&
                            validation.errors.question_name_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.question_name_en &&
                        validation.errors.question_name_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.question_name_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_code_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("ColorCode")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="color_code_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.color_code || ""}
                          name="color_code"
                          placeholder={props.t("ColorCode")}
                          className="form-control"
                        >
                          <option value="">{props.t("ColorCode")}</option>
                          <option value="A">A</option>
                          <option value="B">B</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                          <option value="E">E</option>
                        </select>
                        {validation.touched.color_code &&
                        validation.errors.color_code ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_code}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="theme_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("ThemeName")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="theme_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.theme || ""}
                          name="theme"
                          placeholder={props.t("ThemeName")}
                          className="form-control"
                        >
                          <option value="">{props.t("ThemeName")}</option>
                          {themes &&
                            themes?.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {props.t(
                                  language === "fr"
                                    ? item?.theme_name_fr
                                    : item?.theme_name_en
                                )}
                              </option>
                            ))}
                        </select>
                        {validation.touched.theme && validation.errors.theme ? (
                          <FormFeedback type="invalid">
                            {validation.errors.theme}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {initialState
                          ? props.t("UpdateQuestion")
                          : props.t("SubmitQuestion")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

QuestionCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(QuestionCreate))
