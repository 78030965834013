import { ADD_NEW_COLORTYPE, ADD_COLORTYPE_SUCCESS, ADD_COLORTYPE_FAIL, GET_COLORTYPES, GET_COLORTYPES_SUCCESS, GET_COLORTYPES_FAIL } from "./actionTypes"

export const getColorTypesActions = () => ({
  type: GET_COLORTYPES,
})

export const getColorTypeSuccess = ColorTypes => ({
  type: GET_COLORTYPES_SUCCESS,
  payload: ColorTypes,
})

export const getColorTypeFail = error => ({
  type: GET_COLORTYPES_FAIL,
  payload: error,
})

export const addNewColorType = colorType => ({
  type: ADD_NEW_COLORTYPE,
  payload: colorType,
})

export const addColorTypeSuccess = colorType => ({
  type: ADD_COLORTYPE_SUCCESS,
  payload: colorType,
})

export const addColorTypeFail = error => ({
  type: ADD_COLORTYPE_FAIL,
  payload: error,
})
