/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

/**
 * add Categorie
 */
export const ADD_NEW_CATEGORIE = "ADD_NEW_CATEGORIE"
export const ADD_CATEGORIE_SUCCESS = "ADD_CATEGORIE_SUCCESS"
export const ADD_CATEGORIE_FAIL = "ADD_CATEGORIE_FAIL"
export const UPDATE_CATEGORIE = "UPDATE_CATEGORIE"
export const UPDATE_CATEGORIE_SUCCESS = "UPDATE_CATEGORIE_SUCCESS"
export const UPDATE_CATEGORIE_FAIL = "ADD_CATEGORIE_FAIL"
export const DELETE_CATEGORIE = "DELETE_CATEGORIE"
export const DELETE_CATEGORIE_SUCCESS = "DELETE_CATEGORIE_SUCCESS"
export const DELETE_CATEGORIE_FAIL = "DELETE_CATEGORIE_FAIL"