import { call, put, takeEvery } from "redux-saga/effects"

import { GET_SECTEURS, ADD_NEW_SECTEUR, DELETE_SECTEUR, UPDATE_SECTEUR } from "./actionTypes"
import {
  getSecteurSuccess,
  getSecteurFail,
  addSecteurSuccess,
  addSecteurFail, deleteSecteurSuccess, deleteSecteurFail, updateSecteurSuccess, updateSecteurFail
} from "./actions"

import {
  get_Secteurs_Api,
  add_NewSecteur_Api,
  delete_Menu_Api,
  delete_Secteur_Api,
  update_Menu_Api, update_Secteur_Api
} from "helpers/backend"
import { deleteMenuFail, deleteMenuSuccess, updateMenuFail, updateMenuSuccess } from "../menus/actions"

function* fetchSecteurs() {
  try {
    const response = yield call(get_Secteurs_Api)
    yield put(getSecteurSuccess(response.data))
  } catch (error) {
    yield put(getSecteurFail(error))
  }
}

function* onAddNewSecteurApi({ payload: secteur }) {
  try {
    const response = yield call(add_NewSecteur_Api, secteur)

    yield put(addSecteurSuccess(response))
  } catch (error) {
    yield put(addSecteurFail(error))
  }
}
function* onDeleteSecteur({ payload: id }) {
  try {
    const response = yield call(delete_Secteur_Api, id)
    yield put(deleteSecteurSuccess(id))
  } catch (error) {
    yield put(deleteSecteurFail(error))
  }
}
function* onUpdateSecteur({payload: secteur}) {
  try {
    const response = yield call(update_Secteur_Api, secteur)
    yield put(updateSecteurSuccess(secteur))
  } catch (error) {
    yield put(updateSecteurFail(error))
  }
}
function* secteurSaga() {
  yield takeEvery(GET_SECTEURS, fetchSecteurs)
  yield takeEvery(ADD_NEW_SECTEUR, onAddNewSecteurApi)
  yield takeEvery(DELETE_SECTEUR, onDeleteSecteur)
  yield takeEvery(UPDATE_SECTEUR, onUpdateSecteur)
}

export default secteurSaga
