import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { menuData } from "../../helpers/utils"

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  const user =JSON.parse( localStorage.getItem("authUser"));
  const role = user.user.role
  let sidebarMenu = menuData
  let firstMenu = [...menuData].slice(0,2)
  let secondMenu = [...menuData].slice(2,menuData.length)



  return (
    <React.Fragment>
      <SimpleBar className="h-100 mb-2" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {
              firstMenu.splice(0,2).map((menu,index) => (
                <li key={index}>
                  <Link to={menu.href}>
                    <i className={menu.icon}></i>
                    <span>{props.t(menu.name)}</span>
                  </Link>
                </li>
              ))
            }
            <li className="menu-title">{props.t("Apps")}</li>
            {
              (role === 'USER' ? secondMenu.filter(item=>item.name ==='Answers'):secondMenu).map((menu,index)=> (
                <li key={index}>
                  <Link to={menu.href} className="has-arrow ">
                    <i className={menu.icon}></i>
                    <span>{props.t(menu.name)}</span>
                  </Link>
                  <ul className="sub-menu">
                    {
                      menu.sub_menu.map((submenu,index)=> (
                        <li key={index}>
                          <Link to={submenu.href}>{props.t(submenu.name)}</Link>
                        </li>
                      ))
                    }
                  </ul>
                </li>
              ))
            }

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
