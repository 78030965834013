import React, { Component, useEffect } from "react"

import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { FaRegWindowClose } from "react-icons/fa"

const ToastComponent = ({color,message,title,onClose,time }) => {
  const handleClose = ()=>{
    return setTimeout(()=>{
      onClose()
    }, (time || 1000))
  }
  useEffect(()=>{handleClose()},[])
  return (
    <>
      <Toast isOpen={true} color={color} style={{position:"fixed",top:'10vh', right:0,zIndex:2,background:'white'}}>
        <ToastHeader toggle={onClose}>
          {title}
        </ToastHeader>
        <ToastBody>
          {message}
        </ToastBody>
      </Toast>
    </>

)
}


export default ToastComponent