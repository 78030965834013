import {
  GET_BARYCENTRE_USER,
  GET_BARYCENTRE_USER_SUCCESS,
  GET_BARYCENTRE_USER_FAIL,
  GET_BARYCENTRE_NIVEAU,
  GET_BARYCENTRE_NIVEAU_SUCCESS,
  GET_BARYCENTRE_NIVEAU_FAIL,
} from "./actionTypes"

export const getAverage = id => ({
  type: GET_BARYCENTRE_USER,
  id,
})

export const getAverageSuccess = average => ({
  type: GET_BARYCENTRE_USER_SUCCESS,
  payload: average,
})

export const getAverageFail = error => ({
  type: GET_BARYCENTRE_USER_FAIL,
  payload: error,
})

export const getBarycentreByNiveau = barycentre_niveau => ({
  type: GET_BARYCENTRE_NIVEAU,
  barycentre_niveau
})

export const getBarycentreByNiveauSuccess = barycentre_niveau => ({
  type: GET_BARYCENTRE_NIVEAU_SUCCESS,
  payload: barycentre_niveau,
})

export const getBarycentreByNiveauFail = error => ({
  type: GET_BARYCENTRE_NIVEAU_FAIL,
  payload: error,
})