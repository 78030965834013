/* SPRINTS */
export const GET_SPRINTS = "GET_SPRINTS"
export const GET_SPRINTS_SUCCESS = "GET_SPRINTS_SUCCESS"
export const GET_SPRINTS_FAIL = "GET_SPRINTS_FAIL"

/**
 * add Sprint
 */
export const ADD_NEW_SPRINT = "ADD_NEW_SPRINT"
export const ADD_SPRINT_SUCCESS = "ADD_SPRINT_SUCCESS"
export const ADD_SPRINT_FAIL = "ADD_SPRINT_FAIL"

/**
 * Edit Sprint
 */
export const UPDATE_SPRINT = "UPDATE_SPRINT"
export const UPDATE_SPRINT_SUCCESS = "UPDATE_SPRINT_SUCCESS"
export const UPDATE_SPRINT_FAIL = "UPDATE_SPRINT_FAIL"

/**
 * Delete Sprint
 */
export const DELETE_SPRINT = "DELETE_SPRINT"
export const DELETE_SPRINT_SUCCESS = "DELETE_SPRINT_SUCCESS"
export const DELETE_SPRINT_FAIL = "DELETE_SPRINT_FAIL"