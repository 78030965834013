import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteComponent from "components/Common/DeleteComponent"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

import SprintUpdate from "./sprint-update"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getSprintsActions, deleteSprint } from "store/sprints/actions"

//i18n
import { withTranslation } from "react-i18next"

export const SprintList = props => {
  // meta title
  document.title = props.t("Sprint")

  const dispatch = useDispatch()

  const { sprints } = useSelector(state => ({
    sprints: state.sprints.sprints,
  }))

  const { loading } = useSelector(state => ({
    loading: state.sprints.loading,
  }))

  const [modal_standard, setmodal_standard] = useState(false)
  const [sprint_selected, setsprint_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const handleDelete = (id, name) => {
    // console.log({ name })
    dispatch(deleteSprint(id))
    dispatch(getSprintsActions())
  }

  function showToast() {
    const title = props.t("Sprint")
    const message = props.t("SprintListMessage")
    const positionClass = "toast-top-right"
    const showEasing = "swing"
    const hideEasing = "linear"
    const showMethod = "fadeIn"
    const hideMethod = "fadeOut"
    const showDuration = 300
    const hideDuration = 1000
    const timeOut = 5000
    const extendedTimeOut = 1000
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    }
    toastr.success(message, title)
  }

  useEffect(() => {
    if (sprints && !sprints?.length) {
      dispatch(getSprintsActions())
      showToast()
    }
  }, [sprints?.length])

  const language = localStorage.getItem("I18N_LANGUAGE")

  //   ------------------------- Loader ---------------------------------//
  if (loading && sprints?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Run")}
            breadcrumbItem={props.t("SprintList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("SprintList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th>{props.t("Code")}</th>
                          <th>{props.t("Scope")}</th>
                          <th>{props.t("organisation")}</th>
                          <th>{props.t("menu")}</th>
                          <th className="d-flex justify-content-center">{props.t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          sprints?.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{item?.code}</th>
                              <td>
                                {language === "fr"
                                  ? item?.scope_fr
                                  : item?.scope_en}
                              </td>
                              <th scope="row">
                                {item?.organisation?.short_code_org}
                              </th>
                              <td>
                                {language === "fr"
                                  ? item?.menu?.fr
                                  : item?.menu?.en}
                              </td>
                              <td>
                                <div className="d-flex justify-content-around">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                      setname_selected(
                                        language === "fr"
                                          ? item?.scope_fr
                                          : item?.scope_en
                                      )
                                      setsprint_selected(item?._id)
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setname_selected(
                                        language === "fr"
                                          ? item?.scope_fr
                                          : item?.scope_en
                                      )
                                      setsprint_selected(item?._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>

                                <SprintUpdate
                                  modal_standard={modal_standard}
                                  id_sprint={sprint_selected}
                                  setsprint_selected={setsprint_selected}
                                  setmodal_standard={setmodal_standard}
                                  tog_standard={tog_standard}
                                />

                                <DeleteComponent
                                  tog_center={tog_center}
                                  name={name_selected}
                                  id={sprint_selected}
                                  setmodal_center={setmodal_center}
                                  modal_center={modal_center}
                                  handleDelete={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SprintList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SprintList))
