import PropTypes from "prop-types"
import React, { useState, useTransition } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteComponent from "components/Common/DeleteComponent"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import "toastr/build/toastr.min.css"

import MenuUpdate from "./menu-update"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { deleteMenu } from "store/menus/actions"

//i18n
import { withTranslation } from "react-i18next"
import MenuCreate from "../MenuCreate/menu-create"

export const MenuList = props => {
  // meta title
  document.title = props.t("Menus")

  const dispatch = useDispatch()

  const { menus } = useSelector(state => ({
    menus: state.menus.menus,
  }))

  const { loading } = useSelector(state => ({
    loading: state.menus.loading,
  }))

  console.log("MENUS", menus)

  const [modal_standard, setmodal_standard] = useState(false)
  const [menu_selected, setmenu_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const handleDelete = (id, name) => {
    dispatch(deleteMenu(id))
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  function moyenne_01(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_01)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }
  function moyenne_02(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_02)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }
  function moyenne_03(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_03)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }
  function moyenne_04(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_04)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }
  function moyenne_05(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_05)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }
  function moyenne_06(tableau) {
    if (tableau && tableau?.length === 0) return 0
    // Variables pour stocker le total et le nombre d'entiers
    let total = 0
    let nombreEntiers = 0

    // Parcours du tableau
    for (const objet of tableau) {
      // Conversion de la valeur en nombre (si c'est un entier)
      const valeurEntier = parseInt(objet.percent_zone_06)

      // Vérification si la conversion a réussi et si c'est un entier
      if (!isNaN(valeurEntier)) {
        total += valeurEntier
        nombreEntiers++
      }
    }
    return total / nombreEntiers
  }

  // ------------------------- Loader ---------------------------------//
  if (loading && menus.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  // console.log("this is the state value",[menus])
  const [updateMenu, setUpdateMenu] = useState(undefined)

  return (
    <React.Fragment>
      {updateMenu ? (
        <FullModalEdit
          open={updateMenu !== undefined}
          onClose={() => setUpdateMenu(undefined)}
          menu={updateMenu}
          props={props}
        />
      ) : null}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Menus")}
            breadcrumbItem={props.t("MenuList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("MenuList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{props.t("MenuName")}</th>
                          {/* <th>{props.t("ThemeName")}</th> */}
                          <th style={{ width: "70px" }}>{props.t("Z1")}</th>
                          <th style={{ width: "70px" }}>{props.t("Z2")}</th>
                          <th style={{ width: "70px" }}>{props.t("Z3")}</th>
                          <th style={{ width: "70px" }}>{props.t("Z4")}</th>
                          <th style={{ width: "70px" }}>{props.t("Z5")}</th>
                          <th style={{ width: "70px" }}>{props.t("Z6")}</th>
                          <th>{props.t("Q1")}</th>
                          <th>{props.t("Q3")}</th>
                          <th>{props.t("Q2")}</th>
                          <th>{props.t("Q4")}</th>
                          <th>{props.t("INT")}</th>
                          <th>{props.t("EXT")}</th>
                          <th>{props.t("Actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {menus.map((menu, index) => (
                          <tr key={menu._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{language === "fr" ? menu.fr : menu.en}</td>
                            {/* {menu.themes.map((item, index) => ( */}
                            {/* <td>0</td> */}
                            {/* ))} */}
                            <td>
                              {moyenne_01(menu.themes)
                                ? moyenne_01(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_02(menu.themes)
                                ? moyenne_02(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_03(menu.themes)
                                ? moyenne_03(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_04(menu.themes)
                                ? moyenne_04(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_05(menu.themes)
                                ? moyenne_05(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_06(menu.themes)
                                ? moyenne_06(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_01(menu.themes) ||
                              moyenne_03(menu.themes)
                                ? (
                                    moyenne_01(menu.themes) +
                                    moyenne_03(menu.themes)
                                  ).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_05(menu.themes)
                                ? moyenne_05(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_02(menu.themes) ||
                              moyenne_04(menu.themes)
                                ? (
                                    moyenne_02(menu.themes) +
                                    moyenne_04(menu.themes)
                                  ).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_06(menu.themes)
                                ? moyenne_06(menu.themes).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_01(menu.themes) ||
                              moyenne_03(menu.themes) ||
                              moyenne_05(menu.themes)
                                ? (
                                    moyenne_01(menu.themes) +
                                    moyenne_03(menu.themes) +
                                    moyenne_05(menu.themes)
                                  ).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              {moyenne_02(menu.themes) ||
                              moyenne_04(menu.themes) ||
                              moyenne_06(menu.themes)
                                ? (
                                    moyenne_02(menu.themes) +
                                    moyenne_04(menu.themes) +
                                    moyenne_06(menu.themes)
                                  ).toFixed(2)
                                : 0}
                              %
                            </td>
                            <td>
                              <div className="d-flex justify-content-around">
                                <button
                                  type="button"
                                  onClick={() => {
                                    tog_standard()
                                    setmenu_selected(menu._id)
                                    setUpdateMenu(menu)
                                  }}
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                >
                                  <i className="bx bx-edit"></i>
                                  {""}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-dark"
                                  onClick={() => {
                                    tog_center()
                                    setname_selected(
                                      language === "fr" ? menu.fr : menu.en
                                    )
                                    setmenu_selected(menu._id)
                                  }}
                                >
                                  <i className="bx bx-trash"></i>
                                  {""}
                                </button>
                              </div>

                              <DeleteComponent
                                tog_center={tog_center}
                                name={name_selected}
                                id={menu_selected}
                                setmodal_center={setmodal_center}
                                modal_center={modal_center}
                                handleDelete={handleDelete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const FullModalEdit = props => {
  const { open, onClose, menu } = props
  return (
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>{/*{menu.fr}*/}</ModalHeader>
      <ModalBody>
        <MenuCreate initialState={menu} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

MenuList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MenuList))
