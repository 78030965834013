import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  GET_USERS_USERS_SUCCESS,
  ADD_USER_USER_FAIL,
  ADD_USER_USER_SUCCESS,
  GET_USERS_USERS_FAIL,
  UPDATE_USER_USER_SUCCESS,
  UPDATE_USER_USER_FAIL,
  DELETE_USER_USER_FAIL,
  DELETE_USER_USER_SUCCESS,
} from "./actionTypes"
import { t } from "i18next"

const INIT_STATE = {
  users_users: [],
  user_user: {},
  error: {},
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_USERS_SUCCESS:
      return {
        ...state,
        users_users: action.payload,
      }

    case GET_USERS_USERS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_USER_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        users_users: [...state.users_users, action.payload],
      }

    case ADD_USER_USER_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_USER_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        users_users: state.users_users?.map(user =>
          user?._id === action.payload.id ? { user, ...action.payload } : user
        ),
      }

    case UPDATE_USER_USER_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_USER_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      return {
        ...state,
        users_users: state.users_users?.filter(
          user => user?._id !== action.payload
        ),
      }

    case DELETE_USER_USER_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Users
