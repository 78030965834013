import {
  ADD_NEW_SPRINT,
  ADD_SPRINT_SUCCESS,
  ADD_SPRINT_FAIL,
  GET_SPRINTS,
  GET_SPRINTS_SUCCESS,
  GET_SPRINTS_FAIL,
  UPDATE_SPRINT,
  UPDATE_SPRINT_SUCCESS,
  UPDATE_SPRINT_FAIL,
  DELETE_SPRINT,
  DELETE_SPRINT_SUCCESS,
  DELETE_SPRINT_FAIL,
} from "./actionTypes"

export const getSprintsActions = () => ({
  type: GET_SPRINTS,
})

export const getSprintSuccess = Sprints => ({
  type: GET_SPRINTS_SUCCESS,
  payload: Sprints,
})

export const getSprintFail = error => ({
  type: GET_SPRINTS_FAIL,
  payload: error,
})

export const addNewSprint = sprint => ({
  type: ADD_NEW_SPRINT,
  payload: sprint,
})

export const addSprintSuccess = sprint => ({
  type: ADD_SPRINT_SUCCESS,
  payload: sprint,
})

export const addSprintFail = error => ({
  type: ADD_SPRINT_FAIL,
  payload: error,
})

export const updateSprint = sprint => ({
  type: UPDATE_SPRINT,
  payload: sprint,
})

export const updateSprintSuccess = sprint => ({
  type: UPDATE_SPRINT_SUCCESS,
  payload: sprint,
})

export const updateSprintFail = error => ({
  type: UPDATE_SPRINT_FAIL,
  payload: error,
})

export const deleteSprint = id => ({
  type: DELETE_SPRINT,
  payload: id,
})

export const deleteSprintSuccess = id => ({
  type: DELETE_SPRINT_SUCCESS,
  payload: id,
})

export const deleteSprintFail = error => ({
  type: DELETE_SPRINT_FAIL,
  payload: error,
})