/**
 * add questionnaire
 */
export const ADD_NEW_QUESTIONNAIRE_ADMIN = "ADD_NEW_QUESTIONNAIRE_ADMIN"
export const ADD_QUESTIONNAIRE_ADMIN_SUCCESS = "ADD_QUESTIONNAIRE_ADMIN_SUCCESS"
export const ADD_QUESTIONNAIRE_ADMIN_FAIL = "ADD_QUESTIONNAIRE_ADMIN_FAIL"

/* QUESTIONNAIRE */
export const GET_QUESTIONNAIRE_DETAIL = "GET_QUESTIONNAIRE_DETAIL"
export const GET_QUESTIONNAIRE_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_DETAIL_FAIL = "GET_QUESTIONNAIRE_DETAIL_FAIL"

/* QUESTIONNAIRES */
export const GET_QUESTIONNAIRES = "GET_QUESTIONNAIRES"
export const GET_QUESTIONNAIRES_SUCCESS = "GET_QUESTIONNAIRES_SUCCESS"
export const GET_QUESTIONNAIRES_FAIL = "GET_QUESTIONNAIRES_FAIL"

/* QUESTIONNAIRE BY ID */
export const GET_QUESTIONNAIRE_DETAIL_BY_ID = "GET_QUESTIONNAIRE_DETAIL_BY_ID"
export const GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS = "GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS"
export const GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL = "GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL"