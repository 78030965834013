import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import * as LayoutActions from "../../store/actions";
import * as EntityActions from "store/actions";

// Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

const Layout = (props) => {
  const dispatch = useDispatch();
  const layoutState = useSelector((state) => state.Layout);

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType,
  } = layoutState;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    const newSidebarType = leftSideBarType === "default" ? "condensed" : "default";
    dispatch(LayoutActions.changeSidebarType(newSidebarType, isMobile));
  };

  const hideRightbar = (event) => {
    const rightbar = document.getElementById("right-bar");
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      dispatch(LayoutActions.showRightSidebarAction(false));
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", hideRightbar, true);
    return () => {
      document.body.removeEventListener("click", hideRightbar, true);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useCustomEffect = (action) => {
    useEffect(() => {
      dispatch(action());
    }, [dispatch]);
  };

  useCustomEffect(EntityActions.get_UsersActions);
  useCustomEffect(EntityActions.getMenusActions);
  useCustomEffect(EntityActions.getOrganisationsActions);
  useCustomEffect(EntityActions.getNiveauxActions);
  useCustomEffect(EntityActions.getThemesActions);
  useCustomEffect(EntityActions.getSprintsActions);
  useCustomEffect(EntityActions.getSecteursActions);
  useCustomEffect(EntityActions.getColorTypesActions);
  useCustomEffect(EntityActions.getQuestionnaires);

  useCustomEffect(() => LayoutActions.changeSidebarTheme(leftSideBarTheme));
  useCustomEffect(() => LayoutActions.changeLayoutMode(layoutModeType));
  useCustomEffect(() => LayoutActions.changeSidebarThemeImage(leftSideBarThemeImage));
  useCustomEffect(() => LayoutActions.changeLayoutWidth(layoutWidth));
  useCustomEffect(() => LayoutActions.changeSidebarType(leftSideBarType));
  useCustomEffect(() => LayoutActions.changeTopbarTheme(topbarTheme));

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);

