import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getAnswersActions } from "store/answers/actions"

//i18n
import { withTranslation } from "react-i18next"
import Pagination from "components/Common/ItemsPagination"

export const AnswerList = props => {
  // meta title
  document.title = props.t("Answers")

  const dispatch = useDispatch()

  const { answers } = useSelector(state => ({
    answers: state.answers.answers,
  }))

  const { loading } = useSelector(state => ({
    loading: state.answers.loading,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  const [page, setPage] = useState(1)
  const ITEMS_PER_PAGE = 8
  const [dataList, setDataList] = useState(
    answers?.data?.slice(0, ITEMS_PER_PAGE) || []
  )
  const handlePaginate = (page, offset) => {
    if (answers?.data?.length > 0) {
      setPage(page + 1)
      setDataList(answers?.data?.slice(offset, offset + ITEMS_PER_PAGE))
    }
  }

  useEffect(() => {
    if (answers && !answers?.data?.length) {
      dispatch(getAnswersActions())
      // showToast()
    }
    setDataList(answers?.data?.slice(0, ITEMS_PER_PAGE))
  }, [answers?.data?.length])

  useEffect(() => {
    if (answers?.data?.length > 0) {
      setDataList(answers?.data.slice(0, ITEMS_PER_PAGE))
    }
  }, [answers?.data])

  //   ------------------------- Loader ---------------------------------//
  if (loading && answers?.data?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Answers")}
            breadcrumbItem={props.t("AnswerList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("AnswerList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{props.t("User")}</th>
                          <th>{props.t("Question")}</th>
                          <th>{props.t("Answer")} 1</th>
                          <th>{props.t("Answer")} 2</th>
                          <th>{props.t("Answer")} 3</th>
                          <th>{props.t("Answer")} 4</th>
                          <th>{props.t("Answer")} 5</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList &&
                          dataList.map((item, index) => (
                            <tr key={item?._id}>
                              <th scope="row">{index + 1}</th>
                              <td>
                                {item?.user?.first_name} {item?.user?.last_name}
                              </td>
                              <td>
                                {language === "fr"
                                  ? item?.question?.question_name_fr
                                  : item?.question?.question_name_en}
                              </td>
                              <td>{item?.value_T0}</td>
                              <td>{item?.value_T1}</td>
                              <td>{item?.value_T2}</td>
                              <td>{item?.value_T3}</td>
                              <td>{item?.value_T4}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Pagination
                      items={answers?.data}
                      itemsPerPage={ITEMS_PER_PAGE}
                      handlePaginate={(page, offset) =>
                        handlePaginate(page, offset)
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AnswerList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AnswerList))
