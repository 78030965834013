import {
  ADD_QUESTIONNAIRE_ADMIN_SUCCESS,
  ADD_QUESTIONNAIRE_ADMIN_FAIL,
  GET_QUESTIONNAIRE_DETAIL_SUCCESS,
  GET_QUESTIONNAIRE_DETAIL_FAIL,
  GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS,
  GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL,
  GET_QUESTIONNAIRES_SUCCESS,
  GET_QUESTIONNAIRES_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  questionnaireAdmins: [],
  questionnaires: [],
  questionnaireAdmin: {},
  questionnaireDetail: {},
  questionnaireDetailById: {},
  loading: false,
  error: null,
}

const QuestionnaireAdmins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_QUESTIONNAIRE_ADMIN_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        questionnaireAdmin: action.payload,
        error: null,
      }

    case ADD_QUESTIONNAIRE_ADMIN_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUESTIONNAIRE_DETAIL_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_FETCH"))
      return {
        ...state,
        questionnaireDetail: action.payload,
      }

    case GET_QUESTIONNAIRE_DETAIL_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }
    
      case GET_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        questionnaires: action.payload,
      }

    case GET_QUESTIONNAIRES_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUESTIONNAIRE_DETAIL_BY_ID_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_FETCH"))
      return {
        ...state,
        questionnaireDetailById: action.payload,
      }

    case GET_QUESTIONNAIRE_DETAIL_BY_ID_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default QuestionnaireAdmins
