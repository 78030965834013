import {
  ADD_NEW_SECTEUR,
  ADD_SECTEUR_SUCCESS,
  ADD_SECTEUR_FAIL,
  GET_SECTEURS,
  GET_SECTEURS_SUCCESS,
  GET_SECTEURS_FAIL,
  DELETE_SECTEUR,
  DELETE_SUCCESS,
  DELETE_SECTEUR_SUCCESS,
  DELETE_SECTEUR_FAIL,
  UPDATE_SECTEUR,
  UPDATE_SECTEUR_SUCCESS, UPDATE_SECTEUR_FAIL
} from "./actionTypes"
import {
  DELETE_MENU_FAIL,
  DELETE_MENU_SUCCESS,
  UPDATE_MENU,
  UPDATE_MENU_FAIL,
  UPDATE_MENU_SUCCESS
} from "../menus/actionTypes"
  
  export const getSecteursActions = () => ({
    type: GET_SECTEURS,
  })
  
  export const getSecteurSuccess = secteurs => ({
    type: GET_SECTEURS_SUCCESS,
    payload: secteurs,
  })
  
  export const getSecteurFail = error => ({
    type: GET_SECTEURS_FAIL,
    payload: error,
  })
  
  export const addNewSecteur = secteur => ({
    type: ADD_NEW_SECTEUR,
    payload: secteur,
  })
  
  export const addSecteurSuccess = secteur => ({
    type: ADD_SECTEUR_SUCCESS,
    payload: secteur,
  })
  
  export const addSecteurFail = error => ({
    type: ADD_SECTEUR_FAIL,
    payload: error,
  })
export const updateSecteur = secteur => ({
  type: UPDATE_SECTEUR,
  payload: secteur,
})

export const updateSecteurSuccess = menu => ({
  type: UPDATE_SECTEUR_SUCCESS,
  payload: menu,
})

export const updateSecteurFail = error => ({
  type: UPDATE_SECTEUR_FAIL,
  payload: error,
})

export  const deleteSecteur= id =>({
  type:DELETE_SECTEUR,
  payload:id
})
export const deleteSecteurSuccess = id => ({
  type: DELETE_SECTEUR_SUCCESS,
  payload: id,
})

export const deleteSecteurFail = error => ({
  type: DELETE_SECTEUR_FAIL,
  payload: error,
})
  