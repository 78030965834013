import {
  GET_MENUS_SUCCESS,
  GET_MENUS_FAIL,
  ADD_MENU_SUCCESS,
  ADD_MENU_FAIL,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAIL,
  DELETE_MENU_FAIL,
  DELETE_MENU_SUCCESS,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  menus: [],
  menu: {},
  error: {},
}

const Menus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MENUS_SUCCESS:
      return {
        ...state,
        menus: action.payload,
      }

    case GET_MENUS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MENU_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        menus: [...state.menus, action.payload],
      }

    case ADD_MENU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_MENU_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        menus: state.menus.map(menu =>
          menu._id === action.payload.id ? { menu, ...action.payload } : menu
        ),
      }

    case UPDATE_MENU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MENU_SUCCESS: {
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      let data = {
        ...state,
        menus: state.menus.filter(item => item._id !== action.payload),
      }
      return data
    }

    case DELETE_MENU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Menus
