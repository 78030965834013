import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
  Tooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
//redux
import { useDispatch, useSelector } from "react-redux"
import {
  addNewMenu as onAddNewMenu,
  getMenusActions,
  updateMenu,
} from "store/menus/actions"

//import images
import logo from "assets/images/logo.jpg"
import { useHistory } from "react-router-dom"
const MenuCreate = props => {
  const { initialState, onClose } = props
  // meta title
  // document.title = props.t("NewMenu")

  const history = useHistory()

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const { themes } = useSelector(state => ({
    themes: state.themes.themes,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  // console.log("PROPS", props)

  const toggleToast = () => {
    setToast(!toast)
  }
  useEffect(() => {
    dispatch(getMenusActions())
  }, [])
  const [selectTheme, setSelectTheme] = useState(
    (initialState && initialState?.themes.map(theme => theme._id)) || []
  )
  const [menuName, setMenuName] = useState(
    (initialState && initialState[language]) || ""
  )
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fr: menuName,
      en: menuName,
      themes: selectTheme,
    },
    validationSchema: Yup.object({
      fr: Yup.string().optional("Please Enter Your French Menu Name"),
      en: Yup.string().optional("Please Enter Your English Menu Name"),
      themes: Yup.array().required("Veuillez sélectionner au moins un thème"),
    }),
    onSubmit: values => {
      // save new themes
      // // console.log({values})
      if (initialState) {
        onClose()
        const { fr, en } = values
        if (language === "fr") {
          values = { ...values, fr: fr, en: initialState.en }
        } else {
          values = { ...values, fr: initialState.fr, en: en }
        }
        let data = {
          ...values,
          ...(initialState ? { id: initialState._id } : {}),
        }
        return dispatch(updateMenu(data))
      }
      dispatch(onAddNewMenu(values))
      // toggleToast()
      // history.push("/menu-list")
      location.href = "/menu-list"
      validation.resetForm()
    },
  })

  const handleOptionClick = optionValue => {
    const { themes } = validation.values
    const selectedIndex = themes.indexOf(optionValue)

    if (selectedIndex === -1) {
      // L'option n'est pas encore sélectionnée, ajoutons-la.
      validation.setFieldValue("themes", [...themes, optionValue])
    } else {
      // L'option est déjà sélectionnée, retirons-la.
      const newSelectedOptions = [...themes]
      newSelectedOptions.splice(selectedIndex, 1)
      validation.setFieldValue("themes", newSelectedOptions)
    }
  }

  const [tooltipOpen, setTooltipOpen] = useState("")

  const toggle = () => setTooltipOpen("")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {initialState ? null : (
            <Breadcrumbs
              title={props.t("Menus")}
              breadcrumbItem={props.t("NewMenu")}
            />
          )}
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("MenuMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {initialState
                      ? "Mise à jour du menu" + " " + initialState[language]
                      : props.t("NewMenu")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("translate_key_menu_name")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("translate_key_menu_name")}
                          onChange={event => setMenuName(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="fr_input"
                          name={language === "fr" ? "fr" : "en"}
                          type="text"
                          value={menuName}
                          invalid={
                            language === "fr"
                              ? validation.touched.fr && validation.errors.fr
                                ? true
                                : false
                              : validation.touched.en && validation.errors.en
                              ? true
                              : false
                          }
                        />
                        {language === "fr" ? (
                          validation.touched.fr && validation.errors.fr ? (
                            <FormFeedback type="invalid">
                              {validation.errors.fr}
                            </FormFeedback>
                          ) : null
                        ) : validation.touched.en && validation.errors.en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="en_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("translate_key_menu_chose_theme") + ":"}
                      </Label>
                      <div className="col-md-10">
                        {themes.map((theme, index) => (
                          <Button
                            key={index}
                            className={"m-1"}
                            style={{
                              maxWidth: "200px",
                              width: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            outline={!selectTheme.includes(theme._id)}
                            id={"Tooltip-" + theme._id}
                            onClick={() =>
                              selectTheme.includes(theme._id)
                                ? setSelectTheme(
                                    selectTheme.filter(x => x !== theme._id)
                                  )
                                : setSelectTheme([...selectTheme, theme._id])
                            }
                          >
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                              }}
                            >
                              {theme[`theme_name_${language}`]}
                            </span>
                            <Tooltip
                              isOpen={tooltipOpen}
                              target={"Tooltip-" + theme._id}
                              toggle={toggle}
                              placement="top"
                            >
                              {theme[`theme_name_${language}`]}
                            </Tooltip>
                          </Button>
                        ))}
                        {validation.touched.themes &&
                        validation.errors.themes ? (
                          <FormFeedback type="invalid">
                            {validation.errors.themes}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {initialState ? "Mettre à Jour" : props.t("SubmitMenu")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MenuCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MenuCreate))
