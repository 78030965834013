import { call, put, takeEvery } from "redux-saga/effects"

import { GET_CATEGORIES, ADD_NEW_CATEGORIE, DELETE_CATEGORIE, UPDATE_CATEGORIE } from "./actionTypes"
import {
  getCategorieSuccess,
  getCategorieFail,
  addCategorieSuccess,
  addCategorieFail,
  delete_CategorieSuccess, delete_CategorieFail, updateCategorieSuccess, updateCategorieFail
} from "./actions"

import {
  get_Categories_Api,
  add_NewCategorie_Api,
  delete_Theme_Api,
  delete_Categories_Api,
  update_Theme_Api, update_Categorie_Api
} from "helpers/backend"
import { delete_ThemeFail, delete_ThemeSuccess, updateThemeFail, updateThemeSuccess } from "../themes/actions"

function* fetchCategories() {
  try {
    const response = yield call(get_Categories_Api)
    yield put(getCategorieSuccess(response.data))
  } catch (error) {
    yield put(getCategorieFail(error))
  }
}

function* onAddNewCategorieApi({ payload: categorie }) {
  try {
    const response = yield call(add_NewCategorie_Api, categorie)

    yield put(addCategorieSuccess(response))
  } catch (error) {
    yield put(addCategorieFail(error))
  }
}
function* onDeleteCategorie({ payload: id }) {
  try {
    const response = yield call(delete_Categories_Api, id)
    yield put(delete_CategorieSuccess(id))
  } catch (error) {
    yield put(delete_CategorieFail(error))
  }
}
function* onUpdateCategorie({payload: categorie}) {
  try {
    const response = yield call(update_Categorie_Api, categorie)
    yield put(updateCategorieSuccess(categorie))
  } catch (error) {
    yield put(updateCategorieFail(error))
  }
}

function* categorieSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_NEW_CATEGORIE, onAddNewCategorieApi)
  yield takeEvery(DELETE_CATEGORIE, onDeleteCategorie)
  yield takeEvery(UPDATE_CATEGORIE, onUpdateCategorie)

}

export default categorieSaga
