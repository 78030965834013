import { call, put, takeEvery } from "redux-saga/effects"

import { GET_ANSWERS, ADD_NEW_ANSWER } from "./actionTypes"
import {
  getAnswerSuccess,
  getAnswerFail,
  addAnswerSuccess,
  addAnswerFail,
} from "./actions"

import { get_Answers_Api, add_NewAnswer_Api } from "helpers/backend"

function* fetchAnswers() {
  try {
    const response = yield call(get_Answers_Api)
    yield put(getAnswerSuccess(response))
  } catch (error) {
    yield put(getAnswerFail(error))
  }
}

function* onAddNewAnswerApi({ payload: answer }) {
  try {
    const response = yield call(add_NewAnswer_Api, answer)

    yield put(addAnswerSuccess(response))
  } catch (error) {
    yield put(addAnswerFail(error))
  }
}

function* answerSaga() {
  yield takeEvery(GET_ANSWERS, fetchAnswers)
  yield takeEvery(ADD_NEW_ANSWER, onAddNewAnswerApi)
}

export default answerSaga
