import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
//redux
import { useDispatch, useSelector } from "react-redux"
import { getOrganisationsActions } from "store/organisations/actions"
import { addNewQuestionnaireAdmin as onAddNewQuestionnaireAdmin } from "store/questionnaireAdmin/actions"
import { getMenusActions } from "store/menus/actions"
// import { getSprintsActions } from "store/sprints/actions"

//import images
import logo from "assets/images/logo.jpg"

export const QuestionnaireAdmin = props => {
  // meta title
  document.title = props.t("NewQuestionnaire")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)
  const [userId, setuserId] = useState("")

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { sprints } = useSelector(state => ({
    sprints: state.sprints.sprints,
  }))

  const { menus } = useSelector(state => ({
    menus: state.menus.menus,
  }))

  const { questionnaireAdmin } = useSelector(state => ({
    questionnaireAdmin: state.questionnaireAdmin.questionnaireAdmin,
  }))

  //   const { loading } = useSelector(state => ({
  //     loading: state.organisations.loading,
  //   }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  const toggleToast = () => {
    setToast(!toast)
  }

  useEffect(() => {
    if (organisations && !organisations?.length) {
      dispatch(getOrganisationsActions())
      //   showToast()
    }
  }, [organisations?.length])

  // useEffect(() => {
  //   if (sprints && !sprints?.length) {
  //     dispatch(getSprintsActions())
  //     // showToast()
  //   }
  // }, [sprints?.length])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setuserId(obj?.user?._id)
    }
  }, [props.success])

  useEffect(() => {
    if (menus && !menus?.length) {
      dispatch(getMenusActions())
      // showToast()
    }
  }, [menus?.length])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      organisation: "",
      menu: "",
      name: "",
      sprint: "",
    },
    validationSchema: Yup.object({
      organisation: Yup.string().required("Please Enter Your French Menu Name"),
      menu: Yup.string().required("Please Enter Your menu"),
      name: Yup.string().required("Please Enter Questionnaire Name"),
      sprint: Yup.string().required("Please Enter Your sprint"),
    }),
    onSubmit: values => {
      const data = {
        ...values,
        user: userId,
      }
      dispatch(onAddNewQuestionnaireAdmin(data))
      // toggleToast()
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("QuestionnaireAdmins")}
            breadcrumbItem={props.t("QuestionnaireAdmin")}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("QuestionnaireMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {props.t("QuestionnaireAdmin")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="name_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("QuestionnaireName")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("QuestionnaireName")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="name_input"
                          name="name"
                          type="text"
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="sprint_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Code du Run")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="sprint_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sprint || ""}
                          name="sprint"
                          placeholder={props.t("Code du Run")}
                          className="form-control"
                        >
                          <option value="">{props.t("Run")}</option>
                          {sprints &&
                            sprints.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {language === "fr"
                                  ? item.scope_fr
                                  : item.scopre_en}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="organisation_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("short_code_org")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="organisation_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.organisation || ""}
                          name="organisation"
                          placeholder={props.t("short_code_org")}
                          className="form-control"
                        >
                          <option value="">{props.t("short_code_org")}</option>
                          {organisations &&
                            organisations?.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {props.t(item?.short_code_org)}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="menu_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Menu")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="menu_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.menu || ""}
                          name="menu"
                          placeholder={props.t("Menu")}
                          className="form-control"
                        >
                          <option value="">{props.t("Menu")}</option>
                          {menus &&
                            menus?.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {props.t(
                                  language === "fr" ? item?.fr : item?.en
                                )}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {props.t("SubmitQuestionnaire")}
                      </Button>
                    </Row>
                  </Form>
                  <Row>
                    <ol>
                      {questionnaireAdmin?.data?.questions?.map(
                        (item, index) => (
                          <li key={index}>
                            {language === "fr"
                              ? item?.question_name_fr
                              : item?.question_name_en}
                          </li>
                        )
                      )}
                    </ol>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

QuestionnaireAdmin.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(QuestionnaireAdmin))
