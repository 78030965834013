import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";

import userSaga from "./users/saga";
import menuSaga from "./menus/saga";
import colorTypeSaga from "./colorTypes/saga";
import organisationSaga from "./organisations/saga";
import questionSaga from "./questions/saga";
import secteurSaga from "./secteurs/saga";
import themeSaga from "./themes/saga";
import categorieSaga from "./categories/saga";
import answerSaga from "./answers/saga";
import sprintSaga from "./sprints/saga";
import niveauSaga from "./niveaux/saga";
import questionnaireAdminSaga from "./questionnaireAdmin/saga";
import reportSaga from "./reports/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(userSaga),
    fork(menuSaga),
    fork(colorTypeSaga),
    fork(organisationSaga),
    fork(questionSaga),
    fork(secteurSaga),
    fork(themeSaga),
    fork(categorieSaga),
    fork(answerSaga),
    fork(sprintSaga),
    fork(niveauSaga),
    fork(questionnaireAdminSaga),
    fork(reportSaga),
  ])
}
