import React from "react"
import PropTypes from "prop-types"

import { withRouter } from "react-router-dom"
import { Modal, Form, Row, Label, Input, FormFeedback } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { updateSprint } from "store/sprints/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//i18n
import { withTranslation } from "react-i18next"

const SprintUpdate = props => {
  const { modal_standard, tog_standard, setmodal_standard, id_sprint } = props

  const dispatch = useDispatch()

  const { menus } = useSelector(state => ({
    menus: state.menus.menus,
  }))

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { sprints } = useSelector(state => ({
    sprints: state.sprints.sprints,
  }))

  // Utilisez la méthode find pour récupérer l'objet avec l'id correspondant
  const selectedObjectSprint = sprints?.find(
    obj => obj?._id === id_sprint
  )

  // console.log({ selectedObjectSprint })

  const language = localStorage.getItem("I18N_LANGUAGE")

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nbre_participants: selectedObjectSprint?.nbre_participants
        ? selectedObjectSprint?.nbre_participants
        : "",
      scope_fr: selectedObjectSprint?.scope_fr
        ? selectedObjectSprint?.scope_fr
        : "",
      scope_en: selectedObjectSprint?.scope_en
        ? selectedObjectSprint?.scope_en
        : "",
      scope_nl: selectedObjectSprint?.scope_nl
        ? selectedObjectSprint?.scope_nl
        : "",
      scope_sp: selectedObjectSprint?.scope_sp
        ? selectedObjectSprint?.scope_sp
        : "",
      scope_de: selectedObjectSprint?.scope_de
        ? selectedObjectSprint?.scope_sp
        : "",
      scope_it: selectedObjectSprint?.scope_it
        ? selectedObjectSprint?.scope_sp
        : "",
      menu: selectedObjectSprint?.menu ? selectedObjectSprint?.menu?._id : "",
      organisation: selectedObjectSprint?.organisation
        ? selectedObjectSprint?.organisation?._id
        : "",
      s1_start_date: selectedObjectSprint?.s1_start_date
        ? new Date(selectedObjectSprint?.s1_start_date)
            ?.toISOString()
            .split("T")[0]
        : "",
      s1_end_date: selectedObjectSprint?.s1_end_date
        ? new Date(selectedObjectSprint?.s1_end_date)
            ?.toISOString()
            .split("T")[0]
        : "",
    },
    validationSchema: Yup.object({
      nbre_participants: Yup.string().required(
        "Please Enter Your Participant Number"
      ),
      scope_fr: Yup.string().required("Please Enter Your Scope Name"),
      scope_en: Yup.string().required("Please Enter Your Scope Name"),
      scope_nl: Yup.string().required("Please Enter Your Scope Name"),
      scope_sp: Yup.string().required("Please Enter Your Scope Name"),
      scope_de: Yup.string().required("Please Enter Your Scope Name"),
      scope_it: Yup.string().required("Please Enter Your Scope Name"),
      menu: Yup.string().required("Please Choice Your Menu"),
      organisation: Yup.string().required("Please Choice Your Organisation"),
      s1_start_date: Yup.string().required("Please Enter Your Start Date"),
      s1_end_date: Yup.string().required("Please Enter Your Ended Date"),
    }),
    onSubmit: async values => {
      const data = {
        ...values,
        id: id_sprint,
      }
      // console.log({ data })
      dispatch(updateSprint(data))
      await setmodal_standard(false)
      validation.resetForm()
    },
  })

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Mise À Jour
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <Label
                htmlFor="scope_fr_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (Français)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (Français)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_fr_input"
                  name="scope_fr"
                  type="text"
                  value={validation.values.scope_fr || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="scope_en_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (English)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (English)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_en_input"
                  name="scope_en"
                  type="text"
                  value={validation.values.scope_en || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="scope_nl_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (Neerlandais)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (Neerlandais)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_nl_input"
                  name="scope_nl"
                  type="text"
                  value={validation.values.scope_nl || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="scope_sp_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (Espagnol)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (Espagnol)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_sp_input"
                  name="scope_sp"
                  type="text"
                  value={validation.values.scope_sp || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="scope_de_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (Deutsch)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (Deutsch)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_de_input"
                  name="scope_de"
                  type="text"
                  value={validation.values.scope_de || ""}
                  invalid={
                    validation.touched.scope_de && validation.errors.scope_de
                      ? true
                      : false
                  }
                />
                {validation.touched.scope_de && validation.errors.scope_de ? (
                  <FormFeedback type="invalid">
                    {validation.errors.scope_de}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="scope_it_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Scope (Italian)")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Scope (Italian)")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="scope_it_input"
                  name="scope_it"
                  type="text"
                  value={validation.values.scope_it || ""}
                  invalid={
                    validation.touched.scope_it && validation.errors.scope_it
                      ? true
                      : false
                  }
                />
                {validation.touched.scope_it && validation.errors.scope_it ? (
                  <FormFeedback type="invalid">
                    {validation.errors.scope_it}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="nbre_participants_input"
                className="col-md-2 col-form-label"
              >
                {props.t("nbre_participants")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("nbre_participants")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="nbre_participants_input"
                  name="nbre_participants"
                  type="number"
                  value={validation.values.nbre_participants || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="menu_input" className="col-md-2 col-form-label">
                {props.t("menu")}
              </Label>
              <div className="col-md-10">
                <select
                  id="menu_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.menu || ""}
                  name="menu"
                  placeholder={props.t("menu")}
                  className="form-control"
                >
                  <option value="">{props.t("menu")}</option>
                  {menus &&
                    menus?.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {props.t(language === "fr" ? item?.fr : item?.en)}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="organisation_input"
                className="col-md-2 col-form-label"
              >
                {props.t("organisation")}
              </Label>
              <div className="col-md-10">
                <select
                  id="organisation_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.organisation || ""}
                  name="organisation"
                  placeholder={props.t("organisation")}
                  className="form-control"
                >
                  <option value="">{props.t("organisation")}</option>
                  {organisations&&
                    organisations.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {props.t(item?.short_code_org)}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="s1_start_date_input"
                className="col-md-2 col-form-label"
              >
                {props.t("s1_start_date")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="s1_start_date_input"
                  name="s1_start_date"
                  placeholder={props.t("s1_start_date")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="date"
                  value={validation.values.s1_start_date || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="s1_end_date_input"
                className="col-md-2 col-form-label"
              >
                {props.t("s1_end_date")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="s1_end_date_input"
                  name="s1_end_date"
                  placeholder={props.t("s1_end_date")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="date"
                  value={validation.values.s1_end_date || ""}
                />
              </div>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-primary ">
                Mettre à Jour
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

SprintUpdate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SprintUpdate))
