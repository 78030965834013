import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
} from "reactstrap"

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getColorTypesActions } from "store/colorTypes/actions"

//i18n
import { withTranslation } from "react-i18next"

export const ColorTypeList = props => {
  // meta title
  document.title = props.t("ColorTypes")

  const dispatch = useDispatch()

  const { colorTypes } = useSelector(state => ({
    colorTypes: state.colorTypes.colorTypes,
  }))

  const { loading } = useSelector(state => ({
    loading: state.colorTypes.loading,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  function showToast() {
    const title = props.t("ColorTypes");
    const message = props.t("ColorTypeListMessage");
    const positionClass = "toast-top-right";
    const showEasing = "swing";
    const hideEasing = "linear";
    const showMethod = "fadeIn";
    const hideMethod = "fadeOut";
    const showDuration = 300;
    const hideDuration = 1000;
    const timeOut = 5000;
    const extendedTimeOut = 1000;
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
    toastr.success(message, title);
  }

  useEffect(() => {
    if (colorTypes && !colorTypes?.length) {
      dispatch(getColorTypesActions())
      showToast()
    }
  }, [colorTypes?.length])


//   ------------------------- Loader ---------------------------------//
  if (loading && colorTypes?.data.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("ColorTypes")}
            breadcrumbItem={props.t("ColorTypeList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">{props.t("ColorTypeList")}</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>{props.t("A")}</th>
                          <th>{props.t("B")}</th>
                          <th>{props.t("C")}</th>
                          <th>{props.t("D")}</th>
                          <th>{props.t("E")}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {colorTypes?.data &&
                          colorTypes?.data.map((item) => (
                            <tr key={item?._id}>
                              <th scope="row">{props.t( language === 'fr' ? item?.color_type_fr : item?.color_type_en)}</th>
                              <td>{props.t(language === 'fr' ? item?.color_value_0_fr : item?.color_value_0_en)}</td>
                              <td>{props.t(language === 'fr' ? item?.color_value_1_fr : item?.color_value_1_en)}</td>
                              <td>{props.t(language === 'fr' ? item?.color_value_2_fr : item?.color_value_2_en)}</td>
                              <td>{props.t(language === 'fr' ? item?.color_value_3_fr : item?.color_value_3_en)}</td>
                              <td>{props.t(language === 'fr' ? item?.color_value_4_fr : item?.color_value_4_en)}</td>                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ColorTypeList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ColorTypeList))
