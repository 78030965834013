import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
  Form,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

//redux
import { useDispatch, useSelector } from "react-redux"
import { add_NewUser as onAddNewUser } from "store/users/actions"

//import images
import logo from "assets/images/logo.jpg"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

export const UserCreate = props => {
  // meta title
  document.title = props.t("NewUser")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const { niveaux } = useSelector(state => ({
    niveaux: state.niveaux.niveaux,
  }))

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const [organisationSelectionnee, setOrganisationSelectionnee] = useState(null)
  const [niveauxFiltres, setNiveauxFiltres] = useState([])

  const toggleToast = () => {
    setToast(!toast)
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  useEffect(() => {
    // Mettre à jour les niveaux filtrés lorsque l'organisation change
    if (organisationSelectionnee !== null) {
      console.log(
        "🚀 ~ useEffect ~ organisationSelectionnee:",
        organisationSelectionnee
      )
      const niveauxFiltres = niveaux.filter(
        niveau =>
          niveau.organisation &&
          niveau.organisation._id === organisationSelectionnee
      )
      setNiveauxFiltres(niveauxFiltres)
    } else {
      // Si aucune organisation n'est sélectionnée, afficher tous les niveaux
      setNiveauxFiltres(niveaux)
    }
  }, [organisationSelectionnee, niveaux])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
      name_enterprise: "",
      function: "",
      gender: "",
      role: "",
      phone_number: "",
      password: "",
      date_naiss: "",
      niveau: [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().required("Please Enter Your email"),
      function: Yup.string().required("Please Enter Your Function"),
      // phone_number: Yup.string().required("Please Enter Your Phone Number"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(onAddNewUser(values))
      // // toggleToast()
      validation.resetForm()
    },
  })

  const handleOrganisationChange = event => {
    const nouvelleOrganisationSelectionnee = event.target.value
    console.log(
      "this is the selected organisation",
      nouvelleOrganisationSelectionnee
    )
    setOrganisationSelectionnee(nouvelleOrganisationSelectionnee)
    validation.setFieldValue(
      "name_enterprise",
      nouvelleOrganisationSelectionnee
    )
  }

  const handleOptionClick = optionValue => {
    const { niveau } = validation.values
    const selectedIndex = niveau.indexOf(optionValue)

    if (selectedIndex === -1) {
      // L'option n'est pas encore sélectionnée, ajoutons-la.
      validation.setFieldValue("niveau", [...niveau, optionValue])
    } else {
      // L'option est déjà sélectionnée, retirons-la.
      const newSelectedOptions = [...niveau]
      newSelectedOptions.splice(selectedIndex, 1)
      validation.setFieldValue("niveau", newSelectedOptions)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("NewUser")}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className={"text-white bg-primary"}>
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("UserMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {props.t("NewUser")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="title_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Title")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="title_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          name="title"
                          placeholder={props.t("Title")}
                          className="form-control"
                        >
                          <option value="">{props.t("Title")}</option>
                          <option value="Mr">{props.t("Mr")}</option>
                          <option value="Mme">{props.t("Mme")}</option>
                          <option value="Mlle">{props.t("Mlle")}</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="firstname_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("FirstName")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("FirstName")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="firstname_input"
                          name="first_name"
                          type="text"
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                        validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="lastname_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("LastName")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="lastname_input"
                          name="last_name"
                          placeholder={props.t("LastName")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="text"
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                        validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="email_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Email")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Email")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="email"
                          id="email_input"
                          type="email"
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="enterprise_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("short_code_org")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="enterprise_input"
                          onChange={e => {
                            handleOrganisationChange(e)
                            validation.handleChange(e)
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.name_enterprise || ""}
                          name="name_enterprise"
                          placeholder={props.t("short_code_org")}
                          className={`form-control ${
                            validation.touched.name_enterprise &&
                            validation.errors.name_enterprise
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="" disabled>
                            {props.t("short_code_org")}
                          </option>
                          {organisations &&
                            organisations.map(item => (
                              <option key={item._id} value={item._id}>
                                {item.short_code_org}
                              </option>
                            ))}
                        </select>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <Label
                        htmlFor="function_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Function")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Function")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="function_input"
                          name="function"
                          type="text"
                          value={validation.values.function || ""}
                          invalid={
                            validation.touched.function &&
                            validation.errors.function
                              ? true
                              : false
                          }
                        />
                        {validation.touched.function &&
                        validation.errors.function ? (
                          <FormFeedback type="invalid">
                            {validation.errors.function}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="gender_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Gender")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="gender_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gender || ""}
                          name="gender"
                          placeholder={props.t("Gender")}
                          className="form-control"
                        >
                          <option value="">{props.t("Gender")}</option>
                          <option value="M">{props.t("M")}</option>
                          <option value="F">{props.t("F")}</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="role_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Role")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          value={validation.values.role || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="role_input"
                          name="role"
                          placeholder={props.t("Role")}
                          className="form-control"
                        >
                          <option value="">{props.t("Role")}</option>
                          <option value="SUPERADMIN">
                            {props.t("SUPERADMIN")}
                          </option>
                          <option value="ADMIN">{props.t("ADMIN")}</option>
                          <option value="NAUTELAUS">
                            {props.t("NAUTELAUS")}
                          </option>
                          <option value="COMMANDITAIRE">
                            {props.t("COMMANDITAIRE")}
                          </option>
                          <option value="COACH">{props.t("COACH")}</option>
                          <option value="SUPERCOACH">
                            {props.t("SUPERCOACH")}
                          </option>
                          <option value="USER">{props.t("User")}</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="tel_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Telephone")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="tel_input"
                          placeholder={props.t("Telephone")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="phone_number"
                          type="text"
                          value={validation.values.phone_number || ""}
                          invalid={
                            validation.touched.phone_number &&
                            validation.errors.phone_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone_number &&
                        validation.errors.phone_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="date_naiss_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("DateNaiss")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="date_naiss_input"
                          name="date_naiss"
                          placeholder={props.t("DateNaiss")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="date"
                          value={validation.values.date_naiss || ""}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="niveau_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("niveau")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="niveau_input"
                          onChange={e => {
                            handleOptionClick(e.target.value)
                            validation.handleChange(e)
                          }}
                          onBlur={validation.handleBlur}
                          value={validation.values.niveau || ""}
                          name="niveau"
                          multiple={true}
                          placeholder={props.t("niveau")}
                          className={`form-control ${
                            validation.touched.niveau &&
                            validation.errors.niveau
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          {/* <option value="" disabled>{props.t("niveau")}</option> */}
                          {niveauxFiltres?.map(item => (
                            <option key={item?._id} value={item?._id}>
                              {language === "fr"
                                ? item?.type_name_fr
                                : item?.type_name_en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="password_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Password")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="password_input"
                          name="password"
                          placeholder={props.t("Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="password"
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {props.t("SubmitUser")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UserCreate))
