//AUTH
export const POST_LOGIN = "/auth/login"
export const POST_LOGOUT = "/auth/logout"

export const POST_PASSWORD_FORGET = "/auth/modify-password"

//USERS
export const GET_API_USERS = "/user/get_all"
export const ADD_API_USERS = "/auth/super_admin/register"
export const UPDATE_API_USER = "/user"
export const DELETE_API_USER = "/user/delete"

//MENUS
export const GET_API_MENUS = "/menu/getAll_menu"
export const ADD_API_MENU = "/menu/create_menu"
export const UPDATE_API_MENU = "/menu/"
export const DELETE_API_MENU = "menu"

//COLORTYPES
export const GET_API_COLORTYPES = "/color_type/getAll"
export const ADD_API_COLORTYPE = "/color_type/create"

//ORGANISATION
export const GET_API_ORGANISATIONS = "/organisation/getAll"
export const ADD_API_ORGANISATION = "/organisation/create"
export const DELETE_API_ORGANISATION = "/organisation/"
export const UPDATE_API_ORGANISATION = "/organisation/"

//QUESTION
export const GET_API_QUESTIONS = "/question/getAll"
export const ADD_API_QUESTION = "/question/create"
export const GET_API_QUESTION_BY_THEMEID = "/question"

//SECTEUR
export const GET_API_SECTEURS = "/secteur/getAll"
export const ADD_API_SECTEUR = "/secteur/create"
export const UPDATE_API_SECTEUR = "/secteur"
export const DELETE_API_SECTEUR = "/secteur"

//THEME
export const GET_API_THEMES = "/theme/getAll"
export const ADD_API_THEME = "/theme/create"
export const DELETE_API_THEME = "/theme"
export const UPDATE_API_THEME = "/theme"

//CATEGORIE
export const GET_API_CATEGORIES = "/categorie/getAll"
export const ADD_API_CATEGORIE = "/categorie/create"
export const DELETE_API_CATEGORIE = "/categorie"
export const UPDATE_API_CATEGORIE = "/categorie"

//ANSWER
export const GET_API_ANSWERS = "/answer/getAll"
export const GET_API_USER_ANSWERS = "/answer/getByUserAndQuestionnaire"
export const ADD_API_ANSWER = "/answer/create"

//SPRINT
export const GET_API_SPRINTS = "/sprint/getAll"
export const ADD_API_SPRINT = "/sprint/create"
export const UPDATE_API_SPRINT = "/sprint/"
export const DELETE_API_SPRINT = "/sprint/"

//NIVEAU
export const GET_API_NIVEAUX = "/niveau/getAll"
export const ADD_API_NIVEAU = "/niveau/create"
export const UPDATE_API_NIVEAU = "/niveau/"
export const DELETE_API_NIVEAU = "/niveau/"

// QUESTIONNAIRE_ADMIN
export const ADD_API_QUESTIONNAIRE = "/questionnaire/create_questionnaire"
export const GET_API_QUESTIONNAIRE_DETAIL = "/questionnaire/get_questionnaire"

export const GET_API_QUESTIONNAIRE_BY_ID = "/questionnaire"
export const GET_API_QUESTIONNAIRES = "/questionnaire/all"

// REPORT
export const GET_API_BARYCENTRE_USER = "/report/barycentre-user"
export const GET_API_BARYCENTRE_NIVEAU = "/report/barycentre-niveau"
