/* THEMES */
export const GET_THEMES = "GET_THEMES"
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS"
export const GET_THEMES_FAIL = "GET_THEMES_FAIL"

/**
 * add THEME
 */
export const ADD_NEW_THEME = "ADD_NEW_THEME"
export const ADD_THEME_SUCCESS = "ADD_THEME_SUCCESS"
export const ADD_THEME_FAIL = "ADD_THEME_FAIL"

export const UPDATE_THEME = "UPDATE_THEME"
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS"
export const UPDATE_THEME_FAIL = "UPDATE_THEME_FAIL"
export const DELETE_THEME = "DELETE_THEME"
export const DELETE_THEME_SUCCESS = "DELETE_THEME_SUCCESS"
export const DELETE_THEME_FAIL = "DELETE_THEME_FAIL"