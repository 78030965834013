import {
  ADD_COLORTYPE_SUCCESS,
  ADD_COLORTYPE_FAIL,
  GET_COLORTYPES_SUCCESS,
  GET_COLORTYPES_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  colorTypes: [],
  colorType: {},
  loading: false,
  error: {},
}

const ColorTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COLORTYPES_SUCCESS:
      return {
        ...state,
        loading: true,
        colorTypes: action.payload,
      }

    case GET_COLORTYPES_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_COLORTYPE_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        colorTypes: [...state.colorTypes, action.payload],
      }

    case ADD_COLORTYPE_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ColorTypes
