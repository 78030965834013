/* QUESTIONS */
export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS"
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL"

/**
 * add question
 */
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL"

/**
 * Get Question By Theme
 */
export const GET_QUESTION_BY_THEME = "GET_QUESTION_BY_THEME"
export const GET_QUESTION_BY_THEME_SUCCESS = "GET_QUESTION_BY_THEME_SUCCESS"
export const GET_QUESTION_BY_THEME_FAIL = "GET_QUESTION_BY_THEME_FAIL"

/**
 * Update Question
 */
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS"
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL"
