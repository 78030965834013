import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiOffCanvas from "pages/Ui/UiOffCanvas"
import UiUtilitie from "../pages/Ui/UiUtilitie"
import UiPlaceholders from "../pages/Ui/UiPlaceholders"
import UiToasts from "../pages/Ui/UiToast"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Users
import UsersList from "../pages/Users/UsersList/users-list"
import UserCreate from "../pages/Users/UserCreate/user-create"

//Menus
import MenuList from "../pages/Menu/MenuList/menu-list.js"
import MenuCreate from "../pages/Menu/MenuCreate/menu-create.js"

//Orga
import OrgaList from "../pages/Organisations/OrgaList/orga-list"
import OrgaCreate from "../pages/Organisations/OrgaCreate/orga-create"

//ColorType
import ColorTypeList from "../pages/ColorTypes/ColorTypeList/colorTypes-list"
import ColorTypeCreate from "../pages/ColorTypes/ColorTypeCreate/colorType-create"

//Question
import QuestionList from "../pages/Questions/QuestionList/question-list"
import QuestionCreate from "../pages/Questions/QuestionCreate/question-create"

//Secteur
import SecteurList from "../pages/Secteurs/SecteurList/secteur-list"
import SecteurCreate from "../pages/Secteurs/SecteurCreate/secteur-create"

//Thème
import ThemeList from "../pages/Themes/ThemeList/theme-list"
import ThemeCreate from "../pages/Themes/ThemeCreate/theme-create"

//Catégories
import CategorieList from "../pages/Categories/CategorieList/categorie-list"
import CategorieCreate from "../pages/Categories/CategorieCreate/categorie-create"

//Answers
import AnswerList from "../pages/Answers/AnswerList/answer-list"
// import AnswerCreate from "../pages/Answers/AnswerCreate/answer-create";

//Sprints
import SprintList from "../pages/Sprints/SprintList/sprint-list"
import SprintCreate from "../pages/Sprints/SprintCreate/sprint-create"

//Niveaux
import NiveauList from "../pages/Niveaux/NiveauList/niveau-list"
import NiveauCreate from "../pages/Niveaux/NiveauCreate/niveau-create"

// Questionnaire
import Questionnaire from "../pages/Questionnaire/index"
import QuestionnaireAdmin from "../pages/Questionnaire/questionnaire-admin"

//Reports
import Average from "../pages/Reports/Average"
import BarycentreNiveau from "../pages/Reports/BarycentreNiveau"
import ReportFile from "../pages/Reports/ReportFile"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-offcanvas", component: UiOffCanvas },
  { path: "/ui-utilities", component: UiUtilitie },
  { path: "/ui-placeholders", component: UiPlaceholders },
  { path: "/ui-toasts", component: UiToasts },

  //Users
  { path: "/user-list", component: UsersList },
  { path: "/new-user", component: UserCreate },

  //Menus
  { path: "/menu-list", component: MenuList },
  { path: "/new-menu", component: MenuCreate },

  //Orga
  { path: "/orga-list", component: OrgaList },
  { path: "/new-orga", component: OrgaCreate },

  //ColorTypes
  { path: "/colorType-list", component: ColorTypeList },
  { path: "/new-colorType", component: ColorTypeCreate },

  //Questions
  { path: "/question-list", component: QuestionList },
  { path: "/new-question", component: QuestionCreate },

  //secteurs
  { path: "/secteur-list", component: SecteurList },
  { path: "/new-secteur", component: SecteurCreate },

  //thèmes
  { path: "/theme-list", component: ThemeList },
  { path: "/new-theme", component: ThemeCreate },

  //catégories
  { path: "/category-list", component: CategorieList },
  { path: "/new-category", component: CategorieCreate },

  //answers
  { path: "/answer-list", component: AnswerList },

  //sprints
  { path: "/sprint-list", component: SprintList },
  { path: "/new-sprint", component: SprintCreate },

  //niveaux
  { path: "/niveau-list", component: NiveauList },
  { path: "/new-niveau", component: NiveauCreate },

  //Questionnaire
  { path: "/questionnaire", component: Questionnaire },
  { path: "/questionnaire-admin", component: QuestionnaireAdmin },

  //Reports
  { path: "/report-average", component: Average },
  { path: "/barycentre-niveau", component: BarycentreNiveau },
  { path: "/report-file", component: ReportFile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/modify-password", component: LockScreen },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-two-step-verification", component: TwostepVerification },
]

export { authProtectedRoutes, publicRoutes }
