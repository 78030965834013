import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import DeleteComponent from "components/Common/DeleteComponent"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Modal,
  Table,
} from "reactstrap"

import UserUpdate from "./user-update.js"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { delete_User } from "store/users/actions"

import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import Pagination from "components/Common/ItemsPagination.js"

const UsersList = props => {
  // meta title
  document.title = props.t("Users")

  const dispatch = useDispatch()

  const { users_users } = useSelector(state => ({
    users_users: state.users.users_users,
  }))

  const { loading } = useSelector(state => ({
    loading: state.users.loading,
  }))

  const [modal_standard, setmodal_standard] = useState(false)
  const [user_selected, setuser_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [page, setPage] = useState(1)
  const ITEMS_PER_PAGE = 8
  const [usersList, setUsersList] = useState(
    users_users.slice(0, ITEMS_PER_PAGE)
  )

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  const handleDelete = (id, name) => {
    // console.log({ name })
    dispatch(delete_User(id))
    // dispatch(get_UsersActions())
  }

  const handlePaginate = (page, offset) => {
    console.log(`User requested page number ${page}, which is offset ${offset}`)
    if (!loading && users_users?.length > 0) {
      setPage(page + 1)
      setUsersList(users_users.slice(offset, offset + ITEMS_PER_PAGE))
    }
  }

  useEffect(() => {
    if (users_users?.length > 0) {
      setUsersList(users_users.slice(0, ITEMS_PER_PAGE))
    }
  }, [users_users])

  // ------------------------- Loader ---------------------------------//
  if (loading && (users_users?.length === 0 || usersList?.length === 0)) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("User")}
          />
          <p>
            {users_users?.length} - {usersList?.length}
          </p>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">{props.t("UserList")}</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                        <tr>
                          <th scope="row">{props.t("code")}</th>
                          <th>{props.t("FirstName")}</th>
                          <th>{props.t("LastName")}</th>
                          <th>{props.t("Email")}</th>
                          <th>{props.t("short_code_org")}</th>
                          <th>{props.t("Role")}</th>
                          <th>{props.t("niveau")}</th>
                          <th className="d-flex justify-content-center">
                            {props.t("actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading && users_users?.length === 0 && (
                          <tr>
                            <td colSpan="8" className="text-center">
                              {props.t("NoData")}
                            </td>
                          </tr>
                        )}
                        {usersList?.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{props.t(item?.code)}</th>
                            <td>{item?.first_name}</td>
                            <td>{item?.last_name}</td>
                            <td>{item?.email}</td>
                            <td>
                              {props.t(item?.name_enterprise?.short_code_org)}
                            </td>
                            <td>{props.t(item?.role)}</td>
                            <td>
                              {language === "fr"
                                ? item?.niveau?.map(
                                    item => item?.type_name_fr + " "
                                  )
                                : item?.niveau?.map(
                                    item => item?.type_name_en + " "
                                  )}
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center justify-content-between">
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                      setname_selected(item?.first_name)
                                      setuser_selected(item?._id)
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>{" "}
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setname_selected(item?.first_name)
                                      setuser_selected(item?._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                    {""}
                                  </button>
                                </div>
                              </div>

                              <UserUpdate
                                modal_standard={modal_standard}
                                id_user={user_selected}
                                setuser_selected={setuser_selected}
                                setmodal_standard={setmodal_standard}
                                tog_standard={tog_standard}
                              />

                              <DeleteComponent
                                tog_center={tog_center}
                                name={name_selected}
                                id={user_selected}
                                setmodal_center={setmodal_center}
                                modal_center={modal_center}
                                handleDelete={handleDelete}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      items={users_users}
                      itemsPerPage={ITEMS_PER_PAGE}
                      handlePaginate={(page, offset) =>
                        handlePaginate(page, offset)
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UsersList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UsersList))
