/* COLORTYPES */
export const GET_COLORTYPES = "GET_COLORTYPES"
export const GET_COLORTYPES_SUCCESS = "GET_COLORTYPES_SUCCESS"
export const GET_COLORTYPES_FAIL = "GET_COLORTYPES_FAIL"

/**
 * add ColorType
 */
export const ADD_NEW_COLORTYPE = "ADD_NEW_COLORTYPE"
export const ADD_COLORTYPE_SUCCESS = "ADD_COLORTYPE_SUCCESS"
export const ADD_COLORTYPE_FAIL = "ADD_COLORTYPE_FAIL"