import {
  ADD_CATEGORIE_SUCCESS,
  ADD_CATEGORIE_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_FAIL,
} from "./actionTypes"
import {
  DELETE_THEME_FAIL,
  DELETE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
  UPDATE_THEME_SUCCESS,
} from "../themes/actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  categories: [],
  categorie: {},
  loading: false,
  error: {},
}

const Categorie = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_CATEGORIE_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        categories: [...state.categories, action.payload],
      }

    case ADD_CATEGORIE_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_CATEGORIE_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        categories: state.categories.map(categorie =>
          categorie._id === action.payload.id
            ? { categorie, ...action.payload }
            : categorie
        ),
      }

    case UPDATE_CATEGORIE_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CATEGORIE_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      return {
        ...state,
        categories: state.categories?.filter(
          cat => cat?._id !== action.payload
        ),
      }

    case DELETE_THEME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Categorie
