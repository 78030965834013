import { call, put, takeEvery } from "redux-saga/effects"

import { GET_COLORTYPES, ADD_NEW_COLORTYPE } from "./actionTypes"
import { getColorTypeSuccess, getColorTypeFail, addColorTypeSuccess, addColorTypeFail } from "./actions"

import { get_ColorTypes_Api, add_NewColorType_Api } from "helpers/backend"

function* fetchColorTypes() {
  try {
    const response = yield call(get_ColorTypes_Api)
    yield put(getColorTypeSuccess(response))
  } catch (error) {
    yield put(getColorTypeFail(error))
  }
}

function* onAddNewColorTypeApi({ payload: colorType }) {
  try {
    const response = yield call(add_NewColorType_Api, colorType)

    yield put(addColorTypeSuccess(response))
  } catch (error) {
    yield put(addColorTypeFail(error))
  }
}

function* colorTypeSaga() {
  yield takeEvery(GET_COLORTYPES, fetchColorTypes)
  yield takeEvery(ADD_NEW_COLORTYPE, onAddNewColorTypeApi)
}

export default colorTypeSaga
