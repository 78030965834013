import React, { useState } from "react"
import { Range, getTrackBackground } from "react-range"

const STEP = 1
const MIN = -1
const MAX = 10

const InputRange = ({ onValueChange, valueInputRange, customColor }) => {
  // Utilisation du hook useState pour initialiser l'état
  const [values, setValues] = useState([valueInputRange])

  // // console.log("this is the value",valueInputRange)

  const createScale = () => {
    let scale = []

    for (let i = MIN; i <= MAX; i = i + STEP) {
      scale.push(
        <span key={i} className="scale_item">
          {i}
        </span>
      )
    }

    return scale
  }
  return (
    <div className="cardinput">
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={values => {
          setValues(values)
          // Send the selected value to the parent component
          const [value] = values
          onValueChange(value)
        }}
        renderTrack={({ props, children }) => {
          return (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "10px",
                  width: "100%",
                  borderRadius: "50px",
                  background: getTrackBackground({
                    values: values,
                    colors: customColor
                      ? customColor
                      : ["rgb(230, 192, 179)", "rgb(102, 99, 106)"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                  boxShadow: "inset -1px -3px 0px 0px rgb(102, 99, 106)",
                }}
              >
                {children}
                <div className="scale">{createScale()}</div>
              </div>
            </div>
          )
        }}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "30px",
              width: "30px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#777" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </div>
  )
}

export default InputRange
