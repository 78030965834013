import PropTypes from "prop-types"
import React, { useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteComponent from "components/Common/DeleteComponent"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table,
} from "reactstrap"

import "toastr/build/toastr.min.css"

import NiveauUpdate from "./niveau-update.js"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { deleteNiveau } from "store/niveaux/actions"

//i18n
import { withTranslation } from "react-i18next"

export const NiveauList = props => {
  // meta title
  document.title = props.t("Niveaux")

  const dispatch = useDispatch()

  const { niveaux } = useSelector(state => ({
    niveaux: state.niveaux.niveaux,
  }))

  const { loading } = useSelector(state => ({
    loading: state.niveaux.loading,
  }))

  const [modal_standard, setmodal_standard] = useState(false)
  const [niveau_selected, setniveau_selected] = useState("")
  const [name_selected, setname_selected] = useState("")
  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const language = localStorage.getItem("I18N_LANGUAGE")

  const handleDelete = (id, name) => {
    // console.log({ name })
    dispatch(deleteNiveau(id))
  }

  // ------------------------- Loader ---------------------------------//
  if (loading && niveaux.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Niveaux")}
            breadcrumbItem={props.t("NiveauList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("NiveauList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>{props.t("Code")}</th>
                          <th>{props.t("LevelType")}</th>
                          <th>{props.t("LevelNumber")}</th>
                          <th>{props.t("organisation")}</th>
                          <th>{props.t("index_precedent")}</th>
                          <th className="d-flex justify-content-center">
                            {props.t("Actions")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          niveaux?.map((niveau, index) => (
                            <tr key={index}>
                              <th scope="row">{niveau.code}</th>
                              <td>
                                {language === "fr"
                                  ? niveau?.type_name_fr
                                  : niveau?.type_name_en}
                              </td>
                              <td>{niveau?.number_niveau}</td>
                              <td>{niveau?.organisation?.short_code_org}</td>
                              <td>{niveau?.index_precedent}</td>
                              <td>
                                <div className="d-flex justify-content-around">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                      setniveau_selected(niveau?._id)
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setname_selected(
                                        language === "fr"
                                          ? niveau?.type_name_fr
                                          : niveau?.type_name_en
                                      )
                                      setniveau_selected(niveau?._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>

                                <NiveauUpdate
                                  modal_standard={modal_standard}
                                  id_niveau={niveau_selected}
                                  setniveau_selected={setniveau_selected}
                                  setmodal_standard={setmodal_standard}
                                  tog_standard={tog_standard}
                                />

                                <DeleteComponent
                                  tog_center={tog_center}
                                  name={name_selected}
                                  id={niveau_selected}
                                  setmodal_center={setmodal_center}
                                  modal_center={modal_center}
                                  handleDelete={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NiveauList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(NiveauList))
