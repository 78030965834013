import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SPRINTS,
  ADD_NEW_SPRINT,
  UPDATE_SPRINT,
  DELETE_SPRINT,
} from "./actionTypes"
import {
  getSprintSuccess,
  getSprintFail,
  addSprintSuccess,
  addSprintFail,
  deleteSprintSuccess,
  deleteSprintFail,
  updateSprintSuccess,
  updateSprintFail,
} from "./actions"

import {
  get_Sprints_Api,
  add_NewSprint_Api,
  update_Sprint_Api,
  delete_Sprint_Api,
} from "helpers/backend"

function* fetchSprints() {
  try {
    const response = yield call(get_Sprints_Api)
    yield put(getSprintSuccess(response.data))
  } catch (error) {
    yield put(getSprintFail(error))
  }
}

function* onAddNewSprintApi({ payload: sprint }) {
  try {
    const response = yield call(add_NewSprint_Api, sprint)

    yield put(addSprintSuccess(response))
  } catch (error) {
    yield put(addSprintFail(error))
  }
}

function* onUpdateSprint({ payload: sprint }) {
  try {
    const response = yield call(update_Sprint_Api, sprint)
    yield put(updateSprintSuccess(response)) 
  } catch (error) {
    yield put(updateSprintFail(error))
  }
}

function* onDeleteSprint({ payload: id }) {
  try {
    const response = yield call(delete_Sprint_Api, id)
    yield put(deleteSprintSuccess(id))
  } catch (error) {
    yield put(deleteSprintFail(error))
  }
}

function* sprintSaga() {
  yield takeEvery(GET_SPRINTS, fetchSprints)
  yield takeEvery(ADD_NEW_SPRINT, onAddNewSprintApi)
  yield takeEvery(UPDATE_SPRINT, onUpdateSprint)
  yield takeEvery(DELETE_SPRINT, onDeleteSprint)
}

export default sprintSaga
