// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.react-paginate {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem; }

ul.react-paginate li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer; }

ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: transparent; }

ul.react-paginate li.selected a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px; }

ul.react-paginate li.disabled a {
  color: grey; }

ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/react-paginate.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;EACrB,eAAe,EAAA;;AAGjB;EACE,kBAAkB;EAClB,oBAAoB;EACpB,sBAAsB;EACtB,eAAe,EAAA;;AAEjB;;;EAGE,yBAAyB,EAAA;;AAE3B;EACE,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;EACZ,eAAe,EAAA;;AAEjB;EACE,WAAW,EAAA;;AAEb;;EAEE,eAAe,EAAA","sourcesContent":["ul.react-paginate {\n  margin-bottom: 2rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  list-style-type: none;\n  padding: 0 5rem;\n}\n\nul.react-paginate li a {\n  border-radius: 7px;\n  padding: 0.1rem 1rem;\n  border: gray 1px solid;\n  cursor: pointer;\n}\nul.react-paginate li.previous a,\nul.react-paginate li.next a,\nul.react-paginate li.break a {\n  border-color: transparent;\n}\nul.react-paginate li.selected a {\n  background-color: #0366d6;\n  border-color: transparent;\n  color: white;\n  min-width: 32px;\n}\nul.react-paginate li.disabled a {\n  color: grey;\n}\nul.react-paginate li.disable,\nul.react-paginate li.disabled a {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
