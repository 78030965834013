import {
  ADD_SPRINT_SUCCESS,
  ADD_SPRINT_FAIL,
  GET_SPRINTS_SUCCESS,
  GET_SPRINTS_FAIL,
  UPDATE_SPRINT_SUCCESS,
  UPDATE_SPRINT_FAIL,
  DELETE_SPRINT_SUCCESS,
  DELETE_SPRINT_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  sprints: [],
  sprint: {},
  loading: false,
  error: {},
}

const Sprint = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPRINTS_SUCCESS:
      return {
        ...state,
        loading: true,
        sprints: action.payload,
      }

    case GET_SPRINTS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_SPRINT_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        sprints: [...state.sprints, action.payload],
      }

    case ADD_SPRINT_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_SPRINT_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        sprints: state.sprints?.map(sprint =>
          sprint?._id === action.payload.id
            ? { sprint, ...action.payload }
            : sprint
        ),
      }

    case UPDATE_SPRINT_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SPRINT_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      return {
        ...state,
        sprints: state.sprints?.filter(
          sprint =>
            sprint?._id !== action.payload
        ),
      }

    case DELETE_SPRINT_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Sprint