import React, { useState } from "react"
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"

import "../../assets/scss/react-paginate.scss"
// You can style your pagination component
// thanks to styled-components.
// Use inner class names to style the controls.
const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`

function Pagination(props) {
  const [itemOffset, setItemOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const endOffset = itemOffset + props.itemsPerPage
  console.log(`Loading items from ${itemOffset} to ${endOffset}`)
  const pageCount = Math.ceil(props?.items?.length / props.itemsPerPage)
  console.log("🚀 ~ Pagination ~ pageCount:", pageCount)
  const handlePageClick = event => {
    const newOffset = (event.selected * props.itemsPerPage) % props.items.length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setCurrentPage(event.selected)
    setItemOffset(newOffset)
    props.handlePaginate(event.selected, newOffset)
  }

  return (
    <>
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      /> */}
      <nav aria-label="Page navigation comments" className="mt-4">
        <ReactPaginate
          previousLabel={props.t("Pagination Previous")}
          nextLabel={props.t("Pagination Next")}
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          pageRangeDisplayed={4}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          // eslint-disable-next-line no-unused-vars
          // hrefBuilder={(page, pageCount, selected) =>
          //   page >= 1 && page <= pageCount ? `/page/${page}` : "#"
          // }
          // hrefAllControls
          // forcePage={currentPage}
          onClick={clickEvent => {
            console.log("onClick", clickEvent)
            // Return false to prevent standard page change,
            // return false; // --> Will do nothing.
            // return a number to choose the next page,
            // return 4; --> Will go to page 5 (index 4)
            // return nothing (undefined) to let standard behavior take place.
          }}
        />
      </nav>
    </>
  )
}

Pagination.prototype = {
  items: PropTypes.any,
  itemsPerPage: PropTypes.any,
  handlePaginate: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Pagination))
