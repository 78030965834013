import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import { addNewSecteur as onAddNewSecteur, updateSecteur } from "store/secteurs/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import logo from "assets/images/logo.jpg"

//i18n
import { withTranslation } from "react-i18next"
import languages from "../../../common/languages"

 const SecteurCreate = props => {

  const {initialState,onClose} = props
   // console.log("this is the secteur state",initialState)
  // meta title
  document.title = props.t("Secteur")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const toggleToast = () => {
    setToast(!toast)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code_secteur:initialState?.code_secteur || "",
      name_secteur_fr:initialState?.name_secteur_fr || "",
      name_secteur_en:initialState?.name_secteur_en || "",
    },
    validationSchema: Yup.object({
      code_secteur: Yup.string().required("Please Enter Your Code"),
      name_secteur_fr: Yup.string().required(
        "Please Enter Your French Secteur Name"
      ),
      name_secteur_en: Yup.string().required(
        "Please Enter Your English Secteur Name"
      ),
    }),
    onSubmit: values => {
      if(initialState){
        onClose()
        return dispatch(updateSecteur({...values, ...(initialState ? {id:initialState._id}:{})}))

      }
      // save new menu
      dispatch(onAddNewSecteur(values))
      // toggleToast()
      validation.resetForm()
    },
  })
  const language = localStorage.getItem("I18N_LANGUAGE")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {initialState ? null :  <Breadcrumbs
            title={props.t("Secteur")}
            breadcrumbItem={props.t("NewSecteur")}
          />}
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("SecteurMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {initialState ? 'Mise à jour du secteur ' + ' ' + initialState[`name_secteur_${language}`] : props.t("NewSecteur")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="code_secteur_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("code_secteur")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("code_secteur")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="code_secteur_input"
                          name="code_secteur"
                          type="text"
                          value={validation.values.code_secteur || ""}
                          invalid={
                            validation.touched.code_secteur &&
                            validation.errors.code_secteur
                              ? true
                              : false
                          }
                        />
                        {validation.touched.code_secteur &&
                        validation.errors.code_secteur ? (
                          <FormFeedback type="invalid">
                            {validation.errors.code_secteur}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="name_secteur_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Secteur (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Secteur (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="name_secteur_fr_input"
                          name="name_secteur_fr"
                          type="text"
                          value={validation.values.name_secteur_fr || ""}
                          invalid={
                            validation.touched.name_secteur_fr &&
                            validation.errors.name_secteur_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name_secteur_fr &&
                        validation.errors.name_secteur_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name_secteur_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="name_secteur_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Sector (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Sector (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="name_secteur_en_input"
                          name="name_secteur_en"
                          type="text"
                          value={validation.values.name_secteur_en || ""}
                          invalid={
                            validation.touched.name_secteur_en &&
                            validation.errors.name_secteur_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name_secteur_en &&
                        validation.errors.name_secteur_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name_secteur_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {initialState ? 'Mise à jour' : props.t("SubmitSecteur")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SecteurCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SecteurCreate))
