import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import logo from "assets/images/logo.jpg"

//redux
import { useDispatch, useSelector } from "react-redux"
import { getCategoriesActions } from "store/categories/actions"
import { addNewCategorie as onAddNewCategorie } from "store/categories/actions"
import { addNewTheme, updateTheme } from "../../../store/themes/actions"
import classNames from "classnames"

 const ThemeCreate = props => {
  const {initialState,onClose} = props
  // meta title
  document.title = props.t("NewTheme")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const { categories } = useSelector(state => ({
    categories: state.categories.categories,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  const toggleToast = () => {
    setToast(!toast)
  }
   const [categorieName,setCategorieName] = useState(initialState?.categorie || undefined)
   const [themeNameFr, setThemeNameFr] = useState(initialState && initialState?.theme_name_fr ||'')
   const [themeNameEn, setThemeNameEn] = useState(initialState && initialState?.theme_name_en || '')
   const [descriptionFr, setDescriptionFr] = useState(initialState && initialState?.description_fr || '')
   const [descriptionEn, setdescriptionEn] = useState(initialState && initialState?.description_en || '')
   const [mainQuestionEn, setMainQuestionEn] = useState(initialState && initialState?.main_question_fr || '')
   const [mainQuestionFr, setMainQuestionFr] = useState(initialState && initialState?.main_question_en || '')
   const [themeName, setThemeName] = useState(initialState && initialState?.categorie ||  '')


  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      theme_name_fr: themeNameFr,
      theme_name_en: themeNameEn,
      description_fr: descriptionFr,
      description_en:  descriptionEn,
      main_question_fr:  mainQuestionFr,
      main_question_en:  mainQuestionEn,
      categorie: categorieName
    },
    validationSchema: Yup.object({
      theme_name_fr: Yup.string().required("Please Enter Your Theme Name French"),
      theme_name_en: Yup.string().required("Please Enter Your Theme Name English"),
      description_fr: Yup.string().required("Please Enter Your Description French"),
      description_en: Yup.string().required("Please Enter Your Description English"),
      main_question_fr: Yup.string().required("Please Enter Your Question French"),
      main_question_en: Yup.string().required("Please Enter Your Question English"),
      // categorie: Yup.string().required("Please Enter Your Category"),
    }),
    onSubmit: values => {
      // save new menu
      if(initialState){
        onClose()
        return dispatch(updateTheme({id:initialState._id,...values}))
      }
      dispatch(addNewTheme(values))
      // toggleToast()
      validation.resetForm()
    },
  })

  useEffect(() => {
    if (categories && !categories?.length) {
      dispatch(getCategoriesActions())
    }
  }, [categories?.length])

// console.log("this is the categorie name",(categorieName && categorieName[`cat_name_${language}`] ) ?? '')
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {initialState ? null : <Breadcrumbs
            title={props.t("Themes")}
            breadcrumbItem={props.t("NewTheme")}
          />}
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("ThemeMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {initialState ? 'Mettre à jour le theme ' + ' '+ initialState[`theme_name_${language}`] :  props.t("NewTheme")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="theme_name_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Thème (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Thème (Français)")}
                          onChange={(event) => setThemeNameFr(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="theme_name_fr"
                          name="theme_name_fr"
                          type="text"
                          value={themeNameFr}
                          invalid={
                            validation.touched.theme_name_fr &&
                            validation.errors.theme_name_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.theme_name_fr &&
                        validation.errors.theme_name_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.theme_name_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="theme_name_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Theme (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Theme (English)")}
                          onChange={(event)=> setThemeNameEn(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="theme_name_en"
                          name="theme_name_en"
                          type="text"
                          value={themeNameEn || ""}
                          invalid={
                            validation.touched.theme_name_en &&
                            validation.errors.theme_name_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.theme_name_en &&
                        validation.errors.theme_name_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.theme_name_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="description_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Description (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Description (Français)")}
                          onChange={(event)=>setDescriptionFr(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="description_fr"
                          name="description_fr"
                          type="text"
                          value={descriptionFr || ""}
                          invalid={
                            validation.touched.description_fr &&
                            validation.errors.description_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description_fr &&
                        validation.errors.description_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="description_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Description (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Description (English)")}
                          onChange={(event)=>setdescriptionEn(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="description_en"
                          name="description_en"
                          type="text"
                          value={descriptionEn}
                          invalid={
                            validation.touched.description_en &&
                            validation.errors.description_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description_en &&
                        validation.errors.description_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="main_question_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Question (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Question (Français)")}
                          onChange={(event)=>setMainQuestionFr(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="main_question_fr"
                          name="main_question_fr"
                          type="text"
                          value={mainQuestionFr}
                          invalid={
                            validation.touched.main_question_fr &&
                            validation.errors.main_question_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.main_question_fr &&
                        validation.errors.main_question_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.main_question_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="main_question_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Question (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Question (English)")}
                          onChange={(event)=>setMainQuestionEn(event.target.value)}
                          onBlur={validation.handleBlur}
                          id="main_question_en"
                          name="main_question_en"
                          type="text"
                          value={mainQuestionEn}
                          invalid={
                            validation.touched.main_question_en &&
                            validation.errors.main_question_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.main_question_en &&
                        validation.errors.main_question_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.main_question_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="categorie_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Categories")}
                      </Label>
                      <div  className="col-md-10 nav nav-pills gap-1 my-2 d-flex flex-wrap border border-2 overflow-auto"
                           style={{ maxHeight: "500px" }}>
                        {/*<select*/}
                        {/*  id="categorie_input"*/}
                        {/*  onChange={(e)=>{*/}
                        {/*    setCategorieName(categories.find((item)=>item._id === e.target.value))*/}
                        {/*  }}*/}
                        {/*  onBlur={validation.handleBlur}*/}
                        {/*  value={(categorieName && categorieName[`cat_name_${language}`] ) ?? ''}*/}
                        {/*  name="categorie"*/}
                        {/*  placeholder={props.t("Categories")}*/}
                        {/*  className="form-control"*/}
                        {/*>*/}
                        {/*  <option value=''>*/}
                        {/*    {props.t("Categories")}*/}
                        {/*  </option>*/}
                        {/*  {*/}
                        {/*    categories?.map(item => (*/}
                        {/*      <option key={item?._id} value={item?._id}>*/}
                        {/*        {props.t(language === 'fr' ? item?.cat_name_fr : item?.cat_name_en)}*/}
                        {/*      </option>*/}
                        {/*  ))}*/}
                        {/*</select>*/}
                        {
                          (categories?.map((item,index) => (
                            <div className={"nav-item overflow-hidden, "} style={{
                              cursor: "pointer",
                              maxWidth: "250px",
                              width: "250px",
                              border: "solid  1px",
                              display:'flex',
                              alignItems:'center',
                              justifyContent:'center',
                              textOverflow: "ellipsis"
                            }} key={index}
                                 onClick={() => setCategorieName(item)}>
                              <span style={{
                                maxWidth: "250px",
                                textAlign:'center',
                                width: "250px",
                                textOverflow: "ellipsis"
                              }} className={classNames(
                                {
                                  active: item?._id === categorieName?._id
                                    ? "active"
                                    : ""
                                },
                                "nav-link text-nowrap overflow-hidden"
                              )} >
                            {item[`cat_name_${language}`]}

                            </span>

                            </div>
                          )))
                        }

                        {validation.touched.categorieName &&
                        validation.errors.categorieName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.categorieName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        { initialState ? 'Mettre à jour' :  props.t("SubmitTheme")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ThemeCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ThemeCreate))
