import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_QUESTIONS,
  ADD_NEW_QUESTION,
  GET_QUESTION_BY_THEME,
  UPDATE_QUESTION,
} from "./actionTypes"
import {
  getQuestionSuccess,
  getQuestionFail,
  addQuestionSuccess,
  addQuestionFail,
  getQuestionByThemeSuccess,
  getQuestionByThemeFail,
  updateQuestionSuccess,
  updateQuestionFail,
} from "./actions"

import {
  get_Questions_Api,
  add_NewQuestion_Api,
  get_Question_By_ThemeID_APi,
  // update_Question_Api,
} from "helpers/backend"

function* fetchQuestions() {
  try {
    const response = yield call(get_Questions_Api)
    yield put(getQuestionSuccess(response.data))
  } catch (error) {
    yield put(getQuestionFail(error))
  }
}

function* fetchQuestionByTheme({ themeID }) {
  try {
    const response = yield call(get_Question_By_ThemeID_APi, themeID)
    yield put(getQuestionByThemeSuccess(themeID, response?.data))
  } catch (error) {
    yield put(getQuestionByThemeFail(error))
  }
}

function* onAddNewQuestionApi({ payload: question }) {
  try {
    const response = yield call(add_NewQuestion_Api, question)

    yield put(addQuestionSuccess(response))
  } catch (error) {
    yield put(addQuestionFail(error))
  }
}

function* onUpdateQuestionApi({ payload: question }) {
  try {
    const response = yield call(update_Question_Api, question)

    yield put(updateQuestionSuccess(response))
  } catch (error) {
    yield put(updateQuestionFail(error))
  }
}

function* questionSaga() {
  yield takeEvery(GET_QUESTIONS, fetchQuestions)
  yield takeEvery(GET_QUESTION_BY_THEME, fetchQuestionByTheme)
  yield takeEvery(ADD_NEW_QUESTION, onAddNewQuestionApi)
  yield takeEvery(UPDATE_QUESTION, onUpdateQuestionApi)
}

export default questionSaga
