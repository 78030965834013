import { call, put, takeEvery } from "redux-saga/effects"

import { GET_BARYCENTRE_USER, GET_BARYCENTRE_NIVEAU} from "./actionTypes"

import { getAverageSuccess, getAverageFail, getBarycentreByNiveauSuccess, getBarycentreByNiveauFail } from "./actions"

import { get_Barycentre_User_Api, get_Barycentre_Niveau_Api } from "helpers/backend"

function* fetchAverageById({ id }) {
  try {
    const response = yield call(get_Barycentre_User_Api, id)
    yield put(getAverageSuccess(response))
  } catch (error) {
    yield put(getAverageFail(error))
  }
}

function* fetchBarycentreByNiveau({ barycentre_niveau }) {
  try {
    const response = yield call(get_Barycentre_Niveau_Api, barycentre_niveau)
    yield put(getBarycentreByNiveauSuccess(response))
  } catch (error) {
    yield put(getBarycentreByNiveauFail(error))
  }
}

function* reportSaga() {
  yield takeEvery(GET_BARYCENTRE_USER, fetchAverageById)
  yield takeEvery(GET_BARYCENTRE_NIVEAU, fetchBarycentreByNiveau)
}

export default reportSaga
