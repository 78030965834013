import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MENUS, ADD_NEW_MENU, UPDATE_MENU, DELETE_MENU, ADD_MENU_SUCCESS, DELETE_MENU_SUCCESS, UPDATE_MENU_SUCCESS } from "./actionTypes"
import {
  getMenuSuccess,
  getMenuFail,
  addMenuSuccess,
  addMenuFail,
  updateMenuSuccess,
  updateMenuFail,
  deleteMenuSuccess,
  deleteMenuFail,
} from "./actions"

import { get_Menus_Api, add_NewMenu_Api, delete_Menu_Api, update_Menu_Api } from "helpers/backend"

function* fetchMenus() {
  try {
    const response = yield call(get_Menus_Api)
    yield put(getMenuSuccess(response.data))
  } catch (error) {
    yield put(getMenuFail(error))
  }
}

function* onAddNewMenuApi({ payload: menu }) {
  try {
    const response = yield call(add_NewMenu_Api, menu)

    yield put(addMenuSuccess(response))

    yield call(fetchMenus)
  } catch (error) {
    yield put(addMenuFail(error))
  }
}

function* onUpdateMenu({payload: menu}) {
  try {
    const response = yield call(update_Menu_Api, menu)
    yield put(updateMenuSuccess(menu))

    yield call(fetchMenus)
  } catch (error) {
    yield put(updateMenuFail(error))
  }
}

function* onDeleteMenu({ payload: id }) {
  try {
    const response = yield call(delete_Menu_Api, id)
    yield put(deleteMenuSuccess(id))

    yield call(fetchMenus)
  } catch (error) {
    yield put(deleteMenuFail(error))
  }
}

function* menuSaga() {
  yield takeEvery(GET_MENUS, fetchMenus)
  yield takeEvery(ADD_NEW_MENU, onAddNewMenuApi)
  yield takeEvery(UPDATE_MENU, onUpdateMenu)
  yield takeEvery(DELETE_MENU, onDeleteMenu)

  // refetch menus
  yield takeEvery([ADD_MENU_SUCCESS, DELETE_MENU_SUCCESS, UPDATE_MENU_SUCCESS], fetchMenus)
}

export default menuSaga
