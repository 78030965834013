import {
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  answers: [],
  answer: {},
  loading: false,
  error: {},
}

const Answer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: true,
        answers: action.payload,
      }

    case GET_ANSWERS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_ANSWER_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        answers: [...state.answers, action.payload],
      }

    case ADD_ANSWER_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Answer
