import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import {
  Modal,
  Button,
  Label,
  FormFeedback,
  Input,
  Form,
  Row,
  Tooltip,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getNiveauxActions } from "store/niveaux/actions"
import { update_User } from "store/users/actions"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { FaFax } from "react-icons/fa"
import chroma from "chroma-js"
import Select from "react-select"

const UserUpdate = props => {
  const { modal_standard, tog_standard, setmodal_standard, id_user } = props

  const dispatch = useDispatch()

  const { users_users } = useSelector(state => ({
    users_users: state.users.users_users,
  }))

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { niveaux } = useSelector(state => ({
    niveaux: state.niveaux.niveaux,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  useEffect(() => {
    if (niveaux && !niveaux?.length) {
      dispatch(getNiveauxActions())
    }
  }, [niveaux?.length])

  // Utilisez la méthode find pour récupérer l'objet avec l'id correspondant
  const selectedObject = users_users?.find(obj => obj?._id === id_user)

  // // console.log({ selectedObject })

  const handleOptionClick = optionValue => {
    console.log("🚀 ~ handleOptionClick ~ optionValue:", optionValue)
    // // // console.log(optionValue)
    const { niveau } = validation.values
    console.log("🚀 ~ handleOptionClick ~ niveau:", niveau)
    // const selectedIndex = niveau.indexOf(optionValue)
    //
    // if (selectedIndex === -1) {
    //   // L'option n'est pas encore sélectionnée, ajoutons-la.
    //   validation.setFieldValue("niveau", [...niveau, optionValue])
    // } else {
    //   // L'option est déjà sélectionnée, retirons-la.
    //   const newSelectedOptions = [...niveau]
    //   newSelectedOptions.splice(selectedIndex, 1)
    //   validation.setFieldValue("niveau", newSelectedOptions)
    // }
    // validation.setFieldValue('niveau', niveau.includes(optionValue) ? niveau.filter((level)=> level._id !== optionValue) : [...niveau,optionValue])
    // validation.setFieldValue('niveau', niveau.includes(optionValue) ? niveau.filter((level)=> level._id !== optionValue) : [...niveau,optionValue])
    validation.setFieldValue("niveau", optionValue)
  }

  console.log(selectedObject)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: selectedObject ? selectedObject?.title : "",
      first_name: selectedObject ? selectedObject?.first_name : "",
      last_name: selectedObject ? selectedObject?.last_name : "",
      email: selectedObject ? selectedObject?.email : "",
      name_enterprise: selectedObject
        ? selectedObject?.name_enterprise?._id
        : "",
      function: selectedObject ? selectedObject?.function : "",
      gender: selectedObject ? selectedObject?.gender : "",
      role: selectedObject ? selectedObject?.role : "",
      phone_number: selectedObject ? selectedObject?.phone_number : "",
      password: selectedObject ? selectedObject?.password : "",
      date_naiss: selectedObject ? selectedObject?.date_naiss : null,
      niveau: selectedObject ? selectedObject?.niveau[0]?._id : "",
    },
    validationSchema: Yup.object({
      // title: Yup.string().required("Please Enter Your Title"),
      // first_name: Yup.string().required("Please Enter Your First Name"),
      // last_name: Yup.string().required("Please Enter Your Last Name"),
      // email: Yup.string().required("Please Enter Your email"),
      // name_enterprise: Yup.string().required(
      //   "Please Enter Your Enterprise Name"
      // ),
      // function: Yup.string().required("Please Enter Your Function"),
      // gender: Yup.string().required("Please Enter Your Gender"),
      // role: Yup.string().required("Please Enter Your Role"),
      // phone_number: Yup.string().required("Please Enter Your Phone Number"),
      // password: Yup.string().required("Please Enter Your Password"),
      // niveau: Yup.string().required("Please Enter Your Level"),
    }),
    onSubmit: async values => {
      try {
        if (validation.isValid) {
          // Dispatch the action or perform other actions
          // const level = niveaux.filter(n => (n._id = values.niveau))
          const level = values.niveau.map(n => n.value)
          const data = {
            ...values,
            id: id_user,
            niveau: level,
          }
          console.log("🚀 ~ UserUpdate ~ data:", data)
          dispatch(update_User(data))
          await setmodal_standard(false)
          validation.resetForm()
        }
      } catch (error) {
        // console.log("Error submitting form: ", error)
      }
    },
  })
  const [tooltipOpen, setTooltipOpen] = useState("")

  const toggle = () => setTooltipOpen("")

  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [filteredNiveaux, setFilteredNiveaux] = useState([])

  useEffect(() => {
    console.log(
      "🚀 ~ useEffect ~ selectedObject?.organisation:",
      selectedObject
    )
    handleSelectOrganisation(selectedObject?.name_enterprise?._id)
  }, [selectedObject])

  const handleSelectOrganisation = id => {
    setFilteredNiveaux([])
    validation.setFieldValue("niveau", null)
    const org = organisations.filter(o => o?._id === id)
    setSelectedOrganisation(org[0])
    const niv = niveaux.filter(n => n?.organisation?._id === id)

    const ff = niv.map(n => {
      return { value: n._id, label: n[`type_name_${language}`] }
    })

    setFilteredNiveaux(ff)
  }

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Mise À Jour
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <Label htmlFor="title_input" className="col-md-2 col-form-label">
                {props.t("Title")}
              </Label>
              <div className="col-md-10">
                <select
                  id="title_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  name="title"
                  placeholder={props.t("Title")}
                  className="form-control"
                >
                  <option value="">{props.t("Title")}</option>
                  <option value="Mr">{props.t("Mr")}</option>
                  <option value="Mme">{props.t("Mme")}</option>
                  <option value="Mlle">{props.t("Mlle")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="firstname_input"
                className="col-md-2 col-form-label"
              >
                {props.t("FirstName")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("FirstName")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="firstname_input"
                  name="first_name"
                  type="text"
                  value={validation.values.first_name || ""}
                  invalid={
                    validation.touched.first_name &&
                    validation.errors.first_name
                      ? true
                      : false
                  }
                />
                {validation.touched.first_name &&
                validation.errors.first_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.first_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="lastname_input"
                className="col-md-2 col-form-label"
              >
                {props.t("LastName")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="lastname_input"
                  name="last_name"
                  placeholder={props.t("LastName")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="text"
                  value={validation.values.last_name || ""}
                  invalid={
                    validation.touched.last_name && validation.errors.last_name
                      ? true
                      : false
                  }
                />
                {validation.touched.last_name && validation.errors.last_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.last_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="email_input" className="col-md-2 col-form-label">
                {props.t("Email")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Email")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="email"
                  id="email_input"
                  type="email"
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="enterprise_input"
                className="col-md-2 col-form-label"
              >
                {props.t("short_code_org")}
              </Label>
              <div className="col-md-10">
                <select
                  id="enterprise_input"
                  onChange={e => {
                    handleSelectOrganisation(e.target.value)
                    validation.handleChange(e)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.name_enterprise || ""}
                  name="name_enterprise"
                  placeholder={props.t("short_code_org")}
                  className="form-control"
                >
                  <option value="">{props.t("short_code_org")}</option>
                  {organisations &&
                    organisations?.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {item?.short_code_org}
                      </option>
                    ))}
                </select>
              </div>
            </Row>

            <Row className="mb-3">
              <Label
                htmlFor="function_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Function")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Function")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="function_input"
                  name="function"
                  type="text"
                  value={validation.values.function || ""}
                  invalid={
                    validation.touched.function && validation.errors.function
                      ? true
                      : false
                  }
                />
                {validation.touched.function && validation.errors.function ? (
                  <FormFeedback type="invalid">
                    {validation.errors.function}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="gender_input" className="col-md-2 col-form-label">
                {props.t("Gender")}
              </Label>
              <div className="col-md-10">
                <select
                  id="gender_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.gender || ""}
                  name="gender"
                  placeholder={props.t("Gender")}
                  className="form-control"
                >
                  <option value="">{props.t("Gender")}</option>
                  <option value="M">{props.t("M")}</option>
                  <option value="F">{props.t("F")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="role_input" className="col-md-2 col-form-label">
                {props.t("Role")}
              </Label>
              <div className="col-md-10">
                <select
                  value={validation.values.role || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="role_input"
                  name="role"
                  placeholder={props.t("Role")}
                  className="form-control"
                >
                  <option value="">{props.t("Role")}</option>
                  <option value="SUPERADMIN">{props.t("SUPERADMIN")}</option>
                  <option value="ADMIN">{props.t("ADMIN")}</option>
                  <option value="NAUTELAUS">{props.t("NAUTELAUS")}</option>
                  <option value="COMMANDITAIRE">
                    {props.t("COMMANDITAIRE")}
                  </option>
                  <option value="COACH">{props.t("COACH")}</option>
                  <option value="SUPERCOACH">{props.t("SUPERCOACH")}</option>
                  <option value="USER">{props.t("User")}</option>
                </select>
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="tel_input" className="col-md-2 col-form-label">
                {props.t("Telephone")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="tel_input"
                  placeholder={props.t("Telephone")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="phone_number"
                  type="tel"
                  value={validation.values.phone_number || ""}
                  invalid={
                    validation.touched.phone_number &&
                    validation.errors.phone_number
                      ? true
                      : false
                  }
                />
                {validation.touched.phone_number &&
                validation.errors.phone_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone_number}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>

            <Row className="mb-4">
              <Label
                htmlFor="date_naiss_input"
                className="col-md-2 col-form-label"
              >
                {props.t("DateNaiss")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="date_naiss_input"
                  name="date_naiss"
                  placeholder={props.t("DateNaiss")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  type="date"
                  value={validation.values.date_naiss || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="niveau_input" className="col-md-2 col-form-label">
                {props.t("niveau")} -{" "}
                {selectedOrganisation?.type_organigramme === "PYRAMIDAL"
                  ? "P"
                  : "C"}
              </Label>
              <div className="col-md-10">
                {filteredNiveaux && (
                  <Select
                    onChange={e => validation.setFieldValue("niveau", e)}
                    value={validation.values.niveau}
                    options={filteredNiveaux}
                    isMulti={
                      selectedOrganisation?.type_organigramme === "PYRAMIDAL"
                    }
                    // styles={colourStyles}
                  />
                )}
              </div>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-primary ">
                Mettre à Jour
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

UserUpdate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(UserUpdate))
