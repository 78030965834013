import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_NEW_QUESTIONNAIRE_ADMIN,
  GET_QUESTIONNAIRE_DETAIL,
  GET_QUESTIONNAIRE_DETAIL_BY_ID,
  GET_QUESTIONNAIRES,
} from "./actionTypes"

import {
  addQuestionnaireAdminSuccess,
  addQuestionnaireAdminFail,
  getQuestionnaireDetailSuccess,
  getQuestionnaireDetailFail,
  getQuestionnaireDetailByIdSuccess,
  getQuestionnaireDetailByIdFail,
  getQuestionnairesSuccess,
  getQuestionnairesFail,
} from "./actions"

import { add_Questionnaire_Api, getQuestionnaireDetail, get_Questionnaire_By_Id_Api, get_Questionnaires_Api } from "helpers/backend"

function* onAddNewQuestionnaireAdminApi({ payload: questionnaireAdmin }) {
  try {
    const response = yield call(add_Questionnaire_Api, questionnaireAdmin)
    yield put(addQuestionnaireAdminSuccess(response))
  } catch (error) {
    yield put(addQuestionnaireAdminFail(error))
  }
}

function* fetchQuestionnaireDetail() {
  try {
    const response = yield call(getQuestionnaireDetail)
    yield put(getQuestionnaireDetailSuccess(response))
  } catch (error) {
    yield put(getQuestionnaireDetailFail(error))
  }
}

function* fetchQuestionnaires() {
  try {
    const response = yield call(get_Questionnaires_Api)
    yield put(getQuestionnairesSuccess(response.data))
  } catch (error) {
    yield put(getQuestionnairesFail(error))
  }
}

function* fetchQuestionnaireDetailById({ id }) {
  try {
    const response = yield call(get_Questionnaire_By_Id_Api, id)
    yield put(getQuestionnaireDetailByIdSuccess(response))
  } catch (error) {
    yield put(getQuestionnaireDetailByIdFail(error))
  }
}

function* questionnaireAdminSaga() {
  yield takeEvery(ADD_NEW_QUESTIONNAIRE_ADMIN, onAddNewQuestionnaireAdminApi)
  yield takeEvery(GET_QUESTIONNAIRE_DETAIL, fetchQuestionnaireDetail)
  yield takeEvery(GET_QUESTIONNAIRES, fetchQuestionnaires)
  yield takeEvery(GET_QUESTIONNAIRE_DETAIL_BY_ID, fetchQuestionnaireDetailById)
}

export default questionnaireAdminSaga
