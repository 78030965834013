import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Breadcrumbs from "components/Common/Breadcrumb"
import YourComponent from "./YourComponent"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Form,
  Button,
  Table,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// redux
import { useDispatch, useSelector } from "react-redux"
import { getBarycentreByNiveau as onGetBarycentreByNiveau } from "store/reports/actions"

export const BarycentreNiveau = props => {
  document.title = props.t("Reports")

  const dispatch = useDispatch()

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { questionnaires } = useSelector(state => ({
    questionnaires: state.questionnaireAdmin.questionnaires,
  }))

  const { niveaux } = useSelector(state => ({
    niveaux: state.niveaux.niveaux,
  }))

  const { average } = useSelector(state => ({
    average: state.reports.barycentre_niveau,
  }))

  // Form Validation For Id Questionnaire - Id Organisation & Id Niveau
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisationId: "",
      questionnaireId: "",
      niveauId: "",
    },
    validationSchema: Yup.object({
      organisationId: Yup.string().required("Please Select Your Code"),
      questionnaireId: Yup.string().required("Please Select Your Code"),
      niveauId: Yup.string().required("Please Select Your Code"),
    }),
    onSubmit: values => {
      dispatch(onGetBarycentreByNiveau(values))

      //   // Set the first_name to state
      //   const selectedUser = answers.find(item => item._id === values.id)
      //   if (selectedUser) {
      //     setFirstName(selectedUser.user.first_name)
      //   }

      // validation.resetForm()
    },
  })

  const language = localStorage.getItem("I18N_LANGUAGE")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Average")}
          />
          <Form className="needs-validation" onSubmit={validation.handleSubmit}>
            <Row className="mb-2">
              <Label
                htmlFor="organisationId_input"
                className="col-md-2 col-form-label"
              >
                {props.t("short_code_org")}
              </Label>
              <div className="col-md-10">
                <select
                  id="organisationId_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.organisationId || ""}
                  name="organisationId"
                  placeholder={props.t("short_code_org")}
                  className="form-control"
                >
                  <option value="">{props.t("short_code_org")}</option>
                  {organisations &&
                    organisations?.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {item?.short_code_org}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="mb-2">
              <Label
                htmlFor="questionnaireId_input"
                className="col-md-2 col-form-label"
              >
                {props.t("Questionnaire")}
              </Label>
              <div className="col-md-10">
                <select
                  id="questionnaireId_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.questionnaireId || ""}
                  name="questionnaireId"
                  placeholder={props.t("Questionnaire")}
                  className={`form-control ${
                    validation.touched.questionnaireId &&
                    validation.errors.questionnaireId
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">{props.t("Questionnaire")}</option>
                  {questionnaires?.map(item => (
                    <option key={item?._id} value={item?._id}>
                      {language === "fr" ? item?.menu?.fr : item?.menu?.en}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
            <Row className="mb-2">
              <Label htmlFor="niveau_input" className="col-md-2 col-form-label">
                {props.t("niveau")}
              </Label>
              <div className="col-md-10">
                <select
                  id="niveau_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.niveauId || ""}
                  name="niveauId"
                  placeholder={props.t("niveau")}
                  className={`form-control ${
                    validation.touched.niveauId && validation.errors.niveauId
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">{props.t("niveau")}</option>
                  {niveaux?.map(item => (
                    <option key={item?._id} value={item?._id}>
                      {language === "fr"
                        ? item?.type_name_fr
                        : item?.type_name_en}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
            <Row className="mb-2">
              <Button
                style={{ width: "25%", margin: "auto" }}
                type="submit"
                color="primary"
                className=" w-xl"
              >
                {props.t("Voir Barycentre du Niveau")}
              </Button>
            </Row>
          </Form>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {`${props.t("Barycentre du niveau")}`}
                  </CardTitle>
                  <div className="table-responsive"></div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("Barycentre par thème")}
                  </CardTitle>
                  <YourComponent
                    average={average}
                    language={language}
                    props={props}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

BarycentreNiveau.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(BarycentreNiveau))
