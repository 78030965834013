import {
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  GET_QUESTION_BY_THEME_SUCCESS,
  GET_QUESTION_BY_THEME_FAIL,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  questions: [],
  question: {},
  questionByTheme: {},
  loading: false,
  error: {},
}

const Questions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: true,
        questions: action.payload,
      }

    case GET_QUESTIONS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_QUESTION_BY_THEME_SUCCESS:
      return {
        ...state,
        loading: true,
        questionByTheme: {
          ...state.questionByTheme, // Copie des clés existantes
          [action.payload.id]: action.payload.question, // Ajout de la nouvelle clé et valeur
        },
      }

    case GET_QUESTION_BY_THEME_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_QUESTION_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        questions: [...state.questions, action.payload],
      }

    case ADD_QUESTION_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_QUESTION_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        questions: [...state.questions, action.payload],
      }

    case UPDATE_QUESTION_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Questions
