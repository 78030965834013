import {
  ADD_NEW_CATEGORIE,
  ADD_CATEGORIE_SUCCESS,
  ADD_CATEGORIE_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORIE,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL,
  UPDATE_CATEGORIE,
  UPDATE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_FAIL
} from "./actionTypes"
import {
  DELETE_THEME,
  DELETE_THEME_FAIL,
  DELETE_THEME_SUCCESS,
  UPDATE_THEME, UPDATE_THEME_FAIL,
  UPDATE_THEME_SUCCESS
} from "../themes/actionTypes"

export const getCategoriesActions = () => ({
  type: GET_CATEGORIES,
})

export const getCategorieSuccess = Categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: Categories,
})

export const getCategorieFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const addNewCategorie = categorie => ({
  type: ADD_NEW_CATEGORIE,
  payload: categorie,
})

export const addCategorieSuccess = categorie => ({
  type: ADD_CATEGORIE_SUCCESS,
  payload: categorie,
})

export const addCategorieFail = error => ({
  type: ADD_CATEGORIE_FAIL,
  payload: error,
})

export const updateCategorie = categorie => ({
  type: UPDATE_CATEGORIE,
  payload: categorie,
})

export const updateCategorieSuccess = categorie => ({
  type: UPDATE_CATEGORIE_SUCCESS,
  payload: categorie,
})

export const updateCategorieFail = error => ({
  type: UPDATE_CATEGORIE_FAIL,
  payload: error,
})
export const delete_Categorie = id => ({
  type: DELETE_CATEGORIE,
  payload: id,
})

export const delete_CategorieSuccess = id => ({
  type: DELETE_CATEGORIE_SUCCESS,
  payload: id,
})

export const delete_CategorieFail = error => ({
  type: DELETE_CATEGORIE_FAIL,
  payload: error,
})
