export const menuData = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: "bx bx-home-circle",
    sub_menu: [],
  },
  {
    name: "Questionnaire",
    href: "/questionnaire",
    icon: "bx bx-list-ol",
    sub_menu: [],
  },
  {
    name: "Users",
    href: "/#",
    icon: "bx bxs-user-detail",
    sub_menu: [
      {
        name: "UserList",
        href: "/user-list",
        icon: "",
      },
      {
        name: "NewUser",
        href: "/new-user",
        icon: "",
      },
    ],
  },
  {
    name: "Questionnaire",
    href: "/#",
    icon: "bx bxs-user-detail",
    sub_menu: [
      {
        name: "Questionnaire",
        href: "/questionnaire-admin",
        icon: "",
      },
    ],
  },
  {
    name: "Menus",
    href: "/#",
    icon: "bx bx-receipt",
    sub_menu: [
      {
        name: "MenuList",
        href: "/menu-list",
        icon: "",
      },
      {
        name: "NewMenu",
        href: "/new-menu",
        icon: "",
      },
    ],
  },
  {
    name: "ColorTypes",
    href: "/#",
    icon: "bx bx-tone",
    sub_menu: [
      {
        name: "ColorTypeList",
        href: "/colorType-list",
        icon: "",
      },
      {
        name: "NewColorType",
        href: "/new-colorType",
        icon: "",
      },
    ],
  },
  {
    name: "Orga",
    href: "/#",
    icon: "bx bxs-eraser",
    sub_menu: [
      {
        name: "OrgaList",
        href: "/orga-list",
        icon: "",
      },
      {
        name: "NewOrga",
        href: "/new-orga",
        icon: "",
      },
    ],
  },
  {
    name: "Questions",
    href: "/#",
    icon: "bx bx-list-ul",
    sub_menu: [
      {
        name: "QuestionList",
        href: "/question-list",
        icon: "",
      },
      {
        name: "NewQuestion",
        href: "/new-question",
        icon: "",
      },
    ],
  },
  {
    name: "Secteurs",
    href: "/#",
    icon: "bx bx-briefcase-alt",
    sub_menu: [
      {
        name: "SecteurList",
        href: "/secteur-list",
        icon: "",
      },
      {
        name: "NewSecteur",
        href: "/new-secteur",
        icon: "",
      },
    ],
  },
  {
    name: "Themes",
    href: "/#",
    icon: "bx bx-file",
    sub_menu: [
      {
        name: "ThemeList",
        href: "/theme-list",
        icon: "",
      },
      {
        name: "NewTheme",
        href: "/new-theme",
        icon: "",
      },
    ],
  },
  {
    name: "Categories",
    href: "/#",
    icon: "bx bx-share-alt",
    sub_menu: [
      {
        name: "CategoryList",
        href: "/category-list",
        icon: "",
      },
      {
        name: "NewCategory",
        href: "/new-category",
        icon: "",
      },
    ],
  },
  {
    name: "Answers",
    href: "/#",
    icon: "bx bxs-eraser",
    sub_menu: [
      {
        name: "AnswerList",
        href: "/answer-list",
        icon: "",
      },
      /* {
        name: "NewAnswer",
        href: "/new-answer",
        icon: ""
      } */
    ],
  },
  {
    name: "Run",
    href: "/#",
    icon: "bx bx-file",
    sub_menu: [
      {
        name: "SprintList",
        href: "/sprint-list",
        icon: "",
      },
      {
        name: "NewSprint",
        href: "/new-sprint",
        icon: "",
      },
    ],
  },
  {
    name: "Niveaux",
    href: "/#",
    icon: "bx bx-aperture",
    sub_menu: [
      {
        name: "NiveauList",
        href: "/niveau-list",
        icon: "",
      },
      {
        name: "NewNiveau",
        href: "/new-niveau",
        icon: "",
      },
    ],
  },
  {
    name: "Reports",
    href: "/#",
    icon: "bx bx-receipt",
    sub_menu: [
      {
        name: "Average",
        href: "/report-average",
        icon: "",
      },
      {
        name: "Niveaux",
        href: "/barycentre-niveau",
        icon: "",
      },
    ],
  },
]

export const getLatestEvent = events => {
  if (!events || events.length === 0) {
    return null
  }

  function compareEndDate(event1, event2) {
    const endDate1 = new Date(event1.s1_end_date)
    const endDate2 = new Date(event2.s1_end_date)
    return endDate2 - endDate1
  }

  events.sort(compareEndDate)

  return events[0]
}
