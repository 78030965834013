import {
  GET_SECTEURS_SUCCESS,
  GET_SECTEURS_FAIL,
  ADD_SECTEUR_SUCCESS,
  ADD_SECTEUR_FAIL, DELETE_SECTEUR_SUCCESS, UPDATE_SECTEUR_SUCCESS, UPDATE_SECTEUR_FAIL
} from "./actionTypes"
import { DELETE_MENU_FAIL, DELETE_MENU_SUCCESS, UPDATE_MENU_FAIL, UPDATE_MENU_SUCCESS } from "../menus/actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
  
  const INIT_STATE = {
    secteurs: [],
    secteur: {},
    loading: false,
    error: {},
  }
  
  const Secteurs = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_SECTEURS_SUCCESS:
        return {
          ...state,
          loading: true,
          secteurs: action.payload,
        }
  
      case GET_SECTEURS_FAIL:
        toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      case ADD_SECTEUR_SUCCESS:
        toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
        return {
          ...state,
          loading: true,
          secteurs: [...state.secteurs, action.payload],
        }
  
      case ADD_SECTEUR_FAIL:
        toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
      case DELETE_SECTEUR_SUCCESS:{
        toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
        let data = {
          ...state,
          secteurs: state.secteurs.filter(
            item => item._id !== action.payload
          ),
        }
        return data
      }
      case UPDATE_SECTEUR_SUCCESS:
        toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
        const data = {
          ...state,
          secteurs: state.secteurs.map(secteur =>
            secteur._id === action.payload.id
              ? { secteur, ...action.payload }
              : secteur
          ),
        }
        return data

      case UPDATE_SECTEUR_FAIL:
        toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
        return {
          ...state,
          error: action.payload,
        }


      case DELETE_MENU_FAIL:
        toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
        return {
          ...state,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default Secteurs
  