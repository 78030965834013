import { call, put, takeEvery } from "redux-saga/effects"

import { GET_THEMES, ADD_NEW_THEME, DELETE_THEME, UPDATE_THEME } from "./actionTypes"
import {
  getThemeSuccess,
  getThemeFail,
  addThemeSuccess,
  addThemeFail, delete_Theme, delete_ThemeFail, delete_ThemeSuccess, updateThemeSuccess, updateThemeFail
} from "./actions"

import {
  get_Themes_Api,
  add_NewTheme_Api,
  delete_User_Api,
  delete_Theme_Api,
  update_Menu_Api,
  update_Theme_Api
} from "helpers/backend"
import { delete_UserFail, delete_UserSuccess } from "../users/actions"
import { updateMenuFail, updateMenuSuccess } from "../menus/actions"

function* fetchThemes() {
  try {
    const response = yield call(get_Themes_Api)
    yield put(getThemeSuccess(response.data))
  } catch (error) {
    yield put(getThemeFail(error))
  }
}

function* onAddNewThemeApi({ payload: theme }) {
  try {
    const response = yield call(add_NewTheme_Api, theme)

    yield put(addThemeSuccess(response))
  } catch (error) {
    yield put(addThemeFail(error))
  }
}
function* onUpdateTheme({payload: theme}) {
  try {
    const response = yield call(update_Theme_Api, theme)

    yield put(updateThemeSuccess(theme))
  } catch (error) {
    yield put(updateThemeFail(error))
  }
}
function* onDeleteTheme({ payload: id }) {
  try {
    const response = yield call(delete_Theme_Api, id)
    yield put(delete_ThemeSuccess(id))
  } catch (error) {
    yield put(delete_ThemeFail(error))
  }
}


function* themeSaga() {
  yield takeEvery(GET_THEMES, fetchThemes)
  yield takeEvery(ADD_NEW_THEME, onAddNewThemeApi)
  yield takeEvery(DELETE_THEME, onDeleteTheme)
  yield takeEvery(UPDATE_THEME, onUpdateTheme)
}

export default themeSaga
