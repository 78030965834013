import {
  ADD_NEW_ANSWER,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  GET_ANSWERS,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
} from "./actionTypes"

export const getAnswersActions = () => ({
  type: GET_ANSWERS,
})

export const getAnswerSuccess = Answers => ({
  type: GET_ANSWERS_SUCCESS,
  payload: Answers,
})

export const getAnswerFail = error => ({
  type: GET_ANSWERS_FAIL,
  payload: error,
})

export const addNewAnswer = answer => ({
  type: ADD_NEW_ANSWER,
  payload: answer,
})

export const addAnswerSuccess = answer => ({
  type: ADD_ANSWER_SUCCESS,
  payload: answer,
})

export const addAnswerFail = error => ({
  type: ADD_ANSWER_FAIL,
  payload: error,
})
