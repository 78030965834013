/* NIVEAUX */
export const GET_NIVEAUX = "GET_NIVEAUX"
export const GET_NIVEAUX_SUCCESS = "GET_NIVEAUX_SUCCESS"
export const GET_NIVEAUX_FAIL = "GET_NIVEAUX_FAIL"

/**
 * add niveau
 */
export const ADD_NEW_NIVEAU = "ADD_NEW_NIVEAU"
export const ADD_NIVEAU_SUCCESS = "ADD_NIVEAU_SUCCESS"
export const ADD_NIVEAU_FAIL = "ADD_NIVEAU_FAIL"

/**
 * Edit niveau
 */
export const UPDATE_NIVEAU = "UPDATE_NIVEAU"
export const UPDATE_NIVEAU_SUCCESS = "UPDATE_NIVEAU_SUCCESS"
export const UPDATE_NIVEAU_FAIL = "UPDATE_NIVEAU_FAIL"

/**
 * Delete niveau
 */
export const DELETE_NIVEAU = "DELETE_NIVEAU"
export const DELETE_NIVEAU_SUCCESS = "DELETE_NIVEAU_SUCCESS"
export const DELETE_NIVEAU_FAIL = "DELETE_NIVEAU_FAIL"