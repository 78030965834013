import { GET_BARYCENTRE_USER_SUCCESS, GET_BARYCENTRE_USER_FAIL, GET_BARYCENTRE_NIVEAU_SUCCESS, GET_BARYCENTRE_NIVEAU_FAIL } from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  average: [],
  barycentre_niveau: {},
  error: null,
}

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BARYCENTRE_USER_SUCCESS:
      return {
        ...state,
        average: action.payload,
      }

    case GET_BARYCENTRE_USER_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }
    
      case GET_BARYCENTRE_NIVEAU_SUCCESS:
        toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_FETCH"))
      return {
        ...state,
        barycentre_niveau: action.payload,
      }

    case GET_BARYCENTRE_NIVEAU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Reports
