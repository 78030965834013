import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const DeleteComponent = props => {
  const { tog_center, handleDelete, modal_center, name, setmodal_center, id } =
    props
  return (
    <div>
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Attention</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-2">
            Voulez-vous vraiment supprimer {name} ?
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleDelete(id, name)
              setmodal_center(false)
            }}
          >
            Supprimer
          </button>
        </div>
      </Modal>
    </div>
  )
}

DeleteComponent.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(DeleteComponent))
