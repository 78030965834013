import React from "react"
import { Table } from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

const YourComponent = ({ average, language, props }) => {
  console.log("🚀 ~ YourComponent ~ average:", average)
  // Transformer les données pour regrouper par thème
  const themeData = {}

  // Fonction pour trouver le plus petit positif dans un tableau
  const trouverPlusPetitPositif = tableau => {
    const positifs = tableau.filter(nombre => nombre >= 0)

    if (positifs.length > 0) {
      return Math.min(...positifs)
    } else {
      return null
    }
  }

  const remplacerNombreEtZeros = (
    tableau,
    resultatPlusPetitPositif,
    barycentre
  ) => {
    // Créer un nouveau tableau en remplaçant les valeurs
    const nouveauTableau = tableau.map(nombre => {
      if (nombre === resultatPlusPetitPositif) {
        return barycentre
      } else {
        return 0
      }
    })

    return nouveauTableau
  }

  // Fonction pour trouver le plus grand négatif dans un tableau
  const trouverPlusGrandNegatif = tableau => {
    const negatifs = tableau.filter(nombre => nombre < 0)
    return negatifs.length > 0 ? Math.max(...negatifs) : null
  }

  if (average && average?.results) {
    average?.results.forEach((item, index) => {
      item?.averages?.forEach(averageItem => {
        const themeId = averageItem?.theme?._id

        if (!themeData[themeId]) {
          themeData[themeId] = {
            themeName:
              language === "fr"
                ? averageItem?.theme?.theme_name_fr
                : averageItem?.theme?.theme_name_en,
            values: [],
            cumulativeSumAscending: [], // Tableau pour l'effectif cumulé croissant
            cumulativeSumDescending: [], // Tableau pour l'effectif cumulé décroissant
            firstCalcul: [], // Nouveau tableau pour les résultats de la première opération
            secondCalcul: [], // Nouveau tableau pour les résultats de la deuxième opération
            resultatPlusPetitPositif: null, // Nouvelle propriété pour le résultat du plus petit positif
            resultatPlusGrandNegatif: null, // Nouvelle propriété pour le résultat du plus grand négatif
            barycentre: null,
            nouveauTableau: [],
          }
        }

        themeData[themeId]?.values?.push({
          id: item?._id,
          colorValue:
            language === "fr"
              ? item?.colorType[`color_value_${index}_fr`]
              : item?.colorType[`color_value_${index}_en`],
          average: averageItem?.average,
        })

        // Initialiser les tableaux d'effectifs cumulés croissants et décroissants
        themeData[themeId].cumulativeSumAscending = new Array(
          themeData[themeId].values.length
        ).fill(0)
        themeData[themeId].cumulativeSumDescending = new Array(
          themeData[themeId].values.length
        ).fill(0)

        // Calculer l'effectif cumulé croissant
        let cumulativeSumAscending = 0
        themeData[themeId].values.forEach((value, index) => {
          cumulativeSumAscending += value.average
          themeData[themeId].cumulativeSumAscending[index] =
            cumulativeSumAscending
        })

        // Calculer l'effectif cumulé décroissant
        let cumulativeSumDescending = 0
        for (let i = themeData[themeId].values.length - 1; i >= 0; i--) {
          const value = themeData[themeId].values[i]
          cumulativeSumDescending += value.average
          themeData[themeId].cumulativeSumDescending[i] =
            cumulativeSumDescending
        }

        // Ajouter la valeur du premier index de cumulativeSumDescending divisé par 2 à la fin du tableau
        const firstIndexValue =
          themeData[themeId].cumulativeSumDescending[0] / 2
        themeData[themeId].cumulativeSumDescending.push(firstIndexValue)

        // Calculer le tableau firstCalcul
        themeData[themeId].firstCalcul = themeData[
          themeId
        ].cumulativeSumDescending.map(
          item => item - themeData[themeId].cumulativeSumDescending[5]
        )

        // Enlever le dernier élément du tableau secondCalcul
        themeData[themeId].firstCalcul = themeData[themeId].firstCalcul.slice(
          0,
          -1
        )

        // Calculer le tableau secondCalcul
        themeData[themeId].secondCalcul = themeData[themeId].firstCalcul.map(
          number => (number < 0 ? 999 : number)
        )

        // Appeler la fonction avec le tableau spécifique
        themeData[themeId].resultatPlusPetitPositif = trouverPlusPetitPositif(
          themeData[themeId].secondCalcul
        )

        // Appeler la fonction avec le tableau spécifique
        themeData[themeId].resultatPlusGrandNegatif = trouverPlusGrandNegatif(
          themeData[themeId].firstCalcul
        )

        // Calculer le barycentre en utilisant les valeurs définies
        const recherche = -themeData[themeId].resultatPlusGrandNegatif
        themeData[themeId].barycentre = Math.ceil(
          (recherche /
            (recherche + themeData[themeId].resultatPlusPetitPositif)) *
            100
        )

        // Appeler la fonction avec les valeurs spécifiques
        themeData[themeId].nouveauTableau = remplacerNombreEtZeros(
          themeData[themeId].firstCalcul,
          themeData[themeId].resultatPlusPetitPositif,
          themeData[themeId].barycentre
        )
      })
    })
  }

  //   // console.log({themeData})

  // Afficher les tableaux pour chaque thème
  console.log("🚀 ~ tables ~ themeId:", themeData)
  const tables = Object.keys(themeData)?.map(themeId => {
    console.log("🚀 ~ tables ~ themeId:", themeId)
    const theme = themeData[themeId]

    return (
      <div key={themeId}>
        <h1>TABLE</h1>
        <Table className="table mb-3">
          <thead>
            <tr>
              <th>{props.t("Niveaux")}</th>
              <th>{theme?.themeName}</th>
              <th className="m-auto" colSpan="6">
                Barycentre
              </th>
            </tr>
          </thead>
          <tbody>
            {theme?.values?.map((value, index) => (
              <tr key={index}>
                <th scope="row">
                  {value?.id}
                  {`. ${value?.colorValue}`}
                </th>
                <td>{value?.average}</td>
                <td>{theme.cumulativeSumDescending[index]}</td>{" "}
                {/* Afficher l'effectif cumulé décroissant */}
                <td>{theme.firstCalcul[index]}</td>
                <td>{theme.secondCalcul[index]}</td>{" "}
                {/* Afficher le tableau secondCalcul */}
                <td>{theme.resultatPlusPetitPositif}</td>
                <td>{theme.nouveauTableau[index]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  })

  return <div>{tables}</div>
}

YourComponent.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(YourComponent))
