import React, { useState } from "react"
import { Input } from "reactstrap"
import "./ColorRange.css"

const ColorRange = () => {
  const [value, setValue] = useState(0)

  const handleChange = event => {
    setValue(Number(event.target.value))
  }

  return (
    <div className="range-container">
      <label>Valeur: {value}</label>
      <Input
        type="range"
        min="-1"
        max="10"
        value={value}
        onChange={handleChange}
        className="color-range"
      />
    </div>
  )
}

export default ColorRange
