import { combineReducers } from "redux"

//questionnaireAdmin
import questionnaireAdmin from "./questionnaireAdmin/reducer"

//user
import users from "./users/reducer"

//menu
import menus from "./menus/reducer"

//colorTypes
import colorTypes from "./colorTypes/reducer"

//organisations
import organisations from "./organisations/reducer"

//questions
import questions from "./questions/reducer"

//secteurs
import secteurs from "./secteurs/reducer"

//themes
import themes from "./themes/reducer"

//categories
import categories from "./categories/reducer"

//answers
import answers from "./answers/reducer"

//sprints
import sprints from "./sprints/reducer"

//niveaux
import niveaux from "./niveaux/reducer"

//reports
import reports from "./reports/reducer"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//contacts
import contacts from "./contacts/reducer"

//Dashboard 
import Dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  contacts,
  Dashboard,
  users,
  menus,
  colorTypes,
  organisations,
  questions,
  secteurs,
  themes,
  categories,
  answers,
  sprints,
  niveaux,
  questionnaireAdmin,
  reports
})

export default rootReducer
