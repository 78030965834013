/* ORGANISATIONS */
export const GET_ORGANISATIONS = "GET_ORGANISATIONS"
export const GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS"
export const GET_ORGANISATIONS_FAIL = "GET_ORGANISATIONS_FAIL"

/**
 * add organisation
 */
export const ADD_NEW_ORGANISATION = "ADD_NEW_ORGANISATION"
export const ADD_ORGANISATION_SUCCESS = "ADD_ORGANISATION_SUCCESS"
export const ADD_ORGANISATION_FAIL = "ADD_ORGANISATION_FAIL"

/**
 * Edit organisation
 */
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION"
export const UPDATE_ORGANISATION_SUCCESS = "UPDATE_ORGANISATION_SUCCESS"
export const UPDATE_ORGANISATION_FAIL = "UPDATE_ORGANISATION_FAIL"

/**
 * Delete organisation
 */
export const DELETE_ORGANISATION = "DELETE_ORGANISATION"
export const DELETE_ORGANISATION_SUCCESS = "DELETE_ORGANISATION_SUCCESS"
export const DELETE_ORGANISATION_FAIL = "DELETE_ORGANISATION_FAIL"