import axios from "axios"
import { del, get, post, put } from "./api"
import * as url from "./url"
import {
  DELETE_API_CATEGORIE,
  DELETE_API_SECTEUR,
  UPDATE_API_SECTEUR,
} from "./url"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// const API_URL = "https://evoluscan-api.onrender.com/api/";
const API_URL = "https://api.dev.evoluscan.com/api/"

const config = {
  headers: { "content-type": "multipart/form-data" },
}

const axios_Login = axios.create({
  baseURL: API_URL,
})

axios_Login.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

// Login Method
const postLogin = data => axios_Login.post(url.POST_LOGIN, data)

// Logout Method
const postLogout = data => post(url.POST_LOGOUT, data)

// postForgetPwd
const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data)

// get users
export const get_Users_Api = () => get(url.GET_API_USERS)

// add user
export const add_NewUser_Api = user_user => post(url.ADD_API_USERS, user_user)

// delete user
export const delete_User_Api = id =>
  del(`${url.DELETE_API_USER}/${id}`, { params: { id } })

// update user
export const update_User_Api = user => put(`${url.UPDATE_API_USER}`, user)

// get menus
export const get_Menus_Api = () => get(url.GET_API_MENUS)

// add menu
export const add_NewMenu_Api = menu => post(url.ADD_API_MENU, menu)

// delete menu
export const delete_Menu_Api = id =>
  del(`${url.DELETE_API_MENU}/${id}`, { params: { id } })

// update menu
export const update_Menu_Api = menu => put(`${url.UPDATE_API_MENU}`, menu)

// get colorType
export const get_ColorTypes_Api = () => get(url.GET_API_COLORTYPES)

// add colorType
export const add_NewColorType_Api = colorType =>
  post(url.ADD_API_COLORTYPE, colorType)

// get organisation
export const get_Organisations_Api = () => get(url.GET_API_ORGANISATIONS)

// add organisation
export const add_NewOrganisation_Api = organisation =>
  post(url.ADD_API_ORGANISATION, organisation, config)

// delete organisation
export const delete_Organisation_Api = id =>
  del(`${url.DELETE_API_ORGANISATION}/${id}`, { params: { id } })

// update organisation
export const update_Organisation_Api = organisation =>
  put(`${url.UPDATE_API_ORGANISATION}`, organisation, config)

// get question
export const get_Questions_Api = () => get(url.GET_API_QUESTIONS)

// get Question by ThemeID
export const get_Question_By_ThemeID_APi = themeID =>
  get(`${url.GET_API_QUESTION_BY_THEMEID}/${themeID}`, { params: { themeID } })

// add question
export const add_NewQuestion_Api = question =>
  post(url.ADD_API_QUESTION, question)

// add question
export const updateQuestion_Api = question =>
  post(url.ADD_API_QUESTION, question)

// get secteur
export const get_Secteurs_Api = () => get(url.GET_API_SECTEURS)

// add secteur
export const add_NewSecteur_Api = secteur => post(url.ADD_API_SECTEUR, secteur)

//update secteur
export const update_Secteur_Api = secteur =>
  put(`${url.UPDATE_API_SECTEUR}/${secteur.id}`, secteur)

//delete secteur
export const delete_Secteur_Api = id =>
  del(`${url.DELETE_API_SECTEUR}/${id}`, { params: { id } })

// get theme
export const get_Themes_Api = () => get(url.GET_API_THEMES)

export const delete_Theme_Api = id =>
  del(`${url.DELETE_API_THEME}/${id}`, { params: { id } })
export const update_Theme_Api = theme =>
  put(`${url.UPDATE_API_THEME}/${theme.id}`, theme)

// add theme
export const add_NewTheme_Api = theme => post(url.ADD_API_THEME, theme)

// get Categorie
export const get_Categories_Api = () => get(url.GET_API_CATEGORIES)
export const delete_Categories_Api = id =>
  del(`${url.DELETE_API_CATEGORIE}/${id}`, { params: { id } })
export const update_Categorie_Api = categorie =>
  put(`${url.UPDATE_API_CATEGORIE}/${categorie.id}`, categorie)

// add Categorie
export const add_NewCategorie_Api = categorie =>
  post(url.ADD_API_CATEGORIE, categorie)

// get Answer
export const get_Answers_Api = () => get(url.GET_API_ANSWERS)
export const get_Users_Answers_Api = () => get(url.GET_API_USER_ANSWERS)

// add Answer
export const add_NewAnswer_Api = answer => post(url.ADD_API_ANSWER, answer)

// get Sprint
export const get_Sprints_Api = () => get(url.GET_API_SPRINTS)

// add Sprint
export const add_NewSprint_Api = sprint => post(url.ADD_API_SPRINT, sprint)

// update Sprint
export const update_Sprint_Api = sprint =>
  put(`${url.UPDATE_API_SPRINT}`, sprint)

// delete Sprint
export const delete_Sprint_Api = id =>
  del(`${url.DELETE_API_SPRINT}/${id}`, { params: { id } })

// get Niveau
export const get_Niveaux_Api = () => get(url.GET_API_NIVEAUX)

// add Niveau
export const add_NewNiveau_Api = niveau => post(url.ADD_API_NIVEAU, niveau)

// update Niveau
export const update_Niveau_Api = niveau =>
  put(`${url.UPDATE_API_NIVEAU}`, niveau)

// delete Sprint
export const delete_Niveau_Api = id =>
  del(`${url.DELETE_API_NIVEAU}/${id}`, { params: { id } })

// create Questionnaire
export const add_Questionnaire_Api = questionnaireAdmin =>
  post(url.ADD_API_QUESTIONNAIRE, questionnaireAdmin)

// get Questionnaire detail
export const getQuestionnaireDetail = () =>
  get(url.GET_API_QUESTIONNAIRE_DETAIL)

// get Questionnaire by Id
export const get_Questionnaire_By_Id_Api = id =>
  get(`${url.GET_API_QUESTIONNAIRE_BY_ID}/${id}`, { params: { id } })

// get Questionnaires
export const get_Questionnaires_Api = () => get(url.GET_API_QUESTIONNAIRES)

// Get Average of Each Answer
export const get_Barycentre_User_Api = id =>
  get(`${url.GET_API_BARYCENTRE_USER}/${id}`, { params: { id } })
export const get_Barycentre_Niveau_Api = barycentre_niveau =>
  post(url.GET_API_BARYCENTRE_NIVEAU, barycentre_niveau)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postLogout,
  postForgetPwd,
}
