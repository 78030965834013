import { ADD_NEW_ORGANISATION, ADD_ORGANISATION_SUCCESS, ADD_ORGANISATION_FAIL, GET_ORGANISATIONS, GET_ORGANISATIONS_SUCCESS, GET_ORGANISATIONS_FAIL, DELETE_ORGANISATION,
  DELETE_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_FAIL, UPDATE_ORGANISATION, UPDATE_ORGANISATION_SUCCESS, UPDATE_ORGANISATION_FAIL } from "./actionTypes"

export const getOrganisationsActions = () => ({
  type: GET_ORGANISATIONS,
})

export const getOrganisationSuccess = organisations => ({
  type: GET_ORGANISATIONS_SUCCESS,
  payload: organisations,
})

export const getOrganisationFail = error => ({
  type: GET_ORGANISATIONS_FAIL,
  payload: error,
})

export const addNewOrganisation = organisation => ({
  type: ADD_NEW_ORGANISATION,
  payload: organisation,
})

export const addOrganisationSuccess = organisation => ({
  type: ADD_ORGANISATION_SUCCESS,
  payload: organisation,
})

export const addOrganisationFail = error => ({
  type: ADD_ORGANISATION_FAIL,
  payload: error,
})

export const updateOrganisation = organisation => ({
  type: UPDATE_ORGANISATION,
  payload: organisation,
})

export const updateOrganisationSuccess = organisation => ({
  type: UPDATE_ORGANISATION_SUCCESS,
  payload: organisation,
})

export const updateOrganisationFail = error => ({
  type: UPDATE_ORGANISATION_FAIL,
  payload: error,
})

export const deleteOrganisation = id => ({
  type: DELETE_ORGANISATION,
  payload: id,
})

export const deleteOrganisationSuccess = id => ({
  type: DELETE_ORGANISATION_SUCCESS,
  payload: id,
})

export const deleteOrganisationFail = error => ({
  type: DELETE_ORGANISATION_FAIL,
  payload: error,
})