/* MENUS */
export const GET_MENUS = "GET_MENUS"
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS"
export const GET_MENUS_FAIL = "GET_MENUS_FAIL"

/**
 * add menu
 */
export const ADD_NEW_MENU = "ADD_NEW_MENU"
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS"
export const ADD_MENU_FAIL = "ADD_MENU_FAIL"

/**
 * Edit menu
 */
export const UPDATE_MENU = "UPDATE_MENU"
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS"
export const UPDATE_MENU_FAIL = "UPDATE_MENU_FAIL"

/**
 * Delete menu
 */
export const DELETE_MENU = "DELETE_MENU"
export const DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS"
export const DELETE_MENU_FAIL = "DELETE_MENU_FAIL"