import React, { useEffect, useRef, useState } from "react"
import { fabric } from "fabric"
import { ButtonGroup } from "@material-ui/core"
import { Button } from "reactstrap"
import { AddImage, AddText, CanvasDefault, HandleSaveCanvas } from "./Common/EditorActions"

export const Editor = ({ firstname }) => {
  const [canvasState, setCanvasState] = useState(null)
  const canvasEl = useRef(null)
  const inputFileRef = useRef(null)
  useEffect(() => {
    const canvas = new fabric.Canvas(canvasEl.current, {
      fill: "#fff", width: 800, height: 3508
    })
    setCanvasState(canvas)

  }, [])

  useEffect(()=>{
    if(canvasState){
      console.log("this is the canvas state",JSON.stringify(CanvasDefault()))
      canvasState.loadFromJSON(JSON.stringify(CanvasDefault()),()=>{
        canvasState.requestRenderAll()
      })
    }
  },[canvasState])
  const actionArray = [
    {
      icon: "fas fa-plus",
      actionName: "add_text"
    },
    {
      icon: "fas fa-image",
      actionName: "add_image"
    },
    {
      icon: "fas fa-bold",
      actionName: "text_bold"
    },
    {
      icon: "fas fa-italic",
      actionName: "text_italic"
    },
    {
      icon: "fas fa-underline",
      actionName: "text_underline"
    }
  ]

  const handleAction = (name) => {
    if (name === "add_text") {
      AddText(canvasState)
    } else if (name === "add_image") {
      inputFileRef.current.click()
    }
  }
  const handleChangeFileInput = (e) => {
    let fr = new FileReader()
    let img = new Image()
    if (e.target.files) {
      fr.onload = function() {
        img.onload = function() {
          AddImage(canvasState, e)
        }
        img.src = fr.result
      }

      fr.readAsDataURL(e.target.files[0])
    }

  }
  const fileInputClicked=(event) =>{
    let file = event.target.files[0];
    window.removeEventListener('focus', handleFocusBack);
  }

  const handleFocusBack=()=>{
    console.log('focus-back');
  }

  const clickedFileInput=()=>{
    window.addEventListener('focus', handleFocusBack,{once:true});
  }

  return (
    <>
      <div style={{
        height: "60px",
        border: "solid 1px",
        display: "flex",
        alignItems: "center",
        background: "#fff"
      }}>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          {actionArray.map((action, index) => (
            <Button key={index} onClick={() => handleAction(action.actionName)}><i className={action.icon}></i></Button>
          ))}
        </ButtonGroup>
        <Button onClick={()=>HandleSaveCanvas(canvasState)}>
          <i className={'fa fa-save'}></i>
        </Button>
        <Button color={'danger'} className={'mx-2'} onClick={()=>{
          const activeObject = canvasState.getActiveObject()
          if(activeObject){
            canvasState.remove(activeObject)
          }
        }}>
          <i className={'fa fa-trash'}></i>
        </Button>
        <input
          type="file"
          hidden={true}
          accept="image/*"
          onChange={(e) => {
            handleChangeFileInput(e)
          }}
          onClick={clickedFileInput}
          ref={inputFileRef}
        />
      </div>
      <canvas ref={canvasEl}></canvas>
    </>
  )

}

export default Editor