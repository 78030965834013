import {
  ADD_NEW_USER_USER,
  ADD_USER_USER_FAIL,
  ADD_USER_USER_SUCCESS,
  GET_USERS_USERS,
  GET_USERS_USERS_SUCCESS,
  GET_USERS_USERS_FAIL,
  UPDATE_USER_USER,
  UPDATE_USER_USER_SUCCESS,
  UPDATE_USER_USER_FAIL,
  DELETE_USER_USER,
  DELETE_USER_USER_SUCCESS,
  DELETE_USER_USER_FAIL
} from "./actionTypes"

export const get_UsersActions = () => ({
  type: GET_USERS_USERS,
})

export const get_UsersSuccess = users_users => ({
  type: GET_USERS_USERS_SUCCESS,
  payload: users_users,
})

export const get_UsersFail = error => ({
  type: GET_USERS_USERS_FAIL,
  payload: error,
})

export const add_NewUser = user_user => ({
  type: ADD_NEW_USER_USER,
  payload: user_user,
})

export const add_UserSuccess = user_user => ({
  type: ADD_USER_USER_SUCCESS,
  payload: user_user,
})

export const add_UserFail = error => ({
  type: ADD_USER_USER_FAIL,
  payload: error,
})

export const update_User = user => ({
  type: UPDATE_USER_USER,
  payload: user,
})

export const update_UserSuccess = user => ({
  type: UPDATE_USER_USER_SUCCESS,
  payload: user,
})

export const update_UserFail = error => ({
  type: UPDATE_USER_USER_FAIL,
  payload: error,
})

export const delete_User = id => ({
  type: DELETE_USER_USER,
  payload: id,
})

export const delete_UserSuccess = id => ({
  type: DELETE_USER_USER_SUCCESS,
  payload: id,
})

export const delete_UserFail = error => ({
  type: DELETE_USER_USER_FAIL,
  payload: error,
})