import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { withRouter, Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import { useSelector } from "react-redux"
import { Avatar } from "@material-ui/core"

const WelcomeComp = props => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [username, setusername] = useState("")
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      setusername(obj?.user?.last_name)
    }
  }, [props.success])
  const user = obj?.user
  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))
  const organisationUser = organisations.find(
    entreprise => entreprise._id === user?.name_enterprise
  )
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">
                  {" "}
                  {props.t("Welcome")} {props.t(`${username}`)} !
                </h5>
                <p>{props.t(`${obj?.user?.role}`)}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              {/*<img src={profileImg} alt="" className="img-fluid" />*/}
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                {/*<img*/}
                {/*  src={avatar1}*/}
                {/*  alt=""*/}
                {/*  className="img-thumbnail rounded-circle"*/}
                {/*/>*/}
                <Avatar>{user?.last_name.slice(0, 2)}</Avatar>
              </div>
              <h5 className="font-size-15 text-truncate">
                {user?.last_name + " " + user?.first_name}
              </h5>
              <p className="text-muted mb-0 text-truncate">{user?.function}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="7">
                    <h5 className="font-size-15">{props.t("Email")}</h5>
                    <p
                      className="text-muted mb-0 text-nowrap "
                      style={{
                        maxWidth: "250px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {user?.email}
                    </p>
                  </Col>
                  <Col xs="5">
                    <h5 className="font-size-12">{props.t("Telephone")}</h5>
                    <p className="text-muted mb-0">{user.phone_number}</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-primary  btn-sm">
                    {props.t("organisation")} :{" "}
                    {user?.name_enterprise?.short_code_org}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(WelcomeComp))
