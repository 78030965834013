import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ORGANISATIONS,
  ADD_NEW_ORGANISATION,
  DELETE_ORGANISATION,
  UPDATE_ORGANISATION,
  ADD_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_FAIL,
  DELETE_ORGANISATION_FAIL,
  UPDATE_ORGANISATION_FAIL,
} from "./actionTypes"
import {
  getOrganisationSuccess,
  getOrganisationFail,
  addOrganisationSuccess,
  addOrganisationFail,
  deleteOrganisationSuccess,
  deleteOrganisationFail,
  updateOrganisationSuccess,
  updateOrganisationFail,
} from "./actions"

import {
  get_Organisations_Api,
  add_NewOrganisation_Api,
  delete_Organisation_Api,
  update_Organisation_Api,
} from "helpers/backend"

function* fetchOrganisations() {
  try {
    const response = yield call(get_Organisations_Api)
    yield put(getOrganisationSuccess(response.data))
  } catch (error) {
    yield put(getOrganisationFail(error))
  }
}

function* onAddNewOrganisationApi({ payload: organisation }) {
  try {
    const response = yield call(add_NewOrganisation_Api, organisation)

    yield put(addOrganisationSuccess(response))
    yield call(fetchOrganisations)
  } catch (error) {
    yield put(addOrganisationFail(error))
  }
}

function* onUpdateOrganisation({ payload: organisation }) {
  try {
    const response = yield call(update_Organisation_Api, organisation)
    console.log("🚀 ~ response:", response)
    const data = { ...response, id: response._id }
    console.log("🚀 ~ function*onUpdateOrganisation ~ data:", data)
    yield put(updateOrganisationSuccess(data))
    yield call(fetchOrganisations)
  } catch (error) {
    console.log("🚀 ~ error:", error)
    yield put(updateOrganisationFail(error))
  }
}

function* onDeleteOrganisation({ payload: id }) {
  try {
    const response = yield call(delete_Organisation_Api, id)
    yield put(deleteOrganisationSuccess(id))
    yield call(fetchOrganisations)
  } catch (error) {
    yield put(deleteOrganisationFail(error))
  }
}

function* organisationSaga() {
  yield takeEvery(GET_ORGANISATIONS, fetchOrganisations)
  yield takeEvery(ADD_NEW_ORGANISATION, onAddNewOrganisationApi)
  yield takeEvery(UPDATE_ORGANISATION, onUpdateOrganisation)
  yield takeEvery(DELETE_ORGANISATION, onDeleteOrganisation)

  yield takeEvery(
    [
      ADD_ORGANISATION_SUCCESS,
      DELETE_ORGANISATION_SUCCESS,
      UPDATE_ORGANISATION_SUCCESS,
      ADD_ORGANISATION_FAIL,
      DELETE_ORGANISATION_FAIL,
      UPDATE_ORGANISATION_FAIL,
    ],
    fetchOrganisations
  )
}

export default organisationSaga
