import PropTypes from "prop-types"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import logo from "assets/images/logo.jpg"

//redux
import { useDispatch } from "react-redux"
import { addNewColorType as onAddNewColorType } from "store/colorTypes/actions"

export const ColorTypeCreate = props => {
  // meta title
  document.title = props.t("NewColorType")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const toggleToast = () => {
    setToast(!toast)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      color_type_fr: "",
      color_type_en: "",
      color_value_0_fr: "",
      color_value_0_en: "",
      color_value_1_fr: "",
      color_value_1_en: "",
      color_value_2_fr: "",
      color_value_2_en: "",
      color_value_3_fr: "",
      color_value_3_en: "",
      color_value_4_fr: "",
      color_value_4_en: "",
    },
    validationSchema: Yup.object({
      color_type_fr: Yup.string().required("Please Enter Your color_type Name"),
      color_type_en: Yup.string().required("Please Enter Your color_type Name"),
      color_value_0_fr: Yup.string().required(
        "Please Enter Your color_value_0 Name"
      ),
      color_value_0_en: Yup.string().required(
        "Please Enter Your color_value_0 Name"
      ),
      color_value_1_fr: Yup.string().required(
        "Please Enter Your color_value_1 Name"
      ),
      color_value_1_en: Yup.string().required(
        "Please Enter Your color_value_1 Name"
      ),
      color_value_2_fr: Yup.string().required(
        "Please Enter Your color_value_2 Name"
      ),
      color_value_2_en: Yup.string().required(
        "Please Enter Your color_value_2 Name"
      ),
      color_value_3_fr: Yup.string().required(
        "Please Enter Your color_value_3 Name"
      ),
      color_value_3_en: Yup.string().required(
        "Please Enter Your color_value_3 Name"
      ),
      color_value_4_fr: Yup.string().required(
        "Please Enter Your color_value_4 Name"
      ),
      color_value_4_en: Yup.string().required(
        "Please Enter Your color_value_4 Name"
      ),
    }),
    onSubmit: values => {
      // save new menu
    //   // console.log({ values })
      dispatch(onAddNewColorType(values))
      // toggleToast()
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("ColorTypes")}
            breadcrumbItem={props.t("NewColorType")}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("ColorTypeMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {props.t("NewColorType")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_type_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("ColorType (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("ColorType (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_type_fr"
                          name="color_type_fr"
                          type="text"
                          value={validation.values.color_type_fr || ""}
                          invalid={
                            validation.touched.color_type_fr &&
                            validation.errors.color_type_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_type_fr &&
                        validation.errors.color_type_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_type_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_type_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("ColorType (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("ColorType (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_type_en"
                          name="color_type_en"
                          type="text"
                          value={validation.values.color_type_en || ""}
                          invalid={
                            validation.touched.color_type_en &&
                            validation.errors.color_type_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_type_en &&
                        validation.errors.color_type_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_type_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_0_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Valeur A (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Valeur A (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_0_fr"
                          name="color_value_0_fr"
                          type="text"
                          value={validation.values.color_value_0_fr || ""}
                          invalid={
                            validation.touched.color_value_0_fr &&
                            validation.errors.color_value_0_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_0_fr &&
                        validation.errors.color_value_0_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_0_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_0_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Value A (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Value A (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_0_en"
                          name="color_value_0_en"
                          type="text"
                          value={validation.values.color_value_0_en || ""}
                          invalid={
                            validation.touched.color_value_0_en &&
                            validation.errors.color_value_0_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_0_en &&
                        validation.errors.color_value_0_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_0_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_1_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Valeur B (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Valeur B (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_1_fr"
                          name="color_value_1_fr"
                          type="text"
                          value={validation.values.color_value_1_fr || ""}
                          invalid={
                            validation.touched.color_value_1_fr &&
                            validation.errors.color_value_1_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_1_fr &&
                        validation.errors.color_value_1_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_1_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_1_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Value B (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Value B (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_1_en"
                          name="color_value_1_en"
                          type="text"
                          value={validation.values.color_value_1_en || ""}
                          invalid={
                            validation.touched.color_value_1_en &&
                            validation.errors.color_value_1_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_1_en &&
                        validation.errors.color_value_1_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_1_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_2_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Valeur C (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Valeur C (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_2_fr"
                          name="color_value_2_fr"
                          type="text"
                          value={validation.values.color_value_2_fr || ""}
                          invalid={
                            validation.touched.color_value_2_fr &&
                            validation.errors.color_value_2_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_2_fr &&
                        validation.errors.color_value_2_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_2_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_2_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Value C (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Value C (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_2_en"
                          name="color_value_2_en"
                          type="text"
                          value={validation.values.color_value_2_en || ""}
                          invalid={
                            validation.touched.color_value_2_en &&
                            validation.errors.color_value_2_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_2_en &&
                        validation.errors.color_value_2_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_2_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_3_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Valeur D (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Valeur D (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_3_fr"
                          name="color_value_3_fr"
                          type="text"
                          value={validation.values.color_value_3_fr || ""}
                          invalid={
                            validation.touched.color_value_3_fr &&
                            validation.errors.color_value_3_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_3_fr &&
                        validation.errors.color_value_3_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_3_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_3_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Value D (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Value D (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_3_en"
                          name="color_value_3_en"
                          type="text"
                          value={validation.values.color_value_3_en || ""}
                          invalid={
                            validation.touched.color_value_3_en &&
                            validation.errors.color_value_3_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_3_en &&
                        validation.errors.color_value_3_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_3_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_4_fr"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Valeur E (Français)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Valeur E (Français)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_4_fr"
                          name="color_value_4_fr"
                          type="text"
                          value={validation.values.color_value_4_fr || ""}
                          invalid={
                            validation.touched.color_value_4_fr &&
                            validation.errors.color_value_4_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_4_fr &&
                        validation.errors.color_value_4_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_4_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="color_value_4_en"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Value E (English)")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("Value E (English)")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="color_value_4_en"
                          name="color_value_4_en"
                          type="text"
                          value={validation.values.color_value_4_en || ""}
                          invalid={
                            validation.touched.color_value_4_en &&
                            validation.errors.color_value_4_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.color_value_4_en &&
                        validation.errors.color_value_4_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.color_value_4_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {props.t("SubmitColorType")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ColorTypeCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ColorTypeCreate))
