import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"

// Styles pour le PDF
const styles = StyleSheet.create({
  page: {
    paddingVertical: 30,
    paddingHorizontal: 50,
  },
  section: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 10,
  },
  image: {
    marginVertical: 10,
  },
})

// Composant pour l'en-tête
const Header = () => (
  <View style={styles.section}>
    <Image src="/path/to/your/logo.jpg" style={styles.image} />
  </View>
)

// Composant pour l'introduction
const Introduction = () => (
  <View style={styles.section}>
    <Text style={styles.title}>Vos résultats Evoluscan</Text>
    <Text style={styles.paragraph}>Bonjour Jean-Pierre !</Text>
    {/* ... Reste du contenu de l'introduction ... */}
  </View>
)

// Composant pour les modes de leadership
const LeadershipModes = () => (
  <View style={styles.section}>
    <Text style={styles.title}>Les modes de leadership</Text>
    {/* ... Contenu sur les modes de leadership ... */}
  </View>
)

// Composant principal pour le PDF
const ReportContent = () => (
  <Document
    style={{
      width: "100%",
      height: "100%",
      position: "relative",
      zIndex: 0,
      overflow: "hidden",
      backgroundColor: "white",
      color: "black",
      border: "1px solid black",
      padding: "10px",
      margin: "10px",
      borderRadius: "10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
      display: "block",
    }}
  >
    <Page size="A4" style={styles.page}>
      <Header />
      <Introduction />
      <LeadershipModes />
    </Page>
    <Page>
      <View style={styles.section}>
        <Text style={styles.title}>Ma page Test</Text>
        <Text style={styles.paragraph}>
          Voluptatem possimus repudiandae asperiores ea perferendis quis itaque.
          Consectetur ea dolorem. Numquam quia reprehenderit. Repellendus
          similique aut qui placeat rem. Minima eum aperiam dolor. Fugit ea
          rerum amet dolores nihil reiciendis enim nesciunt. Laudantium expedita
          eum quam rerum nostrum quos modi sit. Sit debitis earum enim.
          Perferendis nihil delectus perferendis. Sit expedita vel consequatur
          sapiente rem repellendus. Rerum ut amet odit. Minima in reiciendis et
          numquam.
        </Text>
      </View>
    </Page>
    {/* Ajoutez ici les autres pages si nécessaire */}
  </Document>
)

export default ReportContent
