/* ANSWERS */
export const GET_ANSWERS = "GET_ANSWERS"
export const GET_ANSWERS_SUCCESS = "GET_ANSWERS_SUCCESS"
export const GET_ANSWERS_FAIL = "GET_ANSWERS_FAIL"

/**
 * add Answer
 */
export const ADD_NEW_ANSWER = "ADD_NEW_ANSWER"
export const ADD_ANSWER_SUCCESS = "ADD_ANSWER_SUCCESS"
export const ADD_ANSWER_FAIL = "ADD_ANSWER_FAIL"
