import React from "react"
import "./ColorRange.css"

const ColorRangeButton = props => {
  const [selected, setSelected] = React.useState(props.value)
  const range = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const colors = [
    "#dc2626", // 0-1
    "#ef4444", // 1-2
    "#ea580c", // 2-3
    "#f97316", // 3-4
    "#fb923c", // 4-5
    "#f59e0b", // 4-5
    "#fbbf24", // 5-6
    "#fcd34d", // 6-7
    "#fde047", // 6-7
    "#bef264", // 7-8
    "#84cc16", // 8-9
    "#22c55e", // 9-10
    "#15803d", // 9-10
  ]

  return (
    <div
      className="d-flex align-items-center justify-content-center px-5 py-3"
      style={{ width: "100%" }}
    >
      {range.map((value, index) => (
        <button
          key={index}
          onClick={() => {
            setSelected(value)
            props.onValueChange(value)
          }}
          type="button"
          className="colbtn btn btn-sm w-100 rounded-0 text-white"
          style={{
            background:
              selected === value
                ? "#d8b4fe"
                : `linear-gradient(to right, ${colors[index]}, ${
                    colors[index + 1]
                  })`,
            fontWeight: "bold",
          }}
        >
          {value}
        </button>
      ))}
    </div>
  )
}

export default ColorRangeButton
