/* USERS */
export const GET_USERS_USERS = "GET_USERS_USERS"
export const GET_USERS_USERS_SUCCESS = "GET_USERS_USERS_SUCCESS"
export const GET_USERS_USERS_FAIL = "GET_USERS_USERS_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER_USER = "ADD_NEW_USER_USER"
export const ADD_USER_USER_SUCCESS = "ADD_USER_USER_SUCCESS"
export const ADD_USER_USER_FAIL = "ADD_USER_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER_USER = "UPDATE_USER_USER"
export const UPDATE_USER_USER_SUCCESS = "UPDATE_USER_USER_SUCCESS"
export const UPDATE_USER_USER_FAIL = "UPDATE_USER_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER_USER = "DELETE_USER_USER"
export const DELETE_USER_USER_SUCCESS = "DELETE_USER_USER_SUCCESS"
export const DELETE_USER_USER_FAIL = "DELETE_USER_USER_FAIL"