import React, { forwardRef, useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import Breadcrumbs from "components/Common/Breadcrumb"
import YourComponent from "./YourComponent"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Form,
  Button,
  Table,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// redux
import { useDispatch, useSelector } from "react-redux"
import { getAverage as onGetAverage } from "store/reports/actions"
import { getAnswersActions as onGetAnswers } from "store/answers/actions"
import Editor from "../../components/Editor"

export const Average = props => {
  document.title = props.t("Reports")

  const dispatch = useDispatch()

  const { average } = useSelector(state => ({
    average: state.reports.average,
  }))

  const { answers } = useSelector(state => ({
    answers: state.answers.answers,
  }))

  // State to store first_name
  const [firstName, setFirstName] = useState("")

  useEffect(() => {
    if (answers && !answers?.length) {
      dispatch(onGetAnswers())
    }
  }, [answers?.length])

  // console.log({ average })
  // console.log({ answers })

  // Form Validation For Id Questionnaire
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
    },
    validationSchema: Yup.object({
      id: Yup.string().required("Please Select Your Code"),
    }),
    onSubmit: values => {
      console.log({ values })
      dispatch(onGetAverage(values.id))

      // Set the first_name to state
      const selectedUser = answers.find(item => item._id === values.id)
      console.log("🚀 ~ Average ~ selectedUser:", selectedUser)
      if (selectedUser) {
        setFirstName(selectedUser.user.first_name)
      }

      validation.resetForm()
    },
  })

  const [userViewInformation, setUserViewInformation] = useState("")

  const language = localStorage.getItem("I18N_LANGUAGE")
  const buttonRef = useRef(null)
  useEffect(() => {
    if (answers) {
      let user = answers.data?.find(
        item => item.user?._id === validation.values.id
      )?.user
      setUserViewInformation("")

      setFirstName(`${user?.first_name || ""} ${user?.last_name || ""}`)
    }
  }, [validation.values])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Average")}
          />
          <Form className="needs-validation" onSubmit={validation.handleSubmit}>
            <Row className="mb-2">
              <Label htmlFor="id_input" className="col-md-2 col-form-label">
                {props.t("User")}
              </Label>
              <div className="col-md-10">
                <select
                  id="id_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.id || ""}
                  name="id"
                  placeholder={props.t("User")}
                  className="form-control"
                >
                  <option value="">{props.t("User")}</option>
                  {answers &&
                    answers?.data?.map(item => (
                      <option key={item?._id} value={item?.user?._id}>
                        {`${item?.user?.first_name}`}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row className="my-4">
              <Button
                style={{ width: "25%", margin: "auto" }}
                type="submit"
                color="primary"
                className=" w-xl"
              >
                {props.t("Voir Barycentre")}
              </Button>
              <Button
                style={{ width: "25%", margin: "auto" }}
                type="submit"
                color="primary"
                className=" w-xl"
                onClick={() => props.history.push("/report-file")}
              >
                {props.t("Générer un rapport sur l'utilisateur")}
              </Button>
            </Row>
          </Form>
          <Col
            xl={12}
            className={"w-100 d-flex align-items-center justify-content-center"}
          >
            {/* <div >

              <Editor firstname={firstName} />
            </div> */}
          </Col>{" "}
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {`${props.t("Barycentre de l'utilisateur")} ${
                      firstName || ""
                    }`}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-3">
                      <thead>
                        <tr>
                          <th>{props.t("Niveaux")}</th>
                          {average &&
                            average?.results?.[0]?.averages?.map(
                              (item, index) => (
                                <th key={index}>
                                  {language === "fr"
                                    ? item?.theme?.theme_name_fr
                                    : item?.theme?.theme_name_en}
                                </th>
                              )
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {average &&
                          average?.results?.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">
                                {item?._id}
                                {". "}
                                {language === "fr"
                                  ? item?.colorType?.[`color_value_${index}_fr`]
                                  : item?.colorType?.[
                                      `color_value_${index}_en`
                                    ]}
                              </th>
                              {item?.averages?.map((average, index) => (
                                <td key={index}>{average?.average}</td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th scope="row">{props.t("Niveaux")}</th>
                          <th>{props.t("Moyenne")}</th>
                          <th>{props.t("%")}</th>
                          <th className="m-auto" colSpan="6">
                            Barycentre
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {average?.averages?.map((averageItem, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{averageItem}</td>
                            <td>{`${average?.percentages?.[index] || 0}%`}</td>
                            <td>{`${average?.totals?.[index]}`}</td>
                            <td>{`${average?.cumulativeDescending?.[index]}`}</td>
                            <td>{`${average?.firstCalcul?.[index]}`}</td>
                            <td>{`${average?.secondCalcul?.[index]}`}</td>
                            <td>{`${average?.resultatPlusPetitPositif}`}</td>
                            <td>{`${average?.nouveauTableau?.[index]}%`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("Barycentre par thème")}
                  </CardTitle>
                  <YourComponent
                    average={average}
                    language={language}
                    props={props}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Average.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Average))
