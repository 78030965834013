import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table, Modal, ModalHeader, ModalBody
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { deleteSecteur, getSecteursActions } from "store/secteurs/actions"

//i18n
import { withTranslation } from "react-i18next"
import MenuUpdate from "../../Menu/MenuList/menu-update"
import DeleteComponent from "../../../components/Common/DeleteComponent"
import { deleteMenu } from "../../../store/menus/actions"
import SecteurCreate from "../SecteurCreate/secteur-create"

export const SecteurList = props => {
  // meta title
  document.title = props.t("Secteur")

  const dispatch = useDispatch()

  const { secteurs } = useSelector(state => ({
    secteurs: state.secteurs.secteurs,
  }))

  const { loading } = useSelector(state => ({
    loading: state.secteurs.loading,
  }))

  function showToast() {
    const title = props.t("Secteur")
    const message = props.t("SecteurListMessage")
    const positionClass = "toast-top-right"
    const showEasing = "swing"
    const hideEasing = "linear"
    const showMethod = "fadeIn"
    const hideMethod = "fadeOut"
    const showDuration = 300
    const hideDuration = 1000
    const timeOut = 5000
    const extendedTimeOut = 1000
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    }
    toastr.success(message, title)
  }
  const [modal_center, setmodal_center] = useState(false)
  const [secteur_selected, setSecteurSelected] = useState("")
  const [name_secteur, setNameSecteur] = useState("")

  useEffect(() => {
    if (secteurs && !secteurs?.length) {
      dispatch(getSecteursActions())
      // showToast()
    }
  }, [secteurs?.length])

  const language = localStorage.getItem("I18N_LANGUAGE")
  const handleDelete = (id, name) => {
    dispatch(deleteSecteur(id))
  }
  function tog_center() {
    setmodal_center(!modal_center)
    // removeBodyCss()
  }

  //   ------------------------- Loader ---------------------------------//
  if (loading && secteurs?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }
  const [secteurUpdate,setSecteurUpdate] = useState(undefined)

  return (
    <React.Fragment>
      {
        secteurUpdate ? <FullModalEdit
          open={secteurUpdate !== undefined}
          secteur={secteurUpdate}
          onClose={()=>{
           setSecteurUpdate(undefined)
          }}

        /> : null
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Secteur")}
            breadcrumbItem={props.t("SecteurList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("SecteurList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>{props.t("code_secteur")}</th>
                        <th>{props.t("name_secteur")}</th>
                        <th>{props.t("Actions")}</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          secteurs?.map((secteur, index) => (
                            <tr key={secteur?._id}>
                              <th scope="row">{index}</th>
                              <td>{secteur?.code_secteur}</td>
                              <td>{language === 'fr' ? secteur?.name_secteur_fr : secteur?.name_secteur_en}</td>
                              <td>
                                <div className="d-flex justify-content-around">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSecteurUpdate(secteur)
                                      // tog_standard()
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setNameSecteur(language === "fr" ? secteur?.name_secteur_fr : secteur.name_secteur_en)
                                      setSecteurSelected(secteur._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>

                                {/*<MenuUpdate*/}
                                {/*  modal_standard={modal_standard}*/}
                                {/*  id_menu={menu_selected}*/}
                                {/*  setmenu_selected={setmenu_selected}*/}
                                {/*  setmodal_standard={setmodal_standard}*/}
                                {/*  tog_standard={tog_standard}*/}
                                {/*/>*/}

                                <DeleteComponent
                                  tog_center={tog_center}
                                  name={name_secteur}
                                  id={secteur_selected}
                                  setmodal_center={setmodal_center}
                                  modal_center={modal_center}
                                  handleDelete={handleDelete}
                                />

                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const  FullModalEdit = props =>{
  const {onClose,open,secteur} = props
  return(
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={()=>{
      }}>
        {/*{menu.fr}*/}
      </ModalHeader>
      <ModalBody>
        <SecteurCreate initialState={secteur} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}

SecteurList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SecteurList))
