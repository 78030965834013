import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { withRouter } from "react-router-dom"
import { Modal, Form, Row, Label, Input, FormFeedback } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getThemesActions } from "store/themes/actions"
import { updateMenu } from "store/menus/actions"

//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

const MenuUpdate = props => {
  const { modal_standard, tog_standard, setmodal_standard, id_menu } = props

  const dispatch = useDispatch()

  const { themes } = useSelector(state => ({
    themes: state.themes.themes,
  }))

  const { menus } = useSelector(state => ({
    menus: state.menus.menus,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  // Utilisez la méthode find pour récupérer l'objet avec l'id correspondant
  const selectedObject = menus?.find(obj => obj?._id === id_menu)

//   // console.log({ selectedObject })

  useEffect(() => {
    if (themes && !themes?.length) {
      dispatch(getThemesActions())
    }
  }, [themes?.length])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fr: selectedObject ? selectedObject?.fr : "",
      en: selectedObject ? selectedObject?.en : "",
      themes: selectedObject ? selectedObject?.themes?.map(item => item?._id) : [],
    },
    validationSchema: Yup.object({
      fr: Yup.string().optional("Please Enter Your French Menu Name"),
      en: Yup.string().optional("Please Enter Your English Menu Name"),
      themes: Yup.array().required("Veuillez sélectionner au moins un thème"),
    }),
    onSubmit: async values => {
      try {
        const data = {
          ...values,
          id: id_menu,
        }
        // // console.log({data})
        dispatch(updateMenu(data))

        await setmodal_standard(false)

        validation.resetForm()

      } catch (error) {
        // console.log("Error submitting form: ", error)
      }
    },
  })

  const handleOptionClick = optionValue => {
    const { themes } = validation.values
    const selectedIndex = themes.indexOf(optionValue)

    if (selectedIndex === -1) {
      // L'option n'est pas encore sélectionnée, ajoutons-la.
      validation.setFieldValue("themes", [...themes, optionValue])
    } else {
      // L'option est déjà sélectionnée, retirons-la.
      const newSelectedOptions = [...themes]
      newSelectedOptions.splice(selectedIndex, 1)
      validation.setFieldValue("themes", newSelectedOptions)
    }
  }

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 text-primary" id="myModalLabel">
            Mise À Jour
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <Label htmlFor="fr_input" className="col-md-2 col-form-label">
                {props.t("Menu")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("Menu")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="fr_input"
                  name={language === "fr" ? "fr" : "en"}
                  type="text"
                  value={(language === "fr" ? validation.values.fr : validation.values.en) || ""}
                  invalid={
                    language === "fr" ? (validation.touched.fr && validation.errors.fr ? true : false) : (validation.touched.en && validation.errors.en ? true : false)
                  }
                />
                {language === "fr" ? (
                  validation.touched.fr && validation.errors.fr ? (
                    <FormFeedback type="invalid">
                      {validation.errors.fr}
                    </FormFeedback>
                  ) : null
                ) : (
                  validation.touched.en && validation.errors.en ? (
                    <FormFeedback type="invalid">
                      {validation.errors.en}
                    </FormFeedback>
                  ) : null
                )}
              </div>
            </Row>
            <Row className="mb-3">
              <Label htmlFor="en_input" className="col-md-2 col-form-label">
                {props.t("Themes")}
              </Label>
              <div className="col-md-10">
                <select
                  id="themes_input"
                  onChange={e => handleOptionClick(e.target.value)}
                  onBlur={validation.handleBlur}
                  multiple
                  style={{ height: "400px" }}
                  value={validation.values.themes || ""}
                  name="themes"
                  placeholder={props.t("Themes")}
                  className="form-control"
                >
                  <option value="">{props.t("Themes")}</option>
                  {themes &&
                    themes.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {props.t(
                          language === "fr"
                            ? item?.theme_name_fr
                            : item?.theme_name_en
                        )}
                      </option>
                    ))}
                </select>
                {validation.touched.themes && validation.errors.themes ? (
                  <FormFeedback type="invalid">
                    {validation.errors.themes}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-primary ">
                Mettre à Jour {language}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

MenuUpdate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MenuUpdate))
