import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS_USERS,
  ADD_NEW_USER_USER,
  UPDATE_USER_USER,
  DELETE_USER_USER,
} from "./actionTypes"
import {
  get_UsersSuccess,
  get_UsersFail,
  add_UserSuccess,
  add_UserFail,
  update_UserSuccess,
  update_UserFail,
  delete_UserSuccess,
  delete_UserFail,
} from "./actions"

import {
  get_Users_Api,
  add_NewUser_Api,
  update_User_Api,
  delete_User_Api,
} from "helpers/backend"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

function* fetchUsersApi() {
  try {
    const response = yield call(get_Users_Api)
    yield put(get_UsersSuccess(response.data))
  } catch (error) {
    yield put(get_UsersFail(error))
  }
}

function* onAddNewUserApi({ payload: user_user }) {
  try {
    const response = yield call(add_NewUser_Api, user_user)
    console.log("🚀 ~ function*onAddNewUserApi ~ response:", response)
    yield put(add_UserSuccess(response))
    // toastr.success("Success", "User added successfully")
  } catch (error) {
    console.log("🚀 ~ function*onAddNewUserApi ~ response:", error.response)
    const errorMessage = error.response?.data?.message || "Something went wrong"
    yield put(add_UserFail(errorMessage))
  }
}

function* onUpdateUser({ payload: organisation }) {
  try {
    const { role, ...rest } = organisation
    const response = yield call(update_User_Api, rest)
    yield put(update_UserSuccess(organisation))
  } catch (error) {
    yield put(update_UserFail(error))
  }
}

function* onDeleteUser({ payload: id }) {
  try {
    const response = yield call(delete_User_Api, id)
    yield put(delete_UserSuccess(id))
  } catch (error) {
    yield put(delete_UserFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS_USERS, fetchUsersApi)
  yield takeEvery(ADD_NEW_USER_USER, onAddNewUserApi)
  yield takeEvery(UPDATE_USER_USER, onUpdateUser)
  yield takeEvery(DELETE_USER_USER, onDeleteUser)
}

export default userSaga
