import {
  GET_THEMES_SUCCESS,
  GET_THEMES_FAIL,
  ADD_THEME_SUCCESS,
  ADD_THEME_FAIL,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAIL,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
} from "./actionTypes"
import {
  DELETE_USER_USER_FAIL,
  DELETE_USER_USER_SUCCESS,
} from "../users/actionTypes"
import { UPDATE_MENU_FAIL, UPDATE_MENU_SUCCESS } from "../menus/actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  themes: [],
  theme: {},
  loading: false,
  error: {},
}

const Themes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_THEMES_SUCCESS:
      return {
        ...state,
        loading: true,
        themes: action.payload,
      }

    case GET_THEMES_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADD_THEME_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        loading: true,
        themes: [...state.themes, action.payload],
      }

    case ADD_THEME_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPDATE_THEME_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        themes: state.themes.map(theme =>
          theme._id === action.payload.id ? { theme, ...action.payload } : theme
        ),
      }

    case UPDATE_THEME_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_THEME_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      return {
        ...state,
        themes: state.themes?.filter(theme => theme?._id !== action.payload),
      }

    case DELETE_THEME_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Themes
