import PropTypes from "prop-types"
import React from "react"

import { withRouter } from "react-router-dom"
import { Modal, Form, Row, Label, Input, FormFeedback } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useDispatch, useSelector } from "react-redux"
import { updateNiveau as onUpdateNiveau } from "store/niveaux/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

const NiveauUpdate = props => {
  const { modal_standard, tog_standard, setmodal_standard, id_niveau } = props
  const dispatch = useDispatch()

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const { niveaux } = useSelector(state => ({
    niveaux: state.niveaux.niveaux,
  }))

  // Utilisez la méthode find pour récupérer l'objet avec l'id correspondant
  const selectedObject = niveaux?.find(obj => obj?._id === id_niveau)

//   // console.log({ selectedObject })

  const language = localStorage.getItem("I18N_LANGUAGE")

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type_name_fr: selectedObject?.type_name_fr ? selectedObject?.type_name_fr : "",
      number_niveau: selectedObject?.number_niveau ? selectedObject?.number_niveau : "",
      type_name_en: selectedObject?.type_name_en ? selectedObject?.type_name_en : "",
      organisation: selectedObject?.organisation ? selectedObject?.organisation?._id : "",
      index_precedent: selectedObject?.index_precedent ? selectedObject?.index_precedent : "",
    },
    validationSchema: Yup.object({
      type_name_fr: Yup.string().required("Please Enter Your French Type Name"),
      type_name_en: Yup.string().required(
        "Please Enter Your English Type Name"
      ),
      number_niveau: Yup.string().required("Please Enter Your Level Number"),
      // number_niveau: Yup.string().required("Please Enter Your Level Number"),
      organisation: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
    }),
    onSubmit: async values => {
      // save new users
      try {
        const data = {
            ...values,
            id : id_niveau,
        }

        dispatch(onUpdateNiveau(data))
        await setmodal_standard(false)
        validation.resetForm()
      } catch (error) {
        console.error("Error Submitting update niveau form: ", error)
      }
    },
  })

  return (
    <div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Mise À Jour
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row className="mb-3">
              <Label
                htmlFor="type_name_fr_input"
                className="col-md-2 col-form-label"
              >
                {props.t("LevelTypeFR")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("LevelTypeFR")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="type_name_fr_input"
                  name="type_name_fr"
                  type="text"
                  value={validation.values.type_name_fr || ""}
                  invalid={
                    validation.touched.type_name_fr &&
                    validation.errors.type_name_fr
                      ? true
                      : false
                  }
                />
                {validation.touched.type_name_fr &&
                validation.errors.type_name_fr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.type_name_fr}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="type_name_en_input"
                className="col-md-2 col-form-label"
              >
                {props.t("LevelTypeENG")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  placeholder={props.t("LevelTypeENG")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  id="type_name_en_input"
                  name="type_name_en"
                  type="text"
                  value={validation.values.type_name_en || ""}
                  invalid={
                    validation.touched.type_name_en &&
                    validation.errors.type_name_en
                      ? true
                      : false
                  }
                />
                {validation.touched.type_name_en &&
                validation.errors.type_name_en ? (
                  <FormFeedback type="invalid">
                    {validation.errors.type_name_en}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="number_niveau_input"
                className="col-md-2 col-form-label"
              >
                {props.t("LevelNumber")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="number_niveau_input"
                  placeholder={props.t("LevelNumber")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="number_niveau"
                  type="number"
                  value={validation.values.number_niveau || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="index_precedent_input"
                className="col-md-2 col-form-label"
              >
                {props.t("index_precedent")}
              </Label>
              <div className="col-md-10">
                <Input
                  className="form-control"
                  id="index_precedent_input"
                  placeholder={props.t("index_precedent")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="index_precedent"
                  type="number"
                  value={validation.values.index_precedent || ""}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Label
                htmlFor="organisation_input"
                className="col-md-2 col-form-label"
              >
                {props.t("organisation")}
              </Label>
              <div className="col-md-10">
                <select
                  id="organisation_input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.organisation || ""}
                  name="organisation"
                  placeholder={props.t("organisation")}
                  className="form-control"
                >
                  <option value="">{props.t("organisation")}</option>
                  {organisations &&
                    organisations.map(item => (
                      <option key={item?._id} value={item?._id}>
                        {item?.short_code_org}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button type="submit" className="btn btn-primary ">
                Mettre à Jour
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

NiveauUpdate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(NiveauUpdate))
