import {
  GET_ORGANISATIONS_SUCCESS,
  DELETE_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_FAIL,
  GET_ORGANISATIONS_FAIL,
  ADD_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_FAIL,
  UPDATE_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  organisations: [],
  organisation: {},
  error: {},
}

const Organisations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        organisations: action.payload,
      }

    case GET_ORGANISATIONS_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ORGANISATION_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        organisations: [...state.organisations, action.payload],
      }

    case ADD_ORGANISATION_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ORGANISATION_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        organisations: state.organisations.map(organisation =>
          action.payload.id && organisation._id === action.payload.id
            ? { organisation, ...action.payload.data }
            : organisation
        ),
      }

    case UPDATE_ORGANISATION_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ORGANISATION_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      return {
        ...state,
        organisations: state.organisations.filter(
          item => item._id !== action.payload
        ),
      }

    case DELETE_ORGANISATION_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Organisations
