import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Button,
  Label,
  FormFeedback,
  Toast,
  ToastHeader,
  ToastBody,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
//redux
import { useDispatch, useSelector } from "react-redux"
import { addNewNiveau as onAddNewNiveau } from "store/niveaux/actions"

//import images
import logo from "assets/images/logo.jpg"

export const NiveauCreate = props => {
  // meta title
  document.title = props.t("NewNiveau")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)

  const { organisations } = useSelector(state => ({
    organisations: state.organisations.organisations,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  const toggleToast = () => {
    setToast(!toast)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type_name_fr: "",
      number_niveau: 0,
      type_name_en: "",
      organisation: "",
      index_precedent: 0,
    },
    validationSchema: Yup.object({
      type_name_fr: Yup.string().required("Please Enter Your French Type Name"),
      type_name_en: Yup.string().required(
        "Please Enter Your English Type Name"
      ),
      number_niveau: Yup.string().required("Please Enter Your Level Number"),
      // number_niveau: Yup.string().required("Please Enter Your Level Number"),
      organisation: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
    }),
    onSubmit: values => {
      // save new users
      //   // console.log({values})
      dispatch(onAddNewNiveau(values))
      // toggleToast()
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Niveaux")}
            breadcrumbItem={props.t("NewNiveau")}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("NiveauMessage")}</ToastBody>
            </Toast>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    {props.t("NewNiveau")}
                  </CardTitle>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row className="mb-3">
                      <Label
                        htmlFor="type_name_fr_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("LevelTypeFR")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("LevelTypeFR")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="type_name_fr_input"
                          name="type_name_fr"
                          type="text"
                          value={validation.values.type_name_fr || ""}
                          invalid={
                            validation.touched.type_name_fr &&
                            validation.errors.type_name_fr
                              ? true
                              : false
                          }
                        />
                        {validation.touched.type_name_fr &&
                        validation.errors.type_name_fr ? (
                          <FormFeedback type="invalid">
                            {validation.errors.type_name_fr}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="type_name_en_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("LevelTypeENG")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          placeholder={props.t("LevelTypeENG")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          id="type_name_en_input"
                          name="type_name_en"
                          type="text"
                          value={validation.values.type_name_en || ""}
                          invalid={
                            validation.touched.type_name_en &&
                            validation.errors.type_name_en
                              ? true
                              : false
                          }
                        />
                        {validation.touched.type_name_en &&
                        validation.errors.type_name_en ? (
                          <FormFeedback type="invalid">
                            {validation.errors.type_name_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="number_niveau_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("LevelNumber")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="number_niveau_input"
                          placeholder={props.t("LevelNumber")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="number_niveau"
                          type="number"
                          value={validation.values.number_niveau || 0}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="index_precedent_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("index_precedent")}
                      </Label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          id="index_precedent_input"
                          placeholder={props.t("index_precedent")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="index_precedent"
                          type="number"
                          value={validation.values.index_precedent || 0}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="organisation_input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("organisation")}
                      </Label>
                      <div className="col-md-10">
                        <select
                          id="organisation_input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.organisation || ""}
                          name="organisation"
                          placeholder={props.t("organisation")}
                          className="form-control"
                        >
                          <option value="">{props.t("organisation")}</option>
                          {organisations &&
                            organisations.map(item => (
                              <option key={item?._id} value={item?._id}>
                                {item?.short_code_org}
                              </option>
                            ))}
                        </select>
                        {validation.touched.organisation &&
                        validation.errors.organisation ? (
                          <FormFeedback type="invalid">
                            {validation.errors.organisation}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        type="submit"
                        color="primary"
                        className=" w-xl"
                      >
                        {props.t("SubmitNiveau")}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NiveauCreate.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(NiveauCreate))
