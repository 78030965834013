import {
  GET_NIVEAUX_SUCCESS,
  GET_NIVEAUX_FAIL,
  ADD_NIVEAU_SUCCESS,
  ADD_NIVEAU_FAIL,
  UPDATE_NIVEAU_SUCCESS,
  UPDATE_NIVEAU_FAIL,
  DELETE_NIVEAU_SUCCESS,
  DELETE_NIVEAU_FAIL,
} from "./actionTypes"
import { t } from "i18next"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const INIT_STATE = {
  niveaux: [],
  niveau: {},
  error: {},
}

const Niveaux = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NIVEAUX_SUCCESS:
      return {
        ...state,
        niveaux: action.payload,
      }

    case GET_NIVEAUX_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_FETCH"))
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NIVEAU_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_ADD"))
      return {
        ...state,
        niveaux: [...state.niveaux, action.payload],
      }

    case ADD_NIVEAU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_ADD"))
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_NIVEAU_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_UPDATE"))
      return {
        ...state,
        niveaux: state.niveaux.map(niveau =>
          niveau._id === action.payload.id
            ? { niveau, ...action.payload}
            : niveau
        ),
      }

    case UPDATE_NIVEAU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_UPDATE"))
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_NIVEAU_SUCCESS:
      toastr.success(t("SUCCESS_TITLE"), t("SUCCESS_DELETE"))
      const data = {
        ...state,
        niveaux: state.niveaux.filter(
          item => item._id !== action.payload.id
        ),
      }
      return {
        ...state,
        niveaux: state.niveaux.filter(
          item => item._id !== action.payload
        ),
      }

    case DELETE_NIVEAU_FAIL:
      toastr.error(t("ERROR_TITLE"), t("ERROR_DELETE"))
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Niveaux
