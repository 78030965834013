import {
  ADD_NEW_NIVEAU,
  ADD_NIVEAU_SUCCESS,
  ADD_NIVEAU_FAIL,
  GET_NIVEAUX,
  GET_NIVEAUX_SUCCESS,
  GET_NIVEAUX_FAIL,
  UPDATE_NIVEAU,
  UPDATE_NIVEAU_FAIL,
  UPDATE_NIVEAU_SUCCESS,
  DELETE_NIVEAU,
  DELETE_NIVEAU_FAIL,
  DELETE_NIVEAU_SUCCESS,
} from "./actionTypes"

export const getNiveauxActions = () => ({
  type: GET_NIVEAUX,
})

export const getNiveauSuccess = niveaux => ({
  type: GET_NIVEAUX_SUCCESS,
  payload: niveaux,
})

export const getNiveauFail = error => ({
  type: GET_NIVEAUX_FAIL,
  payload: error,
})

export const addNewNiveau = niveau => ({
  type: ADD_NEW_NIVEAU,
  payload: niveau,
})

export const addNiveauSuccess = niveau => ({
  type: ADD_NIVEAU_SUCCESS,
  payload: niveau,
})

export const addNiveauFail = error => ({
  type: ADD_NIVEAU_FAIL,
  payload: error,
})

export const updateNiveau = niveau => ({
  type: UPDATE_NIVEAU,
  payload: niveau,
})

export const updateNiveauSuccess = niveau => ({
  type: UPDATE_NIVEAU_SUCCESS,
  payload: niveau,
})

export const updateNiveauFail = error => ({
  type: UPDATE_NIVEAU_FAIL,
  payload: error,
})

export const deleteNiveau = id => ({
  type: DELETE_NIVEAU,
  payload: id,
})

export const deleteNiveauSuccess = id => ({
  type: DELETE_NIVEAU_SUCCESS,
  payload: id,
})

export const deleteNiveauFail = error => ({
  type: DELETE_NIVEAU_FAIL,
  payload: error,
})