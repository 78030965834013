import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_NIVEAUX,
  ADD_NEW_NIVEAU,
  UPDATE_NIVEAU,
  DELETE_NIVEAU,
} from "./actionTypes"
import {
  getNiveauSuccess,
  getNiveauFail,
  addNiveauSuccess,
  addNiveauFail,
  updateNiveauSuccess,
  updateNiveauFail,
  deleteNiveauSuccess,
  deleteNiveauFail,
} from "./actions"

import {
  get_Niveaux_Api,
  add_NewNiveau_Api,
  update_Niveau_Api,
  delete_Niveau_Api,
} from "helpers/backend"

function* fetchNiveaux() {
  try {
    const response = yield call(get_Niveaux_Api)
    yield put(getNiveauSuccess(response.data))
  } catch (error) {
    yield put(getNiveauFail(error))
  }
}

function* onAddNewNiveauApi({ payload: niveau }) {
  try {
    const response = yield call(add_NewNiveau_Api, niveau)

    yield put(addNiveauSuccess(response))
  } catch (error) {
    yield put(addNiveauFail(error))
  }
}

function* onUpdateNiveau({ payload: oniveau }) {
  try {
    const response = yield call(update_Niveau_Api, oniveau)
    yield put(updateNiveauSuccess(response))
  } catch (error) {
    yield put(updateNiveauFail(error))
  }
}

function* onDeleteNiveau({ payload: id }) {
  try {
    const response = yield call(delete_Niveau_Api, id)
    yield put(deleteNiveauSuccess(id))
  } catch (error) {
    yield put(deleteNiveauFail(error))
  }
}

function* niveauSaga() {
  yield takeEvery(GET_NIVEAUX, fetchNiveaux)
  yield takeEvery(ADD_NEW_NIVEAU, onAddNewNiveauApi)
  yield takeEvery(UPDATE_NIVEAU, onUpdateNiveau)
  yield takeEvery(DELETE_NIVEAU, onDeleteNiveau)
}

export default niveauSaga
