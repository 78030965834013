import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"

import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Table, Modal, ModalHeader, ModalBody
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { RotatingSquare } from "react-loader-spinner"

//redux
import { useSelector, useDispatch } from "react-redux"
import { delete_Categorie, getCategoriesActions } from "store/categories/actions"

//i18n
import { withTranslation } from "react-i18next"
import DeleteComponent from "../../../components/Common/DeleteComponent"
import CategorieCreate from "../CategorieCreate/categorie-create"

export const CategorieList = props => {
  // meta title
  document.title = props.t("Categories")

  const dispatch = useDispatch()

  const { categories } = useSelector(state => ({
    categories: state.categories.categories,
  }))

  const { loading } = useSelector(state => ({
    loading: state.categories.loading,
  }))

  const language = localStorage.getItem("I18N_LANGUAGE")

  function showToast() {
    const title = props.t("Categories")
    const message = props.t("CategorieListMessage")
    const positionClass = "toast-top-right"
    const showEasing = "swing"
    const hideEasing = "linear"
    const showMethod = "fadeIn"
    const hideMethod = "fadeOut"
    const showDuration = 300
    const hideDuration = 1000
    const timeOut = 5000
    const extendedTimeOut = 1000
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    }
    toastr.success(message, title)
  }

  useEffect(() => {
    if (categories && !categories.length) {
      dispatch(getCategoriesActions())
      showToast()
    }
  }, [categories.length])
  const [categorieSelected, setCategorieSelected] = useState("")
  const [nameSelected, setNameSelected] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const handleDelete = (id, name) => {
    dispatch(delete_Categorie(id))
  }
  function tog_center() {
    setmodal_center(!modal_center)
    // removeBodyCss()
  }

  //   ------------------------- Loader ---------------------------------//
  if (loading && categories.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20%",
        }}
      >
        <RotatingSquare
          ariaLabel="rotating-square"
          visible={true}
          color="#316161"
          id="login-spinner"
        />
      </div>
    )
  }
  const [categorieUpdate,setCategorieUpdate] = useState(undefined)

  return (
    <React.Fragment>
      {
        categorieUpdate ? <FullModalEdit  open={categorieUpdate !== undefined} categorie={categorieUpdate} onClose={()=>setCategorieUpdate(undefined)} /> :null
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Categories")}
            breadcrumbItem={props.t("CategoryList")}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">
                    {props.t("CategoryList")}
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped-columns mb-0">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>{props.t("CatName")}</th>
                        <th>{props.t("Actions")}</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          categories.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{index}</th>
                              <td>{language === 'fr' ? item.cat_name_fr : item.cat_name_en}</td>
                              <td>
                                <div className="d-flex justify-content-between">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      // tog_standard()
                                      setCategorieUpdate(item)
                                      setNameSelected(item[`cat_name_${language}`])
                                      setCategorieSelected(item._id)
                                    }}
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="bx bx-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => {
                                      tog_center()
                                      setNameSelected(item[`cat_name_${language}`])
                                      setCategorieSelected(item._id)
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                  </button>
                                </div>

                                <DeleteComponent
                                  tog_center={tog_center}
                                  name={nameSelected}
                                  id={categorieSelected}
                                  setmodal_center={setmodal_center}
                                  modal_center={modal_center}
                                  handleDelete={handleDelete}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const  FullModalEdit = props =>{
  const {open,onClose,categorie} = props
  return(
    <Modal isOpen={open} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>
        {/*{menu.fr}*/}
      </ModalHeader>
      <ModalBody>
        <CategorieCreate initialState={categorie} onClose={onClose} />
      </ModalBody>
    </Modal>
  )
}
CategorieList.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CategorieList))
