import React, { useCallback, useEffect, useMemo, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap"
import InputRange from "components/Common/InputRange"
import classNames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"

// redux
import { useDispatch, useSelector } from "react-redux"
import {
  getQuestionnaireDetailById as onGetQuestionnaireDetailById,
  getQuestionnaires as onGetQuestionnaires,
} from "store/questionnaireAdmin/actions"
import { getQuestionByThemeAction as onGetQuestionByThemeAction } from "store/questions/actions"

// import images
import logo from "assets/images/logo.jpg"
import "swiper/css"
import ToastComponent from "./widgets/ToastComponent"
import { addNewAnswer as onAddNewAnswer } from "store/answers/actions"
import { getLatestEvent } from "../../helpers/utils"
import ColorRange from "components/Common/ColorRange"
import ColorRangeButton from "components/Common/ColorRangeButtons"
import axios from "axios"
import { get } from "helpers/api"

// Function to shuffle an array using Fisher-Yates Shuffle
function shuffleArray(array) {
  const shuffledArray = [...array]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray
}

// Function to get answer by theme and question ID
function getAnswerByIdThemeAndQuestion(themes, themeId, questionId) {
  const theme = themes.find(t => t.id_theme === themeId)
  if (theme) {
    const question = (theme.questions || []).find(q => q.id === questionId)
    if (question) {
      return question.answer
    }
  }
  return null
}

const Questionnaire = props => {
  document.title = props.t("Questionnaire")

  const language = localStorage.getItem("I18N_LANGUAGE")

  const dispatch = useDispatch()
  const [toast, setToast] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState(undefined)
  const [previousSelected, setPreviousSelected] = useState(undefined)
  const [shuffleArrayState, setShuffleArrayState] = useState(null)
  const errorResponse = useSelector(state => state.answers)
  const [errorOpen, setErrorOpen] = useState({ statut: false, message: "" })
  const { users_users } = useSelector(state => ({
    users_users: state.users.users_users,
  }))
  const { sprints } = useSelector(state => ({
    sprints: state.sprints.sprints,
  }))
  // const { questionnaireDetail } = useSelector(state => ({
  //   questionnaireDetail: state.questionnaireAdmin.questionnaireDetail,
  // }))

  const { questionnaireDetailById } = useSelector(state => ({
    questionnaireDetailById: state.questionnaireAdmin.questionnaireDetailById,
  }))

  const { questionnaires } = useSelector(state => ({
    questionnaires: state.questionnaireAdmin.questionnaires,
  }))

  const { questionByTheme } = useSelector(state => ({
    questionByTheme: state.questions.questionByTheme,
  }))

  const [userAnswers, setUserAnswers] = useState({})

  const fetchAnswers = async (userId, qId) => {
    try {
      const response = await get(
        `/answer/getByUserAndQuestionnaire?user_id=${userId}&questionnaire_id=${qId}`
      )
      console.log("🚀 ~ fetchAnswers ~ response:", response)
      setUserAnswers(response.data[response?.data?.length - 1])
    } catch (error) {
      console.error("Erreur lors de la requête:", error)
      // Gérez les erreurs ici
    }
  }

  const [menuState, setMenuState] = useState([])

  const [responseState, setResponseState] = useState([])
  const [errorState, setErrorState] = useState([])

  const toggleToast = () => {
    setToast(!toast)
  }
  const colors = [
    "#dc2626", // 0-1
    "#ef4444", // 1-2
    "#ea580c", // 2-3
    "#f97316", // 3-4
    "#f59e0b", // 4-5
    "#fbbf24", // 5-6
    "#fcd34d", // 6-7
    "#bef264", // 7-8
    "#84cc16", // 8-9
    "#22c55e", // 9-10
  ]
  const [randomizedColors, setRandomizedColors] = useState([])
  // useEffect(() => {
  //   if (questionnaireDetail && !questionnaireDetail?.length) {
  //     dispatch(onGetQuestionnaireDetail())
  //   }
  // }, [questionnaireDetail?.length, dispatch])

  useEffect(() => {
    if (questionnaires && !questionnaires?.length) {
      dispatch(onGetQuestionnaires())
    }
  }, [(questionnaires ?? []).length, dispatch])

  /* useEffect(() => {
    if (questionByTheme && questionByTheme.data) {
      setShuffleArrayState(shuffleArray(questionByTheme?.data))
    }
  }, [questionByTheme]) */

  // Form Validation For Id Questionnaire
  const validation_questionnaire_id = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
    },
    validationSchema: Yup.object({
      id: Yup.string().required("Please Select Your Code"),
    }),
    onSubmit: values => {
      // // console.log({ values })
      dispatch(onGetQuestionnaireDetailById(values.id))
      validation_questionnaire_id.resetForm()
    },
  })

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      responses: Array(questionByTheme?.data?.length || 0).fill({
        questionIndex: -1,
        answer: -1,
      }),
    },
    validationSchema: Yup.object().shape({
      responses: Yup.array()
        .of(
          Yup.number()
            .min(1, "La réponse doit être supérieure ou égale à 1")
            .max(10, "La réponse doit être inférieure ou égale à 10")
        )
        .required("Vous devez répondre à toutes les questions"),
    }),
    onSubmit: values => {
      // toggleToast()
      validation.resetForm()
    },
  })
  const transformData = data => {
    return data.map(({ id_theme, questions, ...rest }) => {
      // Création d'un nouvel objet avec les propriétés nécessaires
      const transformedQuestions = (questions ?? []).map(
        ({ id, answer, color_code }) => ({
          question: id,
          color_code: color_code,
          answer,
        })
      )

      // Renommage des propriétés et suppression des propriétés non nécessaires
      return {
        item: id_theme,
        questions: transformedQuestions,
      }
    })
  }
  const handleInputChange = useCallback(
    (questionIndex, value, parent) => {
      console.log(
        "🚀 ~ questionIndex, value, parent:",
        questionIndex,
        value,
        parent
      )

      const newMenuState = menuState.map(item =>
        item.id_theme === selectedTheme
          ? {
              ...item,
              questions: item.questions.map(q =>
                q.id === questionIndex ? { ...q, answer: value } : q
              ),
            }
          : item
      )

      setErrorState([])
      // setResponseState([...responseState,{ question: questionIndex, answer: value }])
      const transformedData = transformData(newMenuState)
      setResponseState(transformedData)
      setMenuState(newMenuState)
      localStorage.setItem("MenuState", JSON.stringify(newMenuState))
      validation.setFieldValue("responses", responseState)
    },

    [validation, menuState, selectedTheme]
    // [selectedTheme]
  )

  const constructMenuArray = () => {
    let returnArray = questionnaireDetailById?.data?.menu?.themes?.map(item => {
      const themeId = item?._id
      const newMenu = JSON.parse(localStorage.getItem("MenuState")) || menuState
      return {
        id_theme: themeId,
        theme: props.t(language ? item?.theme_name_fr : item?.theme_name_en),
        description: props.t(
          language ? item?.description_fr : item?.description_en
        ),
        main_question: props.t(
          language ? item?.main_question_fr : item?.main_question_en
        ),
        questions: questionByTheme[themeId]
          ?.sort(() => Math.random() - 0.5)
          ?.map(question => {
            const questionId = question?._id
            const colorCode = question?.color_code
            const answer = getAnswerByIdThemeAndQuestion(
              newMenu,
              themeId,
              questionId
            )

            return {
              id: questionId,
              theme_id: themeId,
              color_code: colorCode,
              question: props.t(
                language
                  ? question?.question_name_fr
                  : question?.question_name_en
              ),
              answer: answer,
            }
          }),
      }
    })
    setResponseState(
      (returnArray ?? []).map(response => ({
        item: response?.id_theme,
        questions: (response?.questions ?? []).map(question => ({
          question: question?.id,
          answer: question?.answer ?? -1,
        })),
      }))
    )
    return returnArray
  }

  const user = JSON.parse(localStorage.getItem("authUser"))
  const organisation_id = user?.user?.name_enterprise
  const sprintsUserOrganisation = sprints?.filter(
    x => x?.organisation?._id === organisation_id
  )
  const lastSprint = getLatestEvent(sprintsUserOrganisation)
  // console.log("(this is the user", lastSprint)
  const role = user?.user?.role
  const themesOfQuestionnaire = questionnaires?.find(
    x => x?.sprint?._id === lastSprint?._id
  )
  const currentSprint = sprints.filter(
    x => x.organisation._id === organisation_id._id
  )
  const userSprint = currentSprint[currentSprint.length - 1]
  console.log("🚀 ~ Questionnaire ~ currentSprint:", userSprint)
  useEffect(() => {
    if (role === "USER") {
      return setMenuState(
        themesOfQuestionnaire?.menu?.themes.map(item => {
          const themeId = item?._id
          const newMenu =
            JSON.parse(localStorage.getItem("MenuState")) || menuState
          return {
            id_theme: themeId,
            theme: props.t(
              language ? item?.theme_name_fr : item?.theme_name_en
            ),
            description: props.t(
              language ? item?.description_fr : item?.description_en
            ),
            main_question: props.t(
              language ? item?.main_question_fr : item?.main_question_en
            ),
            questions: questionByTheme[themeId]
              ?.sort(() => Math.random() - 0.5)
              ?.map(question => {
                const questionId = question?._id
                const colorCode = question?.color_code
                const answer = getAnswerByIdThemeAndQuestion(
                  newMenu,
                  themeId,
                  questionId
                )

                return {
                  id: questionId,
                  theme_id: themeId,
                  color_code: colorCode,
                  question: props.t(
                    language
                      ? question?.question_name_fr
                      : question?.question_name_en
                  ),
                  answer: answer,
                }
              }),
          }
        })
      )
    }
    setMenuState(constructMenuArray())
  }, [
    questionnaireDetailById,
    questionByTheme,
    language,
    selectedTheme,
    questionnaires,
  ])

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      // console.log("Réponse du serveur:", menuState)
      const menuData =
        JSON.parse(localStorage.getItem("MenuState")) || menuState

      const results = menuData
        ?.flatMap(item =>
          item?.questions?.map(question => ({
            question: question?.id,
            theme: question?.theme_id,
            color_code: question?.color_code,
            answer: question?.answer ?? -1,
          }))
        )
        .filter(x => x !== undefined)

      const color_type_id =
        questionnaireDetailById?.data?.organisation?.color_type?._id ??
        themesOfQuestionnaire?.organisation?.color_type

      const user_id = JSON.parse(localStorage.getItem("authUser"))?.user?._id
      const orga_id = JSON.parse(localStorage.getItem("authUser"))?.user
        ?.name_enterprise

      let error = results.filter(response => response.answer === -1)
      console.log(error)

      if (error.length === 0) {
        const data = {
          questionnaire:
            questionnaireDetailById?.data?._id ?? themesOfQuestionnaire._id,
          color_type: color_type_id,
          user: user_id,
          organisation: orga_id?._id,
          results,
        }
        dispatch(onAddNewAnswer(data))
        // console.log({ errorResponse })
        if (Object.keys(errorResponse.error).length !== 0) {
          setErrorOpen({
            statut: true,
            message: errorResponse.error.response.data.msg_fr,
          })
          setTimeout(() => {
            setErrorOpen({ statut: false, message: "" })
          }, 5000)
        }
        localStorage.removeItem("MenuState")

        return
      }
      setErrorState(error)
      setToastOpen(true)
    } catch (error) {
      console.error("Erreur lors de la requête POST:", error)
      // Gérez les erreurs ici
    }
  }

  const [selectedThemeIndex, setSelectedThemeIndex] = useState(null)
  var themeIndex = 1
  //Retravailler cette foncton
  const handleThemeClick = (themeId, menu) => {
    themeIndex = menuState.findIndex(item => item.id_theme === themeId)
    setSelectedThemeIndex(themeIndex)
    setSelectedTheme(themeId)
    setPreviousSelected(themeId)
    if (menu === undefined) {
      dispatch(onGetQuestionByThemeAction(themeId))
    }
  }

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const [toastOpen, setToastOpen] = useState(false)

  // Function to shuffle array
  const shuffleArray = array => {
    const shuffledArray = [...array]
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ]
    }
    return shuffledArray
  }
  useEffect(() => {
    setRandomizedColors(shuffleArray(colors))
  }, [])
  const mapperArray = useMemo(() => {
    let dataMenu = JSON.parse(localStorage.getItem("MenuState")) || menuState
    const res1 = dataMenu?.find(
      item => item?.id_theme === selectedTheme
    )?.questions
    // res1?.sort((a, b) => (a.color_code < b.color_code ? -1 : 1))

    const res2 = menuState?.find(
      item => item?.id_theme === selectedTheme
    )?.questions
    // res2?.sort((a, b) => (a.color_code < b.color_code ? -1 : 1))
    console.log("🚀 ~ mapperArray ~ res2:", res2)
    return res1 ?? res2 ?? []
  }, [selectedTheme, menuState])

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))?.user?._id
    const connectedUser = users_users.find(item => item._id === user_id)
    console.log("🚀 ~ useEffect ~ connectedUser:", connectedUser)

    if (role === "USER") {
      const idTheme =
        connectedUser?.questionnaires_fullfilled[
          connectedUser.questionnaires_fullfilled.length - 1
        ]
      if (user_id && idTheme) fetchAnswers(user_id, idTheme)
      if (idTheme !== undefined) {
        dispatch(onGetQuestionnaireDetailById(idTheme))
      }
    }
  }, [])

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem("authUser"))?.user?._id
    const connectedUser = users_users.find(item => item._id === user_id)
    const idTheme =
      connectedUser?.questionnaires_fullfilled[
        connectedUser.questionnaires_fullfilled.length - 1
      ]
    if (role === "USER") {
      if (user_id && idTheme) fetchAnswers(user_id, idTheme)
    }
  }, [selectedTheme])

  const getQuestionUserAnswers = questionId => {
    if (userAnswers) {
      const data = userAnswers?.results?.find(
        item => item?.question === questionId
      )
      if (data) return data?.answer
    }
    return -1
  }

  return (
    <React.Fragment>
      {toastOpen ? (
        <ToastComponent
          onClose={() => setToastOpen(false)}
          color={"danger"}
          title={"Erreur"}
          message={"Vous n'avez pas répondu à toutes les questions "}
        />
      ) : null}
      {errorOpen.statut ? (
        <ToastComponent
          onClose={() => setErrorOpen({ status: false, message: "" })}
          message={errorOpen.message}
          title={"Erreur"}
        />
      ) : null}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Questionnaire")}
            breadcrumbItem={questionnaireDetailById?.data?.name}
          />
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast isOpen={toast} className="text-white bg-primary">
              <ToastHeader toggle={toggleToast}>
                <img src={logo} alt="" className="me-2" height="18" />
                {props.t("Infos")}
              </ToastHeader>
              <ToastBody>{props.t("AnswerMessage")}</ToastBody>
            </Toast>
          </div>

          <Form
            className="needs-validation"
            onSubmit={validation_questionnaire_id.handleSubmit}
          >
            {role === "USER" ? null : (
              <Row className="mb-2">
                <Label htmlFor="id_input" className="col-md-2 col-form-label">
                  {props.t("Code du Run")}
                </Label>
                <div className="col-md-6">
                  <select
                    id="id_input"
                    onChange={validation_questionnaire_id.handleChange}
                    onBlur={validation_questionnaire_id.handleBlur}
                    value={validation_questionnaire_id.values.id || ""}
                    name="id"
                    placeholder={props.t("Code du Run")}
                    className="form-control"
                  >
                    <option value="">{props.t("Run")}</option>
                    {questionnaires &&
                      questionnaires?.map(item => (
                        <option key={item?._id} value={item?._id}>
                          {`${item?.sprint?.code} - ${
                            language === "fr"
                              ? item?.sprint?.scope_fr
                              : item?.sprint?.scope_en
                          }`}
                        </option>
                      ))}
                  </select>
                </div>
                <Button
                  style={{ width: "32%", margin: "auto" }}
                  type="submit"
                  color="primary"
                  className="col-md-3"
                >
                  {props.t("Voir Questionnaire")}
                </Button>
              </Row>
            )}
            <Row className="col-md-2"></Row>
          </Form>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/*<CardTitle className="h4 mb-4">*/}
                  {/*  {props.t("Questionnaire")}*/}
                  {/*</CardTitle>*/}
                  <Form className="needs-validation" onSubmit={e => {}}>
                    <div
                      className="nav nav-pills gap-1 my-2 d-flex flex-wrap border border-2 overflow-auto"
                      style={{ maxHeight: "500px" }}
                    >
                      {(menuState ?? []).map((menu, index) => (
                        <div
                          className={"nav-item overflow-hidden "}
                          style={{
                            cursor: "pointer",
                            maxWidth: "250px",
                            width: "250px",
                            textOverflow: "ellipsis",
                          }}
                          key={menu.id_theme ?? index}
                          onClick={() =>
                            handleThemeClick(menu.id_theme, menu.questions)
                          }
                        >
                          <Badge
                            color={"danger"}
                            style={{ position: "absolute" }}
                          >
                            {
                              Object.entries(errorState).reduce(
                                (countMap, item) => {
                                  const [, errorE, ...rest] = item
                                  const parentID = errorE.theme

                                  countMap[parentID] =
                                    (countMap[parentID] || 0) + 1

                                  return countMap
                                },
                                {}
                              )[menu.id_theme]
                            }
                          </Badge>
                          <span
                            style={{
                              border: "solid  1px",
                              maxWidth: "250px",
                              width: "250px",
                              textOverflow: "ellipsis",
                            }}
                            className={classNames(
                              {
                                active:
                                  menu?.id_theme === selectedTheme
                                    ? "active"
                                    : "",
                              },
                              "nav-link text-nowrap overflow-hidden"
                            )}
                          >
                            {menu.theme}
                          </span>
                        </div>
                      ))}
                    </div>

                    {selectedTheme ? (
                      <Row className="my-3 ">
                        <div
                          style={{
                            position: "sticky",
                            zIndex: 10,
                            top: 0,
                          }}
                        >
                          <div className="bg-primary d-flex justify-content-start py-4 rounded-3">
                            <h3 className="text-white">
                              {
                                menuState.find(
                                  item => item?.id_theme === selectedTheme
                                )?.theme
                              }
                              {" : "}
                              {
                                menuState.find(
                                  item => item?.id_theme === selectedTheme
                                )?.description
                              }
                            </h3>
                          </div>
                          <div>
                            <h3 className="m-2">
                              {language === "fr"
                                ? userSprint.scope_fr
                                : userSprint.scope_en}
                            </h3>
                          </div>
                        </div>
                        <ol className="my-2 mx-4" style={{ listStyle: "none" }}>
                          {mapperArray.map((question_item, index) => (
                            <Row
                              key={question_item?.id}
                              // style={{ marginTop: "3vh", marginBottom: "3vh" }}
                            >
                              <li style={{ fontSize: 18 }} className="">
                                {question_item?.question}
                              </li>
                              <ColorRangeButton
                                value={
                                  getQuestionUserAnswers(question_item?.id) ||
                                  -1
                                }
                                onValueChange={value =>
                                  handleInputChange(
                                    question_item?.id,
                                    value,
                                    selectedTheme
                                  )
                                }
                              />
                              {index === mapperArray.length - 1 ? null : (
                                <hr
                                  className={"w-full border border-2 my-2 "}
                                />
                              )}
                            </Row>
                          ))}
                        </ol>
                      </Row>
                    ) : null}
                    <Row className="mb-2">
                      <Button
                        style={{
                          width: "25%",
                          margin: "auto",
                          display:
                            !selectedThemeIndex || selectedThemeIndex === 0
                              ? "none"
                              : "block",
                        }}
                        type="button"
                        color="secondary"
                        className="w-xl"
                        onClick={() => {
                          if (selectedThemeIndex > 0) {
                            handleThemeClick(
                              menuState[selectedThemeIndex - 1].id_theme
                            )
                          }
                        }}
                      >
                        Précédent
                      </Button>{" "}
                      <Button
                        style={{ width: "25%", margin: "auto" }}
                        color="primary"
                        className=" w-xl"
                        onClick={e => {
                          selectedThemeIndex === (menuState ?? []).length - 1
                            ? handleSubmit(e)
                            : handleThemeClick(
                                menuState[
                                  selectedThemeIndex !== null
                                    ? selectedThemeIndex + 1
                                    : 0
                                ].id_theme
                              )
                        }}
                      >
                        {selectedThemeIndex === (menuState ?? []).length - 1
                          ? " Soumettre mes réponses"
                          : "Suivant"}
                      </Button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Questionnaire.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Questionnaire))
