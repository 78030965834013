/* SECTEURS */
export const GET_SECTEURS = "GET_SECTEURS"
export const GET_SECTEURS_SUCCESS = "GET_SECTEURS_SUCCESS"
export const GET_SECTEURS_FAIL = "GET_SECTEURS_FAIL"

/**
 * add question
 */
export const ADD_NEW_SECTEUR = "ADD_NEW_SECTEUR"
export const ADD_SECTEUR_SUCCESS = "ADD_SECTEUR_SUCCESS"
export const ADD_SECTEUR_FAIL = "ADD_SECTEUR_FAIL"
export const DELETE_SECTEUR = "DELETE_SECTEUR"
export const DELETE_SECTEUR_SUCCESS = "DELETE_SECTEUR_SUCCESS"
export const DELETE_SECTEUR_FAIL = "DELETE_SECTEUR_FAIL"
export const UPDATE_SECTEUR = "UPDATE_SECTEUR"
export const UPDATE_SECTEUR_SUCCESS = "UPDATE_SECTEUR_SUCCESS"
export const UPDATE_SECTEUR_FAIL = "UPDATE_SECTEUR_FAIL"
